import { lazy } from "react";
import { createRef, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useAutoScrollDown, useGoBack, useNextStep, useWindowSize } from "../../../hooks";
import { TPage } from "../../../model";
import { howOftenWorkOptions } from "../../../utils/constants";
import { useAnswersContext } from "../../../context";
import { ArrowBackIos } from "@mui/icons-material";
import * as S from "./style";

const CustomOptionProgressBar = lazy(() => import("../../../components/V2/CustomOptionProgressBar/CustomOptionProgressBar"));
const WarningModal = lazy(() => import("./WarningModal/WarningModal"));
const CustomButton = lazy(() => import("../../../components/Base/CustomButtonV2/CustomButton"));
const CustomStepProgressBar = lazy(() => import("../../../components/CustomStepProgressBar/CustomStepProgressBar"));

const HowOftenWouldYouLikeToWorkOut: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });
  const handleGoBack = useGoBack({ pageId, nextPagePath });
  const { answers } = useAnswersContext();

  const [show, setShow] = useState<boolean>(false);
  const [animationKey, setAnimationKey] = useState<number>(0);

  const { height } = useWindowSize();

  const myRef = createRef<HTMLDivElement>();
  useAutoScrollDown(myRef);

  const selectedFitnessLevel = answers["whatIsYourFitnessLevel"]?.join("");

  const prevhowOftenWorkTitle = answers[pageId]?.join("");
  const prevhowOftenWorkOption = howOftenWorkOptions.find((f) => f.payload.title === prevhowOftenWorkTitle);

  const [selectedOption, setSelectedOption] = useState(prevhowOftenWorkOption || howOftenWorkOptions[0]);

  const onClick = () => {
    handleContinue(selectedOption.payload.title);
  };

  useEffect(() => {
    if (prevhowOftenWorkOption?.value === 100 && selectedFitnessLevel === "Beginner") {
      setShow(true);
    }
  }, [prevhowOftenWorkOption?.value, selectedFitnessLevel]);

  const progressOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    let result: number = 0;
    if (selectedOption) {
      if (selectedOption.value === 100) {
        result = 0;
      } else {
        const selectedOptionIndex = howOftenWorkOptions.findIndex((o) => o.id === selectedOption.id);
        result = howOftenWorkOptions[selectedOptionIndex + 1].value;
      }
    }
    const selected = howOftenWorkOptions.find((o) => o.value === result);
    if (selected) {
      if (selectedOption.value === 50 && selectedFitnessLevel === "Beginner") {
        setShow(true);
      }
      setSelectedOption(selected);
    }
    setAnimationKey((prevKey) => prevKey + 1);
  };

  return (
    <S.Main>
      <S.PageContent height={height}>
        <S.HeaderContent>
          <S.StyledButton onClick={handleGoBack} disableRipple>
            <ArrowBackIos />
          </S.StyledButton>
          <S.PartContent>
            <CustomStepProgressBar part={3} step={14} />
          </S.PartContent>
        </S.HeaderContent>
        <S.Content>
          <S.Title>
            <Box>How often would you</Box>
            <Box>like to work out?</Box>
          </S.Title>
          <S.OptionsContent key={animationKey} onClick={progressOnClick} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2 }}>
            <S.StyledImage
              src={selectedOption.payload.img}
              alt=""
              height={height}
              // initial={{ opacity: 0, x: -100 }}
              // animate={{ opacity: 1, x: 0 }}
              // exit={{ opacity: 0, x: 100 }}
              // transition={{ duration: 0.5 }}
            />
          </S.OptionsContent>
          <S.SelectedOption ref={myRef}>
            <S.SelectedOptionTitle fontSize="0.875rem" fontWeight="600">
              {selectedOption.payload.title}
            </S.SelectedOptionTitle>
            <S.SelectedOptionSubTitle fontSize="0.875rem">{selectedOption.payload.subTitle}</S.SelectedOptionSubTitle>
          </S.SelectedOption>
          <Box display="flex" justifyContent="center" alignItems="center">
            <CustomOptionProgressBar
              height={height}
              options={howOftenWorkOptions}
              onSelectedOptionChange={(selectedOption) => {
                if (selectedOption.value === 100 && selectedFitnessLevel === "Beginner") {
                  setShow(true);
                }
                setSelectedOption(selectedOption);
                setAnimationKey((prevKey) => prevKey + 1);
              }}
              selectedOption={selectedOption}
            />
          </Box>
        </S.Content>
        <CustomButton width={height / 2} text="Continue" onClick={onClick} />
      </S.PageContent>
      <WarningModal
        show={show}
        backdropClick={() => {
          setSelectedOption(howOftenWorkOptions[1]);
          setShow(!show);
        }}
      />
    </S.Main>
  );
};

export default HowOftenWouldYouLikeToWorkOut;
