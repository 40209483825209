import { createRef, lazy, useState } from "react";
import { Box } from "@mui/material";
import { useAutoScrollDown, useGoBack, useNextStep, useWindowSize } from "../../../hooks";
import { TPage } from "../../../model";
import { fitnessLevelOptionsV2 } from "../../../utils/constants";
import { useAnswersContext } from "../../../context";
import { ArrowBackIos } from "@mui/icons-material";
import * as S from "./style";

const CustomOptionProgressBar = lazy(() => import("../../../components/V2/CustomOptionProgressBar/CustomOptionProgressBar"));
const CustomButton = lazy(() => import("../../../components/Base/CustomButtonV2/CustomButton"));
const CustomStepProgressBar = lazy(() => import("../../../components/CustomStepProgressBar/CustomStepProgressBar"));

const WhatIsYourFitnessLevel: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });
  const handleGoBack = useGoBack({ pageId, nextPagePath });
  const { answers } = useAnswersContext();

  const { height } = useWindowSize();

  const myRef = createRef<HTMLDivElement>();
  useAutoScrollDown(myRef);

  const prevActivityLevelTitle = answers[pageId]?.join("");
  const prevActivityLevelOption = fitnessLevelOptionsV2.find((f) => f.payload.title === prevActivityLevelTitle);

  const [selectedOption, setSelectedOption] = useState(prevActivityLevelOption || fitnessLevelOptionsV2[0]);

  const onClick = () => {
    handleContinue(selectedOption.payload.title);
  };

  const progressOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    let result: number = 0;
    if (selectedOption) {
      if (selectedOption.value === 100) {
        result = 0;
      } else {
        const selectedOptionIndex = fitnessLevelOptionsV2.findIndex((o) => o.id === selectedOption.id);
        result = fitnessLevelOptionsV2[selectedOptionIndex + 1].value;
      }
    }
    const selected = fitnessLevelOptionsV2.find((o) => o.value === result);
    if (selected) setSelectedOption(selected);
  };

  return (
    <S.Main>
      <S.PageContent height={height}>
        <S.HeaderContent>
          <S.StyledButton onClick={handleGoBack} disableRipple>
            <ArrowBackIos />
          </S.StyledButton>
          <S.PartContent>
            <CustomStepProgressBar part={3} step={11} />
          </S.PartContent>
        </S.HeaderContent>
        <S.Content>
          <S.Title>
            <Box>
              What is your <S.ColoredText>fitness</S.ColoredText>
            </Box>
            <Box>level</Box>
          </S.Title>
          <S.OptionsContent onClick={progressOnClick}>
            <S.StyledLottie animationData={selectedOption.payload.img} loop={true} height={height} />
          </S.OptionsContent>
          <S.SelectedOption ref={myRef}>
            <S.SelectedOptionTitle fontSize="0.875rem" fontWeight="600">
              {selectedOption.payload.title}
            </S.SelectedOptionTitle>
            <S.SelectedOptionSubTitle fontSize="0.875rem">{selectedOption.payload.subTitle}</S.SelectedOptionSubTitle>
          </S.SelectedOption>
          <S.ProgressContent>
            <CustomOptionProgressBar
              height={height}
              options={fitnessLevelOptionsV2}
              onSelectedOptionChange={(selectedOption) => {
                setSelectedOption(selectedOption);
              }}
              selectedOption={selectedOption}
            />
          </S.ProgressContent>
        </S.Content>
        <CustomButton width={height / 2} text="Continue" onClick={onClick} />
      </S.PageContent>
    </S.Main>
  );
};

export default WhatIsYourFitnessLevel;
