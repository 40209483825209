import { lazy } from "react";
import { createRef, useState } from "react";
import { Box } from "@mui/material";
import { useNextStep, useAutoScrollDown, useWindowSize, useGoBack } from "../../../hooks";
import { CustomOption, TPage } from "../../../model";
import { currentBodyShapeOptionsV2 } from "../../../utils/constants";
import { useAnswersContext } from "../../../context";
import { ArrowBackIos } from "@mui/icons-material";
import * as S from "./style";

const CustomOptionProgressBar = lazy(() => import("../../../components/V2/CustomOptionProgressBar/CustomOptionProgressBar"));
const ImageSlider = lazy(() => import("../../../components/V2/ImageSlider/ImageSlider"));
const CustomButton = lazy(() => import("../../../components/Base/CustomButtonV2/CustomButton"));
const CustomStepProgressBar = lazy(() => import("../../../components/CustomStepProgressBar/CustomStepProgressBar"));

const ChooseYourCurrentBodyShape: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });
  const handleGoBack = useGoBack({ pageId, nextPagePath });
  const { height } = useWindowSize();

  const { answers } = useAnswersContext();
  const prevCurrentBodyShapeTitle: string | undefined = answers["chooseYourCurrentBodyShape"]?.join("");
  const prevCurrentBodyShape = currentBodyShapeOptionsV2.find((c) => c.payload.title === prevCurrentBodyShapeTitle);

  const [selectedOption, setSelectedOption] = useState<CustomOption>(prevCurrentBodyShape || currentBodyShapeOptionsV2[2]);

  const myRef = createRef<HTMLDivElement>();
  useAutoScrollDown(myRef);

  const onClick = () => {
    handleContinue(selectedOption?.payload.title);
  };

  return (
    <S.Main>
      <S.PageContent width={height / 2}>
        <S.HeaderContent>
          <S.StyledButton onClick={handleGoBack} disableRipple>
            <ArrowBackIos />
          </S.StyledButton>
          <S.PartContent>
            <CustomStepProgressBar part={1} step={2} />
          </S.PartContent>
        </S.HeaderContent>
        <S.Content heightval={height}>
          <S.Title>
            <Box>
              Choose your <span style={{ color: "#E5FA40" }}>current</span>
            </Box>
            <Box>body shape</Box>
          </S.Title>
          <S.StyledImageSliderContent>
            <ImageSlider
              options={currentBodyShapeOptionsV2}
              onSelectedOptionChange={(selectedOption) => {
                setSelectedOption(selectedOption);
              }}
              selectedOption={selectedOption}
              height={height}
            />
          </S.StyledImageSliderContent>
          <Box ref={myRef} display="flex" justifyContent="center" alignItems="center">
            <CustomOptionProgressBar
              height={height}
              options={currentBodyShapeOptionsV2}
              onSelectedOptionChange={(selectedOption) => {
                setSelectedOption(selectedOption);
              }}
              selectedOption={selectedOption}
            />
          </Box>
        </S.Content>
        <CustomButton width={height / 2} text="Continue" disabled={selectedOption === undefined} onClick={onClick} />
      </S.PageContent>
    </S.Main>
  );
};

export default ChooseYourCurrentBodyShape;
