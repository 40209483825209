import { Box, Button, styled } from "@mui/material";
import backgrounImg from "../../../assets/img/part2Final/shutterstock.webp";

export const Main = styled(Box)(({ height }: { height: number }) => ({
  position: "fixed",
  height: "calc(100% - 6rem)",
  margin: "0.5rem 0",
  background: `url(${backgrounImg})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  width: `${height / 2}px`,
  borderRadius: "0.25rem",
  "@media (max-width: 768px)": {
    width: "100%",
    margin: "0",
    height: "calc(100% - 2.5rem)",
    backgroundSize: "cover",
    transform: "scale(1.01)",
  },
}));

export const Image = styled("img")(() => ({
  width: "100%",
  height: "calc(100% - 1rem)",
  margin: "0.5rem auto",
  position: "absolute",
  bottom: "0",
  top: "0",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "0.25rem",
  objectFit: "cover",
  "@media (max-width: 768px)": {
    margin: "0",
    height: "100%",
    transform: "scale(1.01)",
  },
}));

export const ButtonContent = styled(Box)(() => ({
  position: "absolute",
  bottom: "1rem",
  left: "0.5rem",
  right: "0.5rem",
  width: "calc(100% - 1rem)",
  height: "3rem",
  "@media (max-width: 768px)": {
    left: "1rem",
    right: "1rem",
    width: "calc(100% - 2rem)",
  },
}));

export const HeaderText = styled(Box)(() => ({
  color: "#fff",
  position: "absolute",
  left: "0",
  right: "0",
  top: "0.5rem",
  width: "100%",
  textAlign: "center",
  fontSize: "1.25rem",
  fontWeight: "600",
  "@media (min-height: 671px)": {
    top: "5%",
  },
}));

export const ContentImage = styled("img")(() => ({
  position: "absolute",
  margin: "0 auto",
  left: "0",
  right: "0",
  top: "5rem",
  height: "auto",
  width: "80%",
  "@media (min-height: 671px)": {
    top: "20%",
  },
}));

export const CustomButtonContainer = styled(Box)(() => ({
  position: "absolute",
  bottom: "1rem",
  left: "0.5rem",
  right: "0.5rem",
  width: "calc(100% - 1rem)",
  height: "3rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "@media (max-width: 768px)": {
    left: "1rem",
    right: "1rem",
    width: "calc(100% - 2rem)",
  },
}));

export const CustomButton = styled(Button)(() => ({
  width: "25rem",
  // margin: "2rem 0",
  borderRadius: "0.5rem",
  height: "3rem",
  background: "#7b66ff",

  "&:hover": {
    background: "#7b66ff !important",
  },
}));
