import { useEffect } from "react";
import useWindowSize from "./useWindowSize";

const useAutoScrollDown = (ref: React.RefObject<HTMLDivElement>, maxHeight: number = 600, ms: number = 100) => {
  const { height } = useWindowSize();

  useEffect(() => {
    let unmonted = false;

    if (!unmonted && height !== 0 && height < maxHeight) {
      setTimeout(() => {
        ref.current?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
      }, ms);
    }
    return () => {
      unmonted = true;
    };
  }, [height, maxHeight, ms, ref]);
};

export default useAutoScrollDown;
