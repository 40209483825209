import "../../config";
import ConfigList from "../../model/ConfigList";
import { getConfigs } from "../../services/stripe.service";
import { getUserId } from "../../services/user.service";

export const getConfigList = async (): Promise<ConfigList> => {
  const configs = {
    stripePublishableKey: window.config.CONFIGS.stripePublishableKey,
    firebaseConfig: window.config.CONFIGS.firebaseConfig,
    sentryDsn: window.config.CONFIGS.sentryDsn,
    facebookPixelId: window.config.CONFIGS.facebookPixelId,
  };

  if (
    configs.stripePublishableKey !== "" &&
    configs.firebaseConfig.apiKey !== "" &&
    configs.firebaseConfig.appId !== "" &&
    configs.firebaseConfig.authDomain !== "" &&
    configs.firebaseConfig.measurementId !== "" &&
    configs.firebaseConfig.messagingSenderId !== "" &&
    configs.firebaseConfig.projectId !== "" &&
    configs.firebaseConfig.storageBucket !== "" &&
    configs.sentryDsn !== "" &&
    configs.facebookPixelId !== ""
  ) {
    return configs;
  }

  return getConfigs().then((response) => response);
};

export const setConfigList = async (): Promise<void> => {
  const data: { configList: ConfigList; userId: string } = await getConfigList()
    .then((response) => Promise.all([response, getUserId()]))
    .then(([configList, userId]) => {
      return {
        configList,
        userId,
      };
    })
    .catch((err) => {
      console.error(`/configs=${window.config.HOSTNAME}`, err);
      throw new Error(`/configs/=${window.config.HOSTNAME}`, err);
    });

  if (data === undefined) {
    console.error("apiConfigList", data);
  }

  window.config.CONFIGS = {
    stripePublishableKey: data.configList.stripePublishableKey,
    firebaseConfig: {
      apiKey: data.configList.firebaseConfig.apiKey,
      appId: data.configList.firebaseConfig.appId,
      authDomain: data.configList.firebaseConfig.authDomain,
      measurementId: data.configList.firebaseConfig.measurementId,
      messagingSenderId: data.configList.firebaseConfig.messagingSenderId,
      projectId: data.configList.firebaseConfig.projectId,
      storageBucket: data.configList.firebaseConfig.storageBucket,
    },
    sentryDsn: data.configList.sentryDsn,
    facebookPixelId: data.configList.facebookPixelId,
  };
  window.config.USER_ID = data.userId;
};
