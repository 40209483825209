import React from "react";
import Svg from "../../../../components/Base/Svg/Svg";

type LineArmsProps = {
  fill?: string;
  color?: string;
  size?: string;
};

const LineArms: React.FC<LineArmsProps> = ({ fill, color, size = "20px" }) => (
  <Svg fill="none" color={color} size={size} path="" width="12rem" height="3.5rem" viewBox="0 0 172 10">
    <path d="M1 5L167 5.00001" stroke={fill} strokeWidth="2" strokeLinecap="round" />
    <circle cx="167" cy="5" r="3.75" fill={fill} stroke="white" strokeWidth="1.5" />
  </Svg>
);

export default LineArms;
