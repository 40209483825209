import { Box, Button, styled } from "@mui/material";
import { motion } from "framer-motion";
import { Fireworks } from "@fireworks-js/react";

export const Main = styled(Box)(() => ({
  width: "100%",
  height: "calc(100% - 5rem)",
  overflow: "hidden",
  touchAction: "none",
  position: "relative",
  WebkitOverflowScrolling: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "Space Grotesk",
  userSelect: "none",
  "@media (max-width: 768px)": {
    height: "100%",
  },
}));

export const PageContent = styled(Box)(({ height }: { height: number }) => ({
  position: "relative",
  width: `${height / 2}px`,
  height: "calc(100% - 1rem)",
  margin: "0.5rem 0",
  "@media (max-width: 768px)": {
    width: "100%",
    height: "100%",
    margin: "0",
  },
}));
export const StyledFireworks = styled(Fireworks)(({ heightval }: { heightval: number }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: "transparent",
  width: "100%",
  marginTop: "5rem",
  height: `${heightval}px`,
  "@media (max-width: 768px)": {
    marginTop: "0",
  },
  canvas: {
    position: "absolute",
    top: 0,
  },
}));

export const Content = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "calc(100% - 13rem)",
  // marginTop: "1rem",
  position: "relative",
  "@media (max-width: 768px)": {
    marginTop: "0rem",
  },
}));

export const HeaderContent = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  padding: "1rem 0.5rem",
  margin: "0.5rem 0 1rem 0",
}));

export const PartContent = styled(Box)(() => ({
  position: "absolute",
  left: 0,
  right: 0,
  margin: "0 auto",
  width: "16rem",
}));

export const Title = styled(Box)(() => ({
  margin: "2rem 0 1rem 0",
  textAlign: "center",
  fontWeight: "600",
  fontSize: "1.25rem",
  color: "#fff",
  "@media (max-width: 768px)": {
    marginTop: "2rem",
  },
}));

export const ColoredTextBox = styled(Box)(() => ({
  color: "#E5FA40",
}));

export const ColoredText = styled("span")(() => ({
  color: "#E5FA40",
}));

export const SubTitle = styled(Box)(() => ({
  textAlign: "center",
  fontSize: "0.875rem",
  color: "#fff",
  marginBottom: "1rem",
  lineHeight: "1.75rem",
  padding: "0 2.5rem",
  "@media (max-width: 768px)": {
    padding: "0 2rem",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },
}));

export const StyledButton = styled(Button)(() => ({
  marginTop: "0 !important",
  minWidth: "1rem",
  padding: "0 1rem",
  margin: "0",
  textTransform: "unset",
  color: "#8E98B4",
  fontSize: "1rem",
  zIndex: 5,
  "&:hover": {
    backgroundColor: "unset !important",
  },
}));

export const WeightChange = styled(Box)(() => ({
  display: "block",
  position: "relative",
  margin: "2rem 0",

  "@media (max-width: 768px)": {
    margin: "4rem 0",
  },

  "@media (max-height: 700px) and (max-width: 768px)": {
    margin: "2rem 0",
  },
}));

export const ImageContent = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "transparent",
}));

export const Current = styled(motion.div)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 0,
  background: "#30396F",
  borderRadius: "1.5rem",
}));

export const CurrentBodyShape = styled("img")(({ heightval }: { heightval: number }) => ({
  width: `${heightval / 5}px`,
  borderRadius: "1.5rem",
  position: "relative",
  // background: "#30396F",
  // borderRadius: "1.5rem",
}));

export const CurrentWeight = styled(Box)(() => ({
  background: "linear-gradient(180deg, #C5169D 24.5%, #1D1630 100%)",
  fontSize: "1.5rem",
  fontWeight: "700",
  color: "#F19FDE",
  borderBottomLeftRadius: "1.5rem",
  borderBottomRightRadius: "1.5rem",
  textAlign: "center",
  padding: "0.25rem 0",
  textTransform: "uppercase",
  height: "2rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const Target = styled(motion.div)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 0,
  backgroundColor: "#30396F",
  borderRadius: "1.5rem",
  zIndex: "5",
  position: "relative",
  boxShadow: "-10.154141426086426px 5.077070713043213px 14.342724800109863px 0px #00000040",
  overflow: "hidden",
}));

export const TargetBodyShape = styled("img")(({ heightval }: { heightval: number }) => ({
  width: `${heightval / 4}px`,
  borderRadius: "1.5rem",
  // background: "#30396F",
  // borderRadius: "1.5rem",
}));

export const LeftArrow = styled("img")(() => ({
  position: "absolute",
  left: "-0",
  top: "7.5rem",
  width: "3rem",
  "@media (max-width: 768px)": {
    width: "2.5rem",
    top: "6rem",
  },
}));

export const RightArrow = styled("img")(() => ({
  position: "absolute",
  right: "0",
  top: "7.5rem",
  width: "3rem",
  "@media (max-width: 768px)": {
    width: "2.5rem",
    top: "6rem",
  },
}));

export const TargetWeight = styled(Box)(() => ({
  background: "linear-gradient(180deg, #C5169D 24.5%, #1D1630 100%)",
  fontSize: "1.5rem",
  fontWeight: "700",
  color: "#E5FA40",
  borderBottomLeftRadius: "1.5rem",
  borderBottomRightRadius: "1.5rem",
  textAlign: "center",
  padding: "0.25rem 0",
  textTransform: "uppercase",
  position: "relative",
  height: "2rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const DownArrow = styled("img")(() => ({
  // position: "absolute",
  // right: "1.25rem",
  // top: "0.7rem",
  // width: "1.25rem",
  paddingLeft: "0.5rem",
  transform: "rotate(-10deg)",
}));

export const Arrow = styled(motion.img)(() => ({
  position: "absolute",
  left: "23%",
  bottom: "-1.25rem",
  zIndex: "6",
  transform: "rotate(10deg)",
  width: "8rem",

  "@media (max-width: 768px)": {
    left: "22%",
    bottom: "-1.5rem",
  },
}));

export const CustomButtonContainer = styled(Box)(() => ({
  position: "absolute",
  bottom: "1rem",
  left: "1rem",
  right: "1rem",
  width: "calc(100% - 2rem)",
  height: "3rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "@media (max-width: 768px)": {
    left: "1rem",
    right: "1rem",
    width: "calc(100% - 2rem)",
  },
}));

export const CustomButton = styled(Button)(() => ({
  width: "25rem",
  // margin: "2rem 0",
  borderRadius: "2rem",
  height: "3rem",
  background: "#E5FA40",
  color: "#232D49",
  textTransform: "none",
  fontWeight: 500,
  fontFamily: "Space Grotesk",
  "&:disabled": {
    background: "#899628 !important",
    color: "#232D49 !important",
  },
  "&:hover": {
    background: "#E5FA40 !important",
  },
}));
