const enum PAGE_ID {
  WELCOME = "welcome",
  PART_1_GOAL = "part1Goal",
  MOTIVATES = "motivates",
  CURRENT_BODY_SHAPE = "currentBodyShape",
  TARGET_BODY_SHAPE = "targetBodyShape",
  WHICH_AREAS_IMPROVE = "whichAreasImprove",
  HEIGHT = "height",
  PART_2_GOAL = "part2Goal",
  CURRENT_WEIGHT = "currentWeight",
  GOAL_WEIGHT = "goalWeight",
  PART_3_GOAL = "part3Goal",
  AGE_RANGE = "ageRange",
  FAVORITE_DANCE_STYLE = "favoriteDanceStyle",
  PREFERRED_MUSIC_STYLE = "preferredMusicStyle",
  PART_4_GOAL = "part4Goal",
  ACTIVITY_LEVEL = "activityLevel",
  DANCE_LEVEL = "danceLevel",
  PHYSICAL_ACTIVITY = "physicalActivity",
  WHAT_OUR_MEMBERS_ARE_SAYING = "whatOurMembersAreSaying",
  PART_2_FINAL = "part2Final",
  WANT_TO_IMPROVE_YOUR_WELLBEING_BY_DANCING = "wantToImproveYourWellbeingByDancing",
  WANT_TO_POWER_UP_YOUR_CHARM = "wantToPowerUpYourCharm",
  WANT_TO_DANCE_YOUR_WAY_TO_A_FITTER_YOU = "wantToDanceYourWayToAFitterYou",
  PREPARING_YOUR_PLAN = "preparingYourPlan",
  ANALYSIS_RESULT = "analysisResult",
  SIGN_UP = "signUp",
  ACCOUNT_IS_READY = "accountIsReady",
  PAYMENT = "payment",

  // V2
  LANDING = "landing",
  PERSONAL_FITNESS_COACH = "personalFitnessCoach",
  PART_1_GOALS = "part1Goals",
  MAIN_GOAL = "mainGoal",
  CHOOSE_YOUR_CURRENT_BODY_SHAPE = "chooseYourCurrentBodyShape",
  CHOOSE_YOUR_TARGET_BODY_SHAPE = "chooseYourTargetBodyShape",
  WHICH_AREAS_WOULD_YOU_LIKE_TO_IMPROVE = "whichAreasWouldYouLikeToImprove",
  PART_2_BODY_DATA = "part2BodyData",
  HOW_OLD_ARE_YOU = "howOldAreYou",
  WHAT_IS_YOUR_HEIGHT = "whatIsYourHeight",
  WHAT_IS_YOUR_CURRENT_WEIGHT = "whatIsYourCurrentWeight",
  WHAT_IS_YOUR_TARGET_WEIGHT = "whatIsYourTargetWeight",
  YOU_WILL_ACHIEVE_A_HEALTHIER_PHYSIQUE = "youWillAchieveAHealthierPhysique",
  PART_4_DANCE_ANALYSIS = "part4DanceAnalysis",
  CHOOSE_YOUR_DANCE_LEVEL = "chooseYourDanceLevel",
  SELECT_YOUR_FAVORITE_DANCE_STYLE = "selectYourFavoriteDanceStyle",
  SELECT_PREFERRED_MUSIC_STYLE = "selectPreferredMusicStyle",
  CALORIE_BURNING_WORKOUT = "calorieBurningWorkout",
  PART_3_FITNESS_ANALYSIS = "part3FitnessAnalysis",
  WHAT_IS_YOUR_ACTIVITY_LEVEL = "whatIsYourActivityLevel",
  WHAT_IS_YOUR_FITNESS_LEVEL = "whatIsYourFitnessLevel",
  WHICH_PLACE_DO_YOU_PREFER_FOR_YOUR_WORKOUT = "whichPlaceDoYouPreferForYourWorkout",
  WORKOUT_PLACE = "workoutPlace",
  HOW_OFTEN_WOULD_YOU_LIKE_TO_WORKOUT = "howOftenWouldYouLikeToWorkOut",
  YOUR_PERSONALIZED_FITNESS_PLAN = "yourPersonalizedFitnessPlan",
  APP_SIGN_UP = "appSignUp",
  YOUR_ACCOUNT_IS_READY = "yourAccountIsReady",
  YOUR_PLAN_IS_READY = "yourPlanIsReady",
}

export default PAGE_ID;
