import { Box, Button, styled } from "@mui/material";

export const Main = styled(Box)(() => ({
  width: "100%",
  height: "calc(100% - 5rem)",
  overflow: "hidden",
  touchAction: "none",
  position: "relative",
  WebkitOverflowScrolling: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "Space Grotesk",
  userSelect: "none",
  "@media (max-width: 768px)": {
    height: "calc(100% - 2.5rem)",
  },
}));

export const PageContent = styled(Box)(({ height }: { height: number }) => ({
  position: "relative",
  width: `${height / 2}px`,
  height: "calc(100% - 1rem)",
  margin: "0.5rem 0",
  "@media (max-width: 768px)": {
    width: "100%",
    height: "100%",
    margin: "0",
  },
}));

export const Content = styled(Box)(({ height }: { height: number }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: `${height / 1.5}px`,
  marginTop: "1rem",
  position: "relative",

  // "@media (min-width: 769px) and (max-width: 1024px)": {
  //   marginTop: "0rem",
  //   height: "calc(100% - 10rem)",
  // },

  // "@media (max-width: 768px)": {
  //   marginTop: "0rem",
  //   height: "calc(100% - 15rem)",
  // },
}));

export const HeaderContent = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  padding: "1rem 0.5rem",
  margin: "0.5rem 0 1rem 0",
}));

export const PartContent = styled(Box)(() => ({
  position: "absolute",
  left: 0,
  right: 0,
  margin: "0 auto",
  width: "16rem",
}));

export const Title = styled(Box)(() => ({
  margin: "0.5rem 0 1rem 0",
  textAlign: "center",
  fontWeight: "600",
  fontSize: "1.25rem",
  color: "#fff",
  userSelect: "none",
}));

export const SubTitle = styled(Box)(() => ({
  textAlign: "center",
  fontSize: "0.875rem",
  color: "#B4B4B4",
  marginBottom: "2rem",
  userSelect: "none",
}));

export const StyledButton = styled(Button)(() => ({
  marginTop: "0 !important",
  minWidth: "1rem",
  padding: "0 1rem",
  margin: "0",
  textTransform: "unset",
  color: "#8E98B4",
  fontSize: "1rem",
  "&:hover": {
    backgroundColor: "unset !important",
  },
}));

export const Image = styled(Box)(({ heightval }: { heightval: number }) => ({
  // width: "100%",
  // height: "100%",
  img: {
    position: "absolute",
    left: 0,
    right: 0,
    margin: "0 auto",
    bottom: "-5.5rem",
    width: "auto",
    height: `${heightval / 2.5}px`,

    "@media (max-width: 768px)": {
      bottom: "-5.5rem",
      width: "auto",
      height: `${heightval / 2.4}px`,
    },

    "@media (min-height: 1250px)": {
      bottom: "-7rem",
      width: "auto",
      height: `${heightval / 2.51}px`,
    },

    "@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)": {
      height: `${heightval / 2.45}px`,
    },

    "@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3)": {
      height: `${heightval / 2.4}px`,
    },

    "@media only screen and (width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3)": {
      height: `${heightval / 2.55}px`,
    },
  },
}));

export const RulerContent = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "end",
  position: "absolute",
  zIndex: 1,
  // height: "calc(100% - 8rem)",
  bottom: "4.5rem",
  "@media (max-width: 768px)": {
    top: "-1.5rem",
    left: "0.5rem",
  },
}));

export const CustomButtonContainer = styled(Box)(() => ({
  position: "absolute",
  bottom: "1rem",
  left: "1rem",
  right: "1rem",
  width: "calc(100% - 2rem)",
  height: "3rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "@media (max-width: 768px)": {
    left: "1rem",
    right: "1rem",
    width: "calc(100% - 2rem)",
  },
}));

export const CustomButton = styled(Button)(() => ({
  width: "25rem",
  // margin: "2rem 0",
  borderRadius: "2rem",
  height: "3rem",
  background: "#E5FA40",
  color: "#232D49",
  textTransform: "none",
  fontWeight: 500,
  fontFamily: "Space Grotesk",
  "&:disabled": {
    background: "#899628 !important",
    color: "#232D49 !important",
  },
  "&:hover": {
    background: "#E5FA40 !important",
  },
}));
