import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { DYNAMIC_PAGES_MAP } from "../app/Pages";
import { helpers } from "../utils";

const useGetPageInfo = () => {
  const { pathname } = useLocation();

  const currentPageId = useMemo(() => helpers.getPageIdFromPath(pathname), [pathname]);

  const currentCohort = useMemo(() => helpers.getCohortFromPath(pathname), [pathname]);

  const currentVariantPageList = useMemo(() => DYNAMIC_PAGES_MAP[currentCohort], [currentCohort]);

  const progressValue = useMemo(() => currentVariantPageList.findIndex(({ id }) => id === currentPageId) + 1, [currentPageId, currentVariantPageList]);

  const pagesNumberWithNavigation = useMemo(() => currentVariantPageList.length, [currentVariantPageList]);

  return { currentVariantPageList, currentCohort, currentPageId, progressValue, pagesNumberWithNavigation };
};

export default useGetPageInfo;
