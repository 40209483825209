import { createRef, lazy, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { useAnswersContext } from "../../../context";
import { useAutoScrollDown, useGoBack, useIsMobile, useNextStep, useWindowSize } from "../../../hooks";
import { TPage } from "../../../model";
import { helpers } from "../../../utils";
import { WEIGHT_TYPE } from "../../../utils/enum";
import { bmiCalculate } from "../../../utils/helpers";
import { ArrowBackIos } from "@mui/icons-material";
import * as S from "./style";

const CustomToggleButton = lazy(() => import("../../../components/V2/CustomToggleButton/CustomToggleButton"));
const WeightRuler = lazy(() => import("../../../components/V2/WeightRuler/WeightRuler"));
const CustomButton = lazy(() => import("../../../components/Base/CustomButtonV2/CustomButton"));
const CustomStepProgressBar = lazy(() => import("../../../components/CustomStepProgressBar/CustomStepProgressBar"));

const WhatIsYourCurrentWeight: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });
  const handleGoBack = useGoBack({ pageId, nextPagePath });

  const { height } = useWindowSize();
  const isMobile = useIsMobile();

  const myRef = createRef<HTMLDivElement>();
  useAutoScrollDown(myRef);

  const { answers } = useAnswersContext();

  const selectedCurrentWeight = answers["whatIsYourCurrentWeight"]?.join("").split(" ");
  const selectedCurrentWeightValue: number | undefined = Number(selectedCurrentWeight?.[0]);
  const selectedCurrentWeightType: WEIGHT_TYPE | undefined = selectedCurrentWeight?.[1] as WEIGHT_TYPE;

  const heightvalue = answers["whatIsYourHeight"]?.join("").split(" ");
  const selectedHeight = heightvalue?.[0];
  const selectedHeightType = heightvalue?.[1];

  const [weightType, setWeightType] = useState<WEIGHT_TYPE>(selectedCurrentWeightType || WEIGHT_TYPE.KG);
  const [selectedWeight, setSelectedWeight] = useState<number>(selectedCurrentWeightValue || 65);

  const bmi = useMemo(() => {
    if (selectedHeight && selectedHeightType) {
      const result = bmiCalculate({ type: selectedHeightType, value: Number(selectedHeight) }, { type: weightType, value: selectedWeight });
      return result;
    }
  }, [selectedHeight, selectedHeightType, selectedWeight, weightType]);

  const onClick = () => {
    handleContinue(`${selectedWeight} ${weightType}`);
  };

  return (
    <S.Main>
      <S.PageContent height={height}>
        <S.HeaderContent>
          <S.StyledButton onClick={handleGoBack} disableRipple>
            <ArrowBackIos />
          </S.StyledButton>
          <S.PartContent>
            <CustomStepProgressBar part={2} step={7} />
          </S.PartContent>
        </S.HeaderContent>
        <S.Title>
          What`s your <S.ColoredText>current</S.ColoredText> weight?
        </S.Title>
        <S.SubTitle>(Determine your weight by swiping right or left)</S.SubTitle>
        <S.Content>
          <Box width="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center" marginBottom="1rem">
            <CustomToggleButton
              onChangeValue={(newValue: string) => {
                if (newValue === WEIGHT_TYPE.KG) {
                  const newWeight = helpers.lbToKg(selectedWeight);
                  setSelectedWeight(newWeight);
                } else {
                  const newWeight = helpers.kgToLb(selectedWeight);
                  setSelectedWeight(newWeight);
                }
                setWeightType(newValue as WEIGHT_TYPE);
              }}
              options={[WEIGHT_TYPE.KG, WEIGHT_TYPE.LB]}
              selected={weightType}
            />
          </Box>
          <S.WeightContent heightval={height}>
            <S.WeightText>{selectedWeight}</S.WeightText>
            <S.WeightType>{weightType}</S.WeightType>
          </S.WeightContent>
          <S.RulerContent ref={myRef} heightvalue={height}>
            <WeightRuler
              width={isMobile ? height / 2 : height / 2 - 50}
              height={100}
              step={1}
              value={selectedWeight}
              max={weightType === WEIGHT_TYPE.KG ? 300 : 660}
              onChange={(weight: number) => {
                setSelectedWeight(weight);
              }}
              color="#FFFFFF"
            />
          </S.RulerContent>
        </S.Content>
      </S.PageContent>
      <S.BMIContent width={height / 2}>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ fontSize: "1.5rem", marginRight: "1rem" }}>💪</Box>
          <Box>
            <S.BMIHeader>
              <S.BMIText>BMI:</S.BMIText>
              <S.BMIValue color={bmi?.color}>{bmi?.value}</S.BMIValue>
            </S.BMIHeader>
            <S.BMIDesc>{bmi?.text}</S.BMIDesc>
          </Box>
        </Box>
      </S.BMIContent>
      <CustomButton width={height / 2} text="Continue" onClick={onClick} />
    </S.Main>
  );
};

export default WhatIsYourCurrentWeight;
