import { lazy } from "react";
import { Box } from "@mui/material";
import { useGoBack, useNextStep, useWindowSize } from "../../../hooks";
import { TPage } from "../../../model";
import { ArrowBackIos } from "@mui/icons-material";
import chart from "../../../assets/lottie/calorieBurningWorkout.json";
import * as S from "./style";

const GoalsIcon = lazy(() => import("../../../components/Base/Svg/Icons/Goals"));
const CustomButton = lazy(() => import("../../../components/Base/CustomButtonV2/CustomButton"));
const CustomStepProgressBar = lazy(() => import("../../../components/CustomStepProgressBar/CustomStepProgressBar"));

const CalorieBurningWorkout: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });
  const handleGoBack = useGoBack({ pageId, nextPagePath });

  const { height } = useWindowSize();

  const onClick = () => {
    handleContinue();
  };

  return (
    <S.Main>
      <S.PageContent height={height}>
        <S.HeaderContent>
          <S.StyledButton onClick={handleGoBack} disableRipple>
            <ArrowBackIos />
          </S.StyledButton>
          <S.PartContent>
            <CustomStepProgressBar part={4} step={18} />
          </S.PartContent>
        </S.HeaderContent>
        <S.Content>
          <S.StyledLottie animationData={chart} loop={false} height={height} autoPlay={false} />
        </S.Content>
        <S.HeaderText>
          <Box>
            <GoalsIcon width="1rem" height="3.5rem" />
          </Box>
          <S.Title>
            <Box>Dancing: The Ultimate</Box>
            <S.ColoredText>Calorie-Burning Workout</S.ColoredText>
          </S.Title>
        </S.HeaderText>
        <S.SubTitle>
          Dancing is known to burn more calories than any other form of fitness workout. Additionally, it provides a significant boost to your mental well-being.
        </S.SubTitle>
        <CustomButton width={height / 2} text="Continue" onClick={onClick} />
      </S.PageContent>
    </S.Main>
  );
};

export default CalorieBurningWorkout;
