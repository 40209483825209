import at_home from "../../assets/img/V2/workOut/at_home.webp";
import at_the_gym from "../../assets/img/V2/workOut/at_the_gym.webp";
import outdoor from "../../assets/img/V2/workOut/outdoor.webp";
import any_place_is_ok from "../../assets/img/V2/workOut/any_place_is_ok.webp";

import { WORK_OUT } from "../enum";

const workOutOptionsV2 = [
  {
    id: 1,
    label: WORK_OUT.AT_HOME,
    img: at_home,
  },
  {
    id: 2,
    label: WORK_OUT.AT_THE_GYM,
    img: at_the_gym,
  },
  {
    id: 3,
    label: WORK_OUT.OUTDOOR,
    img: outdoor,
  },
  {
    id: 4,
    label: WORK_OUT.ANT_PLACE_IS_OK,
    img: any_place_is_ok,
  },
];

export default workOutOptionsV2;
