import { Box, Button, styled } from "@mui/material";

export const Main = styled(Box)(() => ({
  width: "100%",
  height: "calc(100% - 3rem)",
  overflowY: "hidden",
  overflowX: "hidden",
  position: "relative",
  WebkitOverflowScrolling: "unset",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  touchAction: "none",
  "@media (max-width: 768px)": {
    width: "90%",
    height: "calc(100% - 5rem)",
  },
}));

export const PageContent = styled(Box)(({ height }: { height: number }) => ({
  position: "relative",
  width: `${height / 2}px`,
  height: "auto",
  margin: "0rem auto",
  "@media (max-width: 768px)": {
    width: "100%",
  },
}));

export const Image = styled("img")(() => ({
  width: "100%",
  margin: "0.5rem auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "0.25rem",
  objectFit: "cover",
}));

export const CustomButton = styled(Button)(({ height }: { height: number }) => ({
  borderRadius: "0.5rem",
  height: "3rem",
  background: "#7b66ff",
  color: "#fff",
  textTransform: "unset",
  width: `${height / 2}px`,
  "&:hover": {
    background: "#7b66ff !important",
  },
  "@media (max-width: 768px)": {
    width: "100%",
  },
}));
