import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { firebaseService } from "../services/firebase.service";
import { useAnswersContext } from "../context";
import { TPage } from "../model";
import userService from "../services/user.service";

const useNextStep = (params: TPage): ((answer?: string | string[] | null) => void) => {
  const navigate = useNavigate();

  const { setAnswersContext } = useAnswersContext();

  return useCallback(
    (answer?: string | string[] | null) => {
      if (answer) {
        setAnswersContext(params.pageId, answer);
        userService.pushAnswer(window.config.USER_ID, {
          question: params.pageId,
          answer,
        });
      }
      firebaseService.logFirebaseEvent(`${params.pageId}_page_continue_button_clicked`);
      navigate("/" + params.nextPagePath);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params.pageId, params.nextPagePath]
  );
};

export default useNextStep;
