import { Reducer } from "react";
import { Answers, SET_ANSWER, SetAnswersAction } from "./types";
import storage from "../../utils/storage";
import { helpers } from "../../utils";

export const answersReducer: Reducer<Answers, SetAnswersAction> = (state: Answers, action: SetAnswersAction) => {
  switch (action.type) {
    case SET_ANSWER:
      state.answers[action.payload.pageId] = action.payload.answer;
      storage.set("answerKey", JSON.stringify(state.answers), true);
      const answers = helpers.deepCopy(state);
      return answers;
    default:
      return state;
  }
};
