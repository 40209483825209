import { LocalConfig } from "../model";
import { staticLocalConfig } from "./local";

const initialStaticLocalConfig = staticLocalConfig;

declare global {
  interface Window {
    config: LocalConfig;
    fbq?: any;
  }
}

window.config = window.config || initialStaticLocalConfig;
