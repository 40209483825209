import { Box, Button, styled } from "@mui/material";
import Lottie from "lottie-react";

export const Main = styled(Box)(() => ({
  width: "100%",
  height: "calc(100% - 5rem)",
  overflow: "hidden",
  touchAction: "none",
  position: "relative",
  WebkitOverflowScrolling: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "Space Grotesk",
  userSelect: "none",
  "@media (max-width: 768px)": {
    height: "calc(100% - 2.5rem)",
  },
}));

export const PageContent = styled(Box)(({ height }: { height: number }) => ({
  position: "relative",
  width: `${height / 2}px`,
  height: "calc(100% - 1rem)",
  margin: "0.5rem 0",
  "@media (max-width: 768px)": {
    width: "100%",
    height: "100%",
    margin: "0",
  },
}));

export const Content = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

export const HeaderText = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "space-between",
}));

export const StyledLottie = styled(Lottie)(({ height }: { height: number }) => ({
  userSelect: "none",
  width: "auto",
  height: `${height / 2}px`,
}));

export const PartText = styled("div")(() => ({
  color: "#FFFFFF",
  width: "100%",
  textAlign: "start",
  fontWeight: 600,
  fontSize: "3.25rem",
  minWidth: "10rem",
  paddingLeft: "0.75rem",
}));

export const GoalsText = styled("div")(() => ({
  color: "#E5FA40",
  width: "100%",
  fontSize: "1.75rem",
  paddingLeft: "1.75rem",
  fontWeight: "lighter",
  marginBottom: "1rem",
}));

export const ContentText = styled(Box)(() => ({
  textAlign: "start",
  fontSize: "0.875rem",
  fontWeight: "400",
  color: "#fff",
  padding: "0 1.5rem",
  lineHeight: "1.5rem",
  // marginBottom: "2rem",
  "@media (max-height: 700px) and (max-width: 768px)": {
    lineHeight: "1.4rem",
    padding: "0 1rem",
    fontSize: "0.75rem",
  },
}));

export const ImageContent = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  marginBottom: "1rem",
}));

export const Image = styled("img")(({ theme }) => ({
  width: "15rem",
}));

export const CustomButtonContainer = styled(Box)(() => ({
  position: "absolute",
  bottom: "1rem",
  left: "1rem",
  right: "1rem",
  width: "calc(100% - 2rem)",
  height: "3rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "@media (max-width: 768px)": {
    left: "1rem",
    right: "1rem",
    width: "calc(100% - 2rem)",
  },
}));

export const CustomButton = styled(Button)(() => ({
  width: "25rem",
  // margin: "2rem 0",
  borderRadius: "2rem",
  height: "3rem",
  background: "#E5FA40",
  color: "#232D49",
  textTransform: "none",
  fontWeight: 500,
  fontFamily: "Space Grotesk",
  "&:hover": {
    background: "#E5FA40 !important",
  },
}));
