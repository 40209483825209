export const SET_AUTH = "SET_AUTH";

export type Auth = {
  isLoggedIn: boolean;
  user: { email: string };
};

export type SetAuthAction = {
  type: typeof SET_AUTH;
  payload: Auth;
};

export interface IAuthContext {
  authContext: Auth;
  setAuthContext: (auth: Auth) => void;
}
