import { Box, Button, styled } from "@mui/material";
import backgrounImg from "../../../assets/img/wantToImproveYourWellbeingByDancing/shutterstock.webp";

export const Main = styled(Box)(({ height }: { height: number }) => ({
  position: "fixed",
  height: "calc(100% - 6rem)",
  margin: "0.5rem 0",
  background: `url(${backgrounImg})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  width: `${height / 2}px`,
  borderRadius: "0.25rem",
  "@media (max-width: 768px)": {
    width: "100%",
    margin: "0",
    height: "calc(100% - 2.5rem)",
    backgroundSize: "cover",
    transform: "scale(1.01)",
  },
}));

export const HeaderText = styled(Box)(() => ({
  color: "#fff",
  position: "absolute",
  left: "0",
  right: "0",
  bottom: "6rem",
  width: "100%",
  textAlign: "center",
  fontSize: "1.5rem",
  fontWeight: "600",
  "@media (max-width: 768px)": {
    fontSize: "1.75rem",
  },
}));

export const CustomButtonContainer = styled(Box)(() => ({
  position: "absolute",
  bottom: "1rem",
  left: "1rem",
  right: "1rem",
  width: "calc(100% - 2rem)",
  height: "3rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "1rem",
  "@media (max-width: 768px)": {
    left: "1rem",
    right: "1rem",
    width: "calc(100% - 2rem)",
  },
}));

export const CustomLightButton = styled(Button)(() => ({
  width: "40%",
  // margin: "2rem 0",
  borderRadius: "0.5rem",
  height: "3rem",
  background: "transparent",
  textTransform: "unset",
  border: "0.0625rem solid #fff",
  "&:hover": {
    background: "transparent",
  },
}));

export const CustomButton = styled(Button)(() => ({
  width: "60%",
  // margin: "2rem 0",
  borderRadius: "0.5rem",
  height: "3rem",
  background: "#7b66ff",
  textTransform: "unset",
  "&:hover": {
    background: "#7b66ff !important",
  },
}));
