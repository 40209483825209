import { loadStripe } from "@stripe/stripe-js";
import { CancelSubscription, CreateCustomer, GetConfig, GetCustomer, GetPrices, GetSubscription, PreparePayment, RetrievePaymentIntent, UpdateCustomer } from "../model/Stripe";
import { helpers, storage } from "../utils";
import ConfigList from "../model/ConfigList";

export const initializeStripe = async (publishableKey: string) => {
  stripePromise = loadStripe(publishableKey);
};

export let stripePromise: any;

class StripeService {
  apiUrl: string;

  constructor(apiUrl: string) {
    this.apiUrl = apiUrl;
  }

  async cancelSubscription(dto: CancelSubscription) {
    return fetch(`${this.apiUrl}/stripe/subscriptions`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dto),
    }).then((res) => helpers.json(res));
  }

  async getPrices(dto: GetPrices) {
    return fetch(`${this.apiUrl}/stripe/prices/${dto.configId}?cohortId=${dto.cohortId}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }).then((res) => helpers.json(res));
  }

  async createCustomer(dto: CreateCustomer) {
    return fetch(`${this.apiUrl}/stripe/customers`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dto),
    }).then((res) => helpers.json(res));
  }

  async updateCustomer(dto: UpdateCustomer) {
    return fetch(`${this.apiUrl}/stripe/customers`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dto),
    }).then((res) => helpers.json(res));
  }

  async getCustomer(dto: GetCustomer) {
    return fetch(`${this.apiUrl}/stripe/customers/${dto.configId}/${dto.customerId}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }).then((res) => helpers.json(res));
  }

  async getConfig(dto: GetConfig): Promise<ConfigList> {
    return fetch(`${this.apiUrl}/configs/${dto.configId}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }).then((res) => helpers.json(res));
  }

  async getSubscription(dto: GetSubscription) {
    return fetch(`${this.apiUrl}/stripe/${dto.configId}/subscriptions/${dto.subscriptionId}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }).then((res) => helpers.json(res));
  }

  async retrievePaymentIntent(dto: RetrievePaymentIntent) {
    return fetch(`${this.apiUrl}/stripe/${dto.configId}/paymentIntent/${dto.id}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }).then((res) => helpers.json(res));
  }

  async payment(dto: PreparePayment) {
    return fetch(`${this.apiUrl}/payment`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dto),
    }).then((res) => helpers.json(res));
  }
}

const stripeService = new StripeService(window.config.API_URL);

export const initializeConfigs = async () => {
  return stripeService
    .getConfig({
      configId: window.config.CONFIG_ID,
    })
    .then((response) => {
      storage.set("configs", response, true);
      return response;
    });
};

export const getConfigs = async (): Promise<ConfigList> => {
  const configs = storage.get("configs");

  if (configs !== null) {
    return JSON.parse(configs);
  }

  return initializeConfigs().then((configs) => {
    return configs;
  });
};

export default stripeService;
