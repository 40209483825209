import { CustomOption } from "../../model";
import danceLevel1 from "../../assets/img/danceLevel/dance_level1.webp";
import danceLevel2 from "../../assets/img/danceLevel/dance_level2.webp";
import danceLevel3 from "../../assets/img/danceLevel/dance_level3.webp";

const danceLevelOptions: CustomOption[] = [
  {
    id: 1,
    value: 0,
    payload: {
      title: "Beginner",
      subTitle: "Never try or just start.",
      img: danceLevel1,
    },
  },
  {
    id: 2,
    value: 50,
    payload: {
      title: "Intermediate",
      subTitle: "Dance occasionally.",
      img: danceLevel2,
    },
  },
  {
    id: 3,
    value: 100,
    payload: {
      title: "Advanced",
      subTitle: "Take dancing classes before.",
      img: danceLevel3,
    },
  },
];

export default danceLevelOptions;
