import React, { createRef, lazy } from "react";
import { Elements } from "@stripe/react-stripe-js";
import getSymbolFromCurrency from "currency-symbol-map";
import { Box } from "@mui/material";
import { stripePromise } from "../../../../../services/stripe.service";
import { useAutoScrollDown, useWindowSize } from "../../../../../hooks";
import { helpers } from "../../../../../utils";
import { Stripe } from "../../../../../model";
import cards from "../../../../../assets/img/payment/cards.webp";
import { StripeElementsOptions } from "@stripe/stripe-js";
import * as S from "./style";

const CloseIconButton = lazy(() => import("../../../../../components/Base/Svg/Icons/CloseIconButton/CloseIconButton"));
const SecuredIcon = lazy(() => import("../../../../../components/Base/Svg/Icons/Secured"));
const CheckoutForm = lazy(() => import("./CheckoutForm/CheckoutForm"));

type CheckoutProps = {
  plan?: Stripe.StripePrice;
  show: boolean;
  backdropClick: (event: any) => void;
  clientSecret: string;
  onPaymentFailed: () => void;
};

const Checkout: React.FC<CheckoutProps> = ({ plan, show, backdropClick, clientSecret, onPaymentFailed }) => {
  const { height } = useWindowSize();

  const myRef = createRef<HTMLDivElement>();
  useAutoScrollDown(myRef, 5000);
  const options: StripeElementsOptions = {
    clientSecret,
    appearance: {
      theme: "night",
      variables: {
        colorBackground: "#333D5A",
        colorText: "#fff",
        colorDanger: "#df1b41",
        fontFamily: "Space Grotesk",
        spacingUnit: "0.25rem",
        borderRadius: "0.75rem",
        fontWeightNormal: "600",
        focusBoxShadow: "#E5FA40",
        focusOutline: "#E5FA40",
      },
    },
  };

  return (
    <>
      <S.StyledModal open={show} onClose={backdropClick} aria-labelledby="child-modal-title" aria-describedby="child-modal-description" disableAutoFocus={true}>
        <S.Container>
          <CloseIconButton sx={{ top: "0rem" }} backgroundColor="#FFFFFF33" iconColor="#fff" height={height} onClick={backdropClick} />
          <S.Content height={height}>
            <S.Title>Billing Details</S.Title>
            {plan && (
              <>
                <S.Price>
                  <Box sx={{ display: "flex", justifyContent: "space-between", color: "#FFFFFF99", fontSize: "1.25rem" }}>
                    <Box>
                      {plan.recurring.interval_count} {helpers.capitalizeFirstLetter(plan.recurring.interval)}
                    </Box>
                    <Box>
                      {getSymbolFromCurrency(plan.currency)}
                      {Math.round((plan?.unit_amount / 100) * 100) / 100}
                    </Box>
                  </Box>
                  <S.Hr />
                  <Box sx={{ display: "flex", justifyContent: "space-between", color: "#fff", fontSize: "1.25rem" }}>
                    <Box sx={{ fontSize: "1.125rem" }}>Total</Box>
                    <S.Total>
                      <Box>
                        {getSymbolFromCurrency(plan.currency)}
                        {Math.round((plan?.unit_amount / 100) * 100) / 100}
                      </Box>
                      <Box sx={{ color: "#FFFFFF99", fontSize: "0.875rem" }}>
                        Then {getSymbolFromCurrency(plan.currency)}
                        {Math.round((plan?.unit_amount / 100) * 100) / 100} / {helpers.capitalizeFirstLetter(plan.recurring.interval)}
                      </Box>
                    </S.Total>
                  </Box>
                </S.Price>
                {clientSecret && (
                  <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm ref={myRef} onPaymentFailed={onPaymentFailed} />
                  </Elements>
                )}
              </>
            )}
            <S.Bottom>
              <S.BottomTitle>
                <SecuredIcon fill="#fff" />
                <Box>Your payment is secured</Box>
              </S.BottomTitle>
              <S.BankCards src={cards} alt="" />
              <S.BottomDesc>The payment processed by GM Appdev Limited, with registered address at Nafpliou, 15, Limassol, Cyprus, 3025</S.BottomDesc>
            </S.Bottom>
          </S.Content>
        </S.Container>
      </S.StyledModal>
    </>
  );
};

export default Checkout;
