import { lazy } from "react";
import { Box } from "@mui/material";
import { useNextStep, useWindowSize } from "../../../hooks";
import { TPage } from "../../../model";
import animationData from "../../../assets/lottie/part3DanceAnalysis.json";
import * as S from "./style";

const CustomButton = lazy(() => import("../../../components/Base/CustomButtonV2/CustomButton"));
const GoalsUnionIcon = lazy(() => import("../../../components/Base/Svg/Icons/GoalsUnion"));
const GoalsIcon = lazy(() => import("../../../components/Base/Svg/Icons/Goals"));

const Part3DanceAnalysis: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const { height } = useWindowSize();
  const handleContinue = useNextStep({ pageId, nextPagePath });

  return (
    <S.Main>
      <S.PageContent height={height}>
        <S.Content>
          <S.StyledLottie height={height} animationData={animationData} loop={true} />
          <S.HeaderText>
            <Box>
              <GoalsIcon />
            </Box>
            <S.PartText>Part 4</S.PartText>
            <Box>
              <GoalsUnionIcon />
            </Box>
          </S.HeaderText>
          <S.GoalsText>DANCE ANALYSIS</S.GoalsText>
          <S.ContentText>Knowledge of your gender, hobbies and lifestyle will help you create a personalized weight loss plan and get closer to your success.</S.ContentText>
        </S.Content>
        <CustomButton width={height / 2} text="Continue" onClick={() => handleContinue()} />
      </S.PageContent>
    </S.Main>
  );
};

export default Part3DanceAnalysis;
