import { CustomOption } from "../../model";
import currentBodyShapeOption1 from "../../assets/img/V2/currentBodyShape/currentBodyShapeOption1.webp";
import currentBodyShapeOption2 from "../../assets/img/V2/currentBodyShape/currentBodyShapeOption2.webp";
import currentBodyShapeOption3 from "../../assets/img/V2/currentBodyShape/currentBodyShapeOption3.webp";
import currentBodyShapeOption4 from "../../assets/img/V2/currentBodyShape/currentBodyShapeOption4.webp";
import currentBodyShapeOption5 from "../../assets/img/V2/currentBodyShape/currentBodyShapeOption5.webp";

const currentBodyShapeOptionsV2: CustomOption[] = [
  {
    id: 1,
    value: 0,
    payload: {
      title: "Slim",
      img: currentBodyShapeOption4,
    },
  },
  {
    id: 2,
    value: 1,
    payload: {
      title: "Normal",
      img: currentBodyShapeOption3,
    },
  },
  {
    id: 3,
    value: 2,
    payload: {
      title: "Overweight",
      img: currentBodyShapeOption2,
    },
  },
  {
    id: 4,
    value: 3,
    payload: {
      title: "Obese",
      img: currentBodyShapeOption1,
    },
  },
];

const targetBodyShapeOptionsV2: CustomOption[] = [
  {
    id: 1,
    value: 0,
    payload: {
      title: "Muscular",
      img: currentBodyShapeOption5,
    },
  },
  {
    id: 2,
    value: 1,
    payload: {
      title: "Slim",
      img: currentBodyShapeOption4,
    },
  },
  {
    id: 3,
    value: 2,
    payload: {
      title: "Normal",
      img: currentBodyShapeOption3,
    },
  },
  {
    id: 4,
    value: 3,
    payload: {
      title: "Overweight",
      img: currentBodyShapeOption2,
    },
  },
  {
    id: 5,
    value: 4,
    payload: {
      title: "Obese",
      img: currentBodyShapeOption1,
    },
  },
];

export { currentBodyShapeOptionsV2, targetBodyShapeOptionsV2 };
