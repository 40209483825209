import { lazy, memo } from "react";
import { Box } from "@mui/material";
import members from "../../../assets/img/welcome/members.webp";
import { useGoBack, useNextStep, useWindowSize } from "../../../hooks";
import { TPage } from "../../../model";
import * as S from "./style";

const CustomHeader = lazy(() => import("../../../components/CustomHeader/CustomHeader"));
const CustomButton = lazy(() => import("../../../components/Base/CustomButton/CustomButton"));
const CustomStepper = lazy(() => import("../../../components/CustomStepper/CustomStepper"));

const WhatOurMembersAreSaying: React.FC<TPage> = memo(({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });
  const handleGoBack = useGoBack({ pageId, nextPagePath });

  const { height } = useWindowSize();

  return (
    <S.Main>
      <S.PageContent height={height}>
        <CustomHeader part={4} header="FITNESS ANALYSIS" />
        <CustomStepper step={3} onBack={handleGoBack} />
        <S.Content>
          <S.Title>
            <Box>Here is what our</Box>
            <Box>members are saying</Box>
          </S.Title>
          <S.Image src={members} alt="" />
          <S.BottomText>Let’s say goodbye to boring workouts!</S.BottomText>
        </S.Content>
        <CustomButton width={height / 2} text="Continue" onClick={() => handleContinue()} />
      </S.PageContent>
    </S.Main>
  );
});

export default WhatOurMembersAreSaying;
