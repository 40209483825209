const enum DANCE_STYLE_V2 {
  ZUMBA = "Zumba",
  LATIN = "Latin",
  AEROBICS = "Aerobics",
  HIPHOP = "Hip-hop",
  BELLY = "Belly",
  NON_PREFERENCE = "Non preference",
}

export default DANCE_STYLE_V2;
