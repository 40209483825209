import { Snackbar, styled } from "@mui/material";

export const StyledSnackBar = styled(Snackbar)(() => ({
  top: "6rem !important",

  "& .MuiAlert-standardWarning": {
    backgroundColor: "#E5FA40 !important",
    color: "#232D49 !important",
    borderRadius: "1rem",
    fontFamily: "Space Grotesk !important",
    maxWidth: "10rem !important",

    "& .MuiAlert-icon": {
      display: "flex",
      alignItems: "center",
    },
  },

  "& .MuiAlert-action": {
    padding: "0 !important",
    margin: "0 !important",
    width: "0 !important",
    overflow: "hidden !important",
  },

  "@media (max-width: 768px)": {
    top: "1rem !important",
    left: "0 important",
    right: "0 important",
    display: "flex",
    justifyContent: "center",
  },
}));
