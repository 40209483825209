import { lazy } from "react";
import { Box } from "@mui/material";
import { useGoBack, useNextStep, useWindowSize } from "../../../hooks";
import { TPage } from "../../../model";
import { WORK_OUT } from "../../../utils/enum";
import { workPlaceOptions } from "../../../utils/constants";
import { useAnswersContext } from "../../../context";
import { ArrowBackIos } from "@mui/icons-material";
import * as S from "./style";

const ImageSliderMulti = lazy(() => import("../../../components/V2/ImageSliderMulti/ImageSliderMulti"));
const CustomButton = lazy(() => import("../../../components/Base/CustomButtonV2/CustomButton"));
const CustomStepProgressBar = lazy(() => import("../../../components/CustomStepProgressBar/CustomStepProgressBar"));

const WorkoutPlace: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });
  const handleGoBack = useGoBack({ pageId, nextPagePath });

  const { height } = useWindowSize();

  const { answers } = useAnswersContext();

  const selectedFavoriteWorkoutPlace: WORK_OUT = (answers["whichPlaceDoYouPreferForYourWorkout"]?.join("") as WORK_OUT) || WORK_OUT.ANT_PLACE_IS_OK;

  return (
    <S.Main>
      <S.PageContent height={height}>
        <S.HeaderContent>
          <S.StyledButton onClick={handleGoBack} disableRipple>
            <ArrowBackIos />
          </S.StyledButton>
          <S.PartContent>
            <CustomStepProgressBar part={3} step={13} />
          </S.PartContent>
        </S.HeaderContent>
        <S.Content>
          <S.StyledImageSliderContent>
            <ImageSliderMulti options={workPlaceOptions[selectedFavoriteWorkoutPlace].images} height={height} />
          </S.StyledImageSliderContent>
        </S.Content>
        <S.Title>
          <Box>{workPlaceOptions[selectedFavoriteWorkoutPlace].title}</Box>
        </S.Title>
        <S.SubTitle>{workPlaceOptions[selectedFavoriteWorkoutPlace].subTitle}</S.SubTitle>
        <CustomButton width={height / 2} text="Continue" onClick={() => handleContinue()} />
      </S.PageContent>
    </S.Main>
  );
};

export default WorkoutPlace;
