import { createRef, lazy, useMemo, useState } from "react";
import { Box } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useAnswersContext } from "../../../context";
import { useAutoScrollDown, useNextStep, useWindowSize, useIsMobile, useGoBack } from "../../../hooks";
import { TPage } from "../../../model";
import { helpers } from "../../../utils";
import { WEIGHT_TYPE } from "../../../utils/enum";
import { weightChangeCalculate } from "../../../utils/helpers";
import * as S from "./style";

const CustomHeader = lazy(() => import("../../../components/CustomHeader/CustomHeader"));
const CustomButton = lazy(() => import("../../../components/Base/CustomButton/CustomButton"));
const CustomToggleButton = lazy(() => import("../../../components/CustomToggleButton/CustomToggleButton"));
const CustomStepper = lazy(() => import("../../../components/CustomStepper/CustomStepper"));
const WeightRuler = lazy(() => import("../../../components/WeightRuler/WeightRuler"));

const GoalWeight: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });
  const handleGoBack = useGoBack({ pageId, nextPagePath });

  const { height } = useWindowSize();
  const isMobile = useIsMobile();

  const myRef = createRef<HTMLDivElement>();
  useAutoScrollDown(myRef);

  const { answers } = useAnswersContext();

  const selectedGoalWeight = answers["goalWeight"]?.join("").split(" ");
  const selectedGoalWeightValue: number | undefined = Number(selectedGoalWeight?.[0]);
  const selectedGoalWeightType: WEIGHT_TYPE | undefined = selectedGoalWeight?.[1] as WEIGHT_TYPE;

  const weight = answers["currentWeight"]?.join("").split(" ");
  const currentWeight = weight?.[0];
  const currentWeightType = weight?.[1];

  const [weightType, setWeightType] = useState<WEIGHT_TYPE>(selectedGoalWeightType || WEIGHT_TYPE.KG);
  const [selectedWeight, setSelectedWeight] = useState<number>(selectedGoalWeightValue || 60);

  const weightChange = useMemo(() => {
    if (currentWeight && currentWeightType) {
      const result = weightChangeCalculate({ type: currentWeightType, value: Number(currentWeight) }, { type: weightType, value: selectedWeight });
      return result;
    }
  }, [currentWeight, currentWeightType, selectedWeight, weightType]);

  const onClick = () => {
    handleContinue(`${selectedWeight} ${weightType}`);
  };

  return (
    <S.Main>
      <S.PageContent height={height}>
        <CustomHeader part={2} header="BODY DATA" />
        <CustomStepper step={1} onBack={handleGoBack} />
        <S.Content>
          <S.Title>What`s your goal weight?</S.Title>
          <Box width="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <CustomToggleButton
              onChangeValue={(newValue: string) => {
                if (newValue === WEIGHT_TYPE.KG) {
                  const newWeight = helpers.lbToKg(selectedWeight);
                  setSelectedWeight(newWeight);
                } else {
                  const newWeight = helpers.kgToLb(selectedWeight);
                  setSelectedWeight(newWeight);
                }
                setWeightType(newValue as WEIGHT_TYPE);
              }}
              options={[WEIGHT_TYPE.KG, WEIGHT_TYPE.LB]}
              selected={weightType}
            />
          </Box>
          <S.WeightContent>
            <S.WeightText>{selectedWeight}</S.WeightText>
            <S.WeightType>{weightType}</S.WeightType>
          </S.WeightContent>
          <S.RulerContent ref={myRef} heightvalue={height}>
            <S.ArrowLeftButton
              onClick={() => {
                const newWeight = selectedWeight - 1;
                if (newWeight !== 0) {
                  setSelectedWeight(newWeight);
                }
              }}
            >
              <KeyboardArrowLeftIcon />
            </S.ArrowLeftButton>
            <WeightRuler
              width={isMobile ? height / 2 : height / 2 - 50}
              height={100}
              step={1}
              value={selectedWeight}
              max={weightType === WEIGHT_TYPE.KG ? 300 : 660}
              onChange={(weight: number) => {
                setSelectedWeight(weight);
              }}
            />
            <S.ArrowRightButton
              color="primary"
              onClick={() => {
                const newWeight = selectedWeight + 1;
                if (newWeight <= (weightType === WEIGHT_TYPE.KG ? 300 : 660)) {
                  setSelectedWeight(newWeight);
                }
              }}
            >
              <KeyboardArrowRightIcon />
            </S.ArrowRightButton>
          </S.RulerContent>
        </S.Content>

        <S.BMIContent width={height / 2}>
          <S.GoalHeader>Reasonable goal</S.GoalHeader>
          <S.GoalText>{weightChange}</S.GoalText>
          <S.GoalBottom>Stick o your diet, you'll see the results!</S.GoalBottom>
        </S.BMIContent>

        <CustomButton width={height / 2} text="Continue" onClick={onClick} />
      </S.PageContent>
    </S.Main>
  );
};

export default GoalWeight;
