import { Box, keyframes, styled } from "@mui/material";

export const Main = styled(Box)(() => ({
  width: "100%",
  height: "calc(100% - 5rem)",
  overflowY: "auto",
  overflowX: "hidden",
  position: "relative",
  WebkitOverflowScrolling: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "@media (max-width: 768px)": {
    height: "calc(100% - 2.5rem)",
  },
}));

export const PageContent = styled(Box)(({ height }: { height: number }) => ({
  position: "relative",
  width: `${height / 2}px`,
  // width: "50%",
  height: "100%",
  "@media (max-width: 768px)": {
    width: "100%",
  },
}));

export const Content = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  marginTop: "1rem",
  "@media (max-width: 768px)": {
    marginTop: "0rem",
  },
}));

export const Title = styled(Box)(() => ({
  marginBottom: "2rem",
  textAlign: "center",
  fontWeight: "600",
  fontSize: "1.25rem",
  "@media (max-width: 768px)": {
    marginBottom: "1rem",
  },
}));

export const OptionsContent = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  "@media (max-width: 768px)": {
    width: "90%",
    margin: "0 auto",
  },
}));

export const Option = styled(Box)(({ selected, dark, shine }: { selected: boolean; dark: number; shine: number }) => ({
  width: "100%",
  background: dark === 1 ? "#CDD4ED" : "#fff",
  padding: "1rem 0",
  textAlign: "center",
  borderRadius: "0.5rem",
  color: "#232D49",
  cursor: "pointer",
  position: "relative",

  "@media (max-width: 768px)": {
    padding: "0.75rem 0",
  },

  ...(!selected && {
    "&::before": {
      content: '""',
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundImage: "linear-gradient(120deg,transparent 30%,rgba(244, 246, 252, .8),transparent 90%)",
      top: 0,
      left: "-100%",
      animation: `${shineAnimation} 5s normal linear`,
    },
    ...(shine === 1 && {
      "&::after": {
        content: '""',
        position: "absolute",
        width: "100%",
        height: "100%",
        backgroundImage: "linear-gradient(120deg,transparent 30%,rgba(244, 246, 252, .8),transparent 90%)",
        top: 0,
        left: "-100%",
        animation: `${shineAnimation} 5s normal linear`,
      },
    }),
  }),
}));

const shineAnimation = keyframes`
0% {left: -100%}
20% {left: 100%}
100% {left: 100%}
}
`;
