import { createRef, useEffect, useState, lazy } from "react";
import { useGetPageInfo, useWindowSize } from "../../../hooks";
import { Stripe } from "../../../model";
import { Box } from "@mui/material";
import { useAlertContext } from "../../../context";
import { RetrievePaymentIntent } from "../../../model/Stripe";
import stripeService from "../../../services/stripe.service";
import { firebaseService } from "../../../services/firebase.service";
import { fbq } from "../../../services/metapixel.service";
import { getUserId } from "../../../services/user.service";
import { storage } from "../../../utils";
import { captureException } from "../../../services/sentry.service";
import moneyBack from "../../../assets/img/V2/planIsReady/verify.webp";
import Summary from "./Summary/Summary";
import Prices from "./Payment/Prices/Prices";
import Checkout from "./Payment/Checkout/Checkout";
import * as S from "./style";

const PaymentSuccess = lazy(() => import("./Payment/PaymentSuccess/PaymentSuccess"));
const PaymentFailed = lazy(() => import("./Payment/PaymentFailed/PaymentFailed"));

const YourPlanIsReady = () => {
  const { currentCohort } = useGetPageInfo();

  const { height } = useWindowSize();

  const { showAlert } = useAlertContext();

  const checkoutRef = createRef<HTMLDivElement>();

  const [loading, setLoading] = useState<boolean>(true);
  const [show, setShow] = useState<boolean>(false);
  const [plan, setPlan] = useState<Stripe.StripePrice>();
  const [clientSecret, setClientSecret] = useState("");
  const [paymentDisabled, setPaymentDisabled] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentFailed, setPaymentFailed] = useState(false);

  useEffect(() => {
    const paymentIntentId = new URLSearchParams(window.location.search).get("payment_intent");

    if (!paymentIntentId) {
      setLoading(false);
      return;
    }

    // setShow(true);
    // setPaymentDisabled(true);
    const retrievePaymentIntent: RetrievePaymentIntent = {
      configId: window.config.CONFIG_ID,
      id: paymentIntentId,
    };

    stripeService.retrievePaymentIntent(retrievePaymentIntent).then((paymentIntent) => {
      setLoading(false);
      if (!paymentIntent) {
        return;
      }

      switch (paymentIntent.status) {
        case "succeeded":
          onPaymentSuccess(paymentIntent);
          break;
        case "processing":
          break;
        case "requires_payment_method":
          onPaymentFailed();
          break;
        default:
          onPaymentFailed();
          break;
      }
    });
  }, []);

  const onPriceSelected = async (price: Stripe.StripePrice) => {
    firebaseService.logFirebaseEvent("getmyplan_button_clicked");
    fbq("trackCustom", "getmyplan_button_clicked");
    fbq("track", "AddToCart");
    setLoading(true);

    getUserId()
      .then((userId) =>
        stripeService.payment({
          configId: window.config.CONFIG_ID,
          customerId: storage.get("customerId"),
          priceId: price.id,
          subscriptionId: plan && plan.id === price.id ? storage.get("subscriptionId") : null,
          userId: userId,
          cohortId: currentCohort,
          email: storage.get("email"),
          initialUrl: storage.get("initialUrl"),
        })
      )
      .then(({ user, subscription, customer }) => {
        storage.set("subscriptionId", subscription.id);
        storage.set("customerId", customer.id);
        setClientSecret(subscription.latest_invoice.payment_intent.client_secret);
        setLoading(false);
        setShow(true);
        setPlan(price);
      })
      .catch((err) => {
        showAlert("Payment failed");
        captureException(err);
        setLoading(false);
      });
  };

  const onTryAgain = () => {
    setClientSecret("");
    setPaymentDisabled(false);
    setPaymentFailed(false);
    setShow(true);
  };

  const onPaymentSuccess = (paymentIntent: any) => {
    setShow(true);
    setPaymentSuccess(true);
    setPaymentDisabled(true);
    firebaseService.logFirebaseEvent("checkout_payment_succeeded");
    fbq("trackCustom", "checkout_payment_succeeded");
    fbq("track", "Purchase", {
      content_ids: [paymentIntent.id],
      // eventref: 'fb_oea',
      currency: paymentIntent.currency,
      num_items: 1,
      value: paymentIntent.amount / 100.0,
    });
  };

  const onPaymentFailed = () => {
    firebaseService.logFirebaseEvent("checkout_payment_failed");
    fbq("trackCustom", "checkout_payment_failed");
    setPaymentFailed(true);
    setPaymentDisabled(true);
  };

  return (
    <S.Main>
      {(paymentFailed || paymentSuccess) && <S.Overlay />}
      <S.PageContent width={height / 2}>
        <S.Header>
          <S.Color />
          <S.Title>
            Your Plan is <S.ColoredText>Ready!</S.ColoredText>
          </S.Title>
        </S.Header>
        <S.Content>
          <Box sx={{ marginBottom: "1.5rem" }}>
            <Summary />
          </Box>
          <Box sx={{ marginBottom: "2.5rem", position: "relative", zIndex: 15 }}>
            <Prices onPriceSelected={onPriceSelected} loading={loading} disabled={paymentDisabled} />
          </Box>
          {clientSecret && !loading && show && (
            <Box ref={checkoutRef}>
              <Checkout
                backdropClick={() => {
                  setShow(false);
                  setClientSecret("");
                  setPaymentDisabled(false);
                }}
                plan={plan}
                clientSecret={clientSecret}
                onPaymentFailed={onPaymentFailed}
                show={show}
              />
            </Box>
          )}

          <S.MoneyBackOverlay>
            <S.MoneyBackWrapper>
              <S.MoneyBackHeader>100% Money-Back Guarantee</S.MoneyBackHeader>
              <S.MoneyBackContent>
                We aim to maintain the highest level of customer satisfaction and want you to have confidence in the product we provide. If you believe our plan hasn't delivered
                the results you desired after the 30-day period and can demonstrate that you followed it, we are prepared to refund your money. Please review our money-back policy
                for more information. Your satisfaction is our priority.
              </S.MoneyBackContent>
              <S.MoneyBackIconWrapper>
                <S.MoneyBackIcon src={moneyBack} />
              </S.MoneyBackIconWrapper>
            </S.MoneyBackWrapper>
          </S.MoneyBackOverlay>
        </S.Content>

        {/* <S.CustomButtonContainer>
          <S.CustomButton size="large" variant="contained" onClick={() => handleContinue()}>
            Get Plan
          </S.CustomButton>
        </S.CustomButtonContainer> */}
      </S.PageContent>
      <PaymentSuccess show={paymentSuccess} />
      <PaymentFailed show={paymentFailed} onTryAgain={onTryAgain} />
    </S.Main>
  );
};

export default YourPlanIsReady;
