import { CustomOption } from "../../model";
import activityLevel1 from "../../assets/img/activityLevel/activity_level1.webp";
import activityLevel2 from "../../assets/img/activityLevel/activity_level2.webp";
import activityLevel3 from "../../assets/img/activityLevel/activity_level3.webp";
import activityLevel4 from "../../assets/img/activityLevel/activity_level4.webp";

const activityLevelOptions: CustomOption[] = [
  {
    id: 1,
    value: 0,
    payload: {
      title: "Passive",
      subTitle: "I easily get out of breath while walking up the stairs.",
      img: activityLevel1,
    },
  },
  {
    id: 2,
    value: 40,
    payload: {
      title: "Lightly Active",
      subTitle: "Rarely I do quick workouts.",
      img: activityLevel2,
    },
  },
  {
    id: 3,
    value: 75,
    payload: {
      title: "Moderately Active",
      subTitle: "I exercise regularly at least 1-2 times a week.",
      img: activityLevel3,
    },
  },
  {
    id: 4,
    value: 100,
    payload: {
      title: "Highly Active",
      subTitle: "Fitness is an essential part of my life.",
      img: activityLevel4,
    },
  },
];

export default activityLevelOptions;
