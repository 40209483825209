import { createRef, lazy, useState } from "react";
import { Box } from "@mui/material";
import { useAutoScrollDown, useClickAnimation, useGoBack, useNextStep, useWindowSize } from "../../../hooks";
import { TPage } from "../../../model";
import { AGE_RANGE } from "../../../utils/enum";
import { useAnswersContext } from "../../../context";
import * as S from "./style";

const Tip = lazy(() => import("../../../components/Base/Tip/Tip"));
const CustomHeader = lazy(() => import("../../../components/CustomHeader/CustomHeader"));
const CustomButton = lazy(() => import("../../../components/Base/CustomButton/CustomButton"));
const CustomStepper = lazy(() => import("../../../components/CustomStepper/CustomStepper"));

const AgeRange: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });
  const handleGoBack = useGoBack({ pageId, nextPagePath });

  const buttonRef = createRef<HTMLButtonElement>();
  const active = useClickAnimation(buttonRef);

  const { height } = useWindowSize();

  const { answers } = useAnswersContext();
  const prevAgeRange: AGE_RANGE | undefined = answers["ageRange"]?.join("") as AGE_RANGE;

  const [selectedOption, setSelectedOption] = useState<AGE_RANGE>(prevAgeRange);

  const myRef = createRef<HTMLDivElement>();
  useAutoScrollDown(myRef, 730);

  const onClick = () => {
    if (selectedOption) handleContinue(selectedOption);
  };

  return (
    <S.Main>
      <S.PageContent height={height}>
        {/* <S.Main2> */}
        <CustomHeader part={3} header="ABOUT YOU" />
        <CustomStepper step={2} onBack={handleGoBack} />
        <S.Content>
          <S.Title>What is your age range?</S.Title>
          <S.OptionsContent gap="0.5rem" ref={myRef}>
            <S.OptionColumn>
              <S.Option
                selected={!!selectedOption}
                shine={active ? 1 : 0}
                key={AGE_RANGE._18_24}
                dark={selectedOption === AGE_RANGE._18_24 ? 1 : 0}
                onClick={() => setSelectedOption(AGE_RANGE._18_24)}
              >
                {AGE_RANGE._18_24}
              </S.Option>
              <S.Option
                selected={!!selectedOption}
                shine={active ? 1 : 0}
                key={AGE_RANGE._25_29}
                dark={selectedOption === AGE_RANGE._25_29 ? 1 : 0}
                onClick={() => setSelectedOption(AGE_RANGE._25_29)}
              >
                {AGE_RANGE._25_29}
              </S.Option>
            </S.OptionColumn>
            <S.OptionColumn>
              <S.Option
                selected={!!selectedOption}
                shine={active ? 1 : 0}
                key={AGE_RANGE._30_44}
                dark={selectedOption === AGE_RANGE._30_44 ? 1 : 0}
                onClick={() => setSelectedOption(AGE_RANGE._30_44)}
              >
                {AGE_RANGE._30_44}
              </S.Option>
              <S.Option
                selected={!!selectedOption}
                shine={active ? 1 : 0}
                key={AGE_RANGE._45_49}
                dark={selectedOption === AGE_RANGE._45_49 ? 1 : 0}
                onClick={() => setSelectedOption(AGE_RANGE._45_49)}
              >
                {AGE_RANGE._45_49}
              </S.Option>
            </S.OptionColumn>
            <S.OptionColumn>
              <S.Option
                selected={!!selectedOption}
                shine={active ? 1 : 0}
                key={AGE_RANGE._50_54}
                dark={selectedOption === AGE_RANGE._50_54 ? 1 : 0}
                onClick={() => setSelectedOption(AGE_RANGE._50_54)}
              >
                {AGE_RANGE._50_54}
              </S.Option>
              <S.Option
                selected={!!selectedOption}
                shine={active ? 1 : 0}
                key={AGE_RANGE._54_58}
                dark={selectedOption === AGE_RANGE._54_58 ? 1 : 0}
                onClick={() => setSelectedOption(AGE_RANGE._54_58)}
              >
                {AGE_RANGE._54_58}
              </S.Option>
            </S.OptionColumn>

            <S.Option
              selected={!!selectedOption}
              shine={active ? 1 : 0}
              key={AGE_RANGE._58_PLUS}
              dark={selectedOption === AGE_RANGE._58_PLUS ? 1 : 0}
              onClick={() => setSelectedOption(AGE_RANGE._58_PLUS)}
            >
              {AGE_RANGE._58_PLUS}
            </S.Option>
          </S.OptionsContent>
        </S.Content>
        {/* </S.Main2> */}
      </S.PageContent>

      <Tip
        width={height / 2}
        sx={{
          left: "0",
          right: "0",
          margin: "0 auto",
          "@media (max-width: 768px)": {
            margin: "0 auto",
            width: "calc(100% - 3rem)",
          },
        }}
      >
        <Box sx={{ fontSize: "0.75rem", fontWeight: "600", marginBottom: "0.25rem" }}>We ask your age to create your personal plan</Box>
        <Box>Older people send to have more body fat than younger people with the same BMI.</Box>
      </Tip>

      <CustomButton ref={buttonRef} width={height / 2} disabled={selectedOption === undefined} text="Continue" onClick={onClick} />
    </S.Main>
  );
};

export default AgeRange;
