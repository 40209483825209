import { Cohort, DEFAULT_COHORT } from "../constants/rootConstants";

const getCohortFromPath = (pathName: string): Cohort => {
  const pathSplit = pathName.split("/");
  const path = pathSplit[1];

  if (!(Object.values(Cohort) as string[]).includes(path)) {
    return DEFAULT_COHORT;
  }

  return path as Cohort;
};

export default getCohortFromPath;
