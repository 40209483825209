import { lazy } from "react";
import { useNextStep, useWindowSize } from "../../../hooks";
import { TPage } from "../../../model";
import wreathLeft from "../../../assets/img/V2/landing/wreathLeft.webp";
import wreathRight from "../../../assets/img/V2/landing/wreathRight.webp";
import stars from "../../../assets/img/V2/landing/stars.webp";
import img_1 from "../../../assets/img/V2/landing/img_1.webp";
import img_2 from "../../../assets/img/V2/landing/img_2.webp";
import img_3 from "../../../assets/img/V2/landing/img_3.webp";
import img_4 from "../../../assets/img/V2/landing/img_4.webp";
import img_5 from "../../../assets/img/V2/landing/img_5.webp";
import img_6 from "../../../assets/img/V2/landing/img_6.webp";
import img_7 from "../../../assets/img/V2/landing/img_7.webp";
import img_8 from "../../../assets/img/V2/landing/img_8.webp";
import img_9 from "../../../assets/img/V2/landing/img_9.webp";
import img_10 from "../../../assets/img/V2/landing/img_10.webp";
import * as S from "./style";

const LandingImageSlider = lazy(() => import("../../../components/V2/LandingImageSlider/LandingImageSlider"));

const Landing: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });

  const { height } = useWindowSize();

  const onClick = () => {
    handleContinue();
  };

  return (
    <S.Main>
      <S.PageContent height={height}>
        <S.Content>
          <S.HeaderContent>
            <S.WreathLeft src={wreathLeft} />
            <S.HeaderTitle>BEST FITNESS</S.HeaderTitle>
            <S.HeaderTitle>APP</S.HeaderTitle>
            <S.WreathRight src={wreathRight} />
            <S.Stars src={stars} />
          </S.HeaderContent>
          <S.StyledImageSliderContent>
            <LandingImageSlider defaultDirection={1} images={[img_1, img_2, img_3, img_4, img_5]} />
            <LandingImageSlider defaultDirection={-1} images={[img_6, img_7, img_8, img_9, img_10]} />
          </S.StyledImageSliderContent>
          <S.Title sx={{ marginBottom: 0 }}>Reach Your Goals!</S.Title>
          <S.TitleContent>
            <S.Title>Join Now!</S.Title>
          </S.TitleContent>
          <S.SubTitle>
            <S.ColoredText>%93</S.ColoredText> of users reached their fitness goals with our app. Join now to achieve yours!
          </S.SubTitle>
        </S.Content>
      </S.PageContent>
      <S.CustomButtonContainer>
        <S.CustomButton onClick={onClick}>Continue</S.CustomButton>
      </S.CustomButtonContainer>
    </S.Main>
  );
};

export default Landing;
