const enum DANCE_STYLE {
  ZUMBA = "Zumba",
  AEROBIC = "Aerobic",
  HIPHOP = "Hip-hop",
  JAZZ = "Jazz",
  MODERN = "Modern",
  NON_PREFERENCE = "Non preference",
}

export default DANCE_STYLE;
