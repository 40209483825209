import { createRef, lazy, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useAutoScrollDown, useGoBack, useNextStep, useWindowSize } from "../../../hooks";
import { TPage } from "../../../model";
import { whichAreasImproveOptionsV2 } from "../../../utils/constants";
import { WHICH_AREAS_IMPROVE_V2 } from "../../../utils/enum";
import img from "../../../assets/img/V2/whichAreasImprove/img.webp";
import { useAlertContext, useAnswersContext } from "../../../context";
import { ArrowBackIos } from "@mui/icons-material";
import * as S from "./style";

const LineShoulders = lazy(() => import("./Line/img/LineShoulders"));
const LineArms = lazy(() => import("./Line/img/LineArms"));
const LineBelly = lazy(() => import("./Line/img/LineBelly"));
const LineButt = lazy(() => import("./Line/img/LineButt"));
const LineLegs = lazy(() => import("./Line/img/LineLegs"));
const LazyLoadImage = lazy(() => import("../../../components/LazyLoadImage/LazyLoadImage"));
const CustomButton = lazy(() => import("../../../components/Base/CustomButtonV2/CustomButton"));
const CustomStepProgressBar = lazy(() => import("../../../components/CustomStepProgressBar/CustomStepProgressBar"));

const WhichAreasWouldYouLikeToImprove: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });
  const handleGoBack = useGoBack({ pageId, nextPagePath });
  const { height } = useWindowSize();

  const { showAlert } = useAlertContext();

  const { answers } = useAnswersContext();
  const prevWhichAreasImprove = answers["whichAreasWouldsYouLikeToImprove"] as WHICH_AREAS_IMPROVE_V2[];

  const [selectedOptions, setSelectedOptions] = useState<WHICH_AREAS_IMPROVE_V2[]>(prevWhichAreasImprove || []);
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const [isElementsVisible, setElementsVisible] = useState(false);

  const myRef = createRef<HTMLDivElement>();
  useAutoScrollDown(myRef);

  const onClick = () => {
    if (selectedOptions.length > 0) handleContinue(selectedOptions);
    else showAlert("Select an Option", "warning", { vertical: "top", horizontal: "right" });
  };

  useEffect(() => {
    if (imageLoaded) {
      const timeout = setTimeout(() => {
        setElementsVisible(true);
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [imageLoaded]);

  return (
    <S.Main>
      <S.PageContent height={height}>
        <S.Content>
          <S.HeaderContent>
            <S.StyledButton onClick={handleGoBack} disableRipple>
              <ArrowBackIos />
            </S.StyledButton>
            <S.PartContent>
              <CustomStepProgressBar part={1} step={4} />
            </S.PartContent>
          </S.HeaderContent>
          <S.Title>
            <Box>Which area(s) would</Box>
            <Box>you like to improve?</Box>
          </S.Title>
          <S.BodyArea>
            <>
              <S.OptionsContent ref={myRef}>
                {whichAreasImproveOptionsV2.map((opt, i) => {
                  return (
                    <S.Option
                      key={opt}
                      initial={isElementsVisible ? { x: -100, opacity: 0 } : { x: -100, opacity: 0 }}
                      animate={isElementsVisible ? { x: 0, opacity: 1 } : { x: -100, opacity: 0 }}
                      transition={isElementsVisible ? { delay: i * 0.2 } : { delay: 1000 }}
                      dark={selectedOptions.includes(opt) ? 1 : 0}
                      onClick={() => {
                        let newSelectedOptions = [...selectedOptions];
                        if (opt === WHICH_AREAS_IMPROVE_V2.FULL_BODY) {
                          if (newSelectedOptions.includes(WHICH_AREAS_IMPROVE_V2.FULL_BODY)) {
                            setSelectedOptions([]);
                          } else {
                            setSelectedOptions(whichAreasImproveOptionsV2);
                          }
                        } else {
                          if (newSelectedOptions.includes(opt)) {
                            if (newSelectedOptions.includes(WHICH_AREAS_IMPROVE_V2.FULL_BODY)) {
                              const index = newSelectedOptions.findIndex((i) => i === WHICH_AREAS_IMPROVE_V2.FULL_BODY);
                              newSelectedOptions.splice(index, 1);
                            }
                            const index = newSelectedOptions.findIndex((i) => i === opt);
                            newSelectedOptions.splice(index, 1);
                          } else {
                            newSelectedOptions.push(opt);
                            const newWhichAreasImproveOptions = [...whichAreasImproveOptionsV2];
                            const others = newWhichAreasImproveOptions.slice(1);
                            const newOthers = [...others];
                            const newArray = newSelectedOptions.filter((item) => item !== opt);
                            if (newOthers.length === newArray.length + 1) {
                              newSelectedOptions.push(WHICH_AREAS_IMPROVE_V2.FULL_BODY);
                            }
                          }
                          setSelectedOptions(newSelectedOptions);
                        }
                      }}
                    >
                      {opt}
                    </S.Option>
                  );
                })}
              </S.OptionsContent>
              <S.Lines visible={isElementsVisible}>
                <S.LineShouldersPassive>
                  <LineShoulders
                    fill={selectedOptions.includes(WHICH_AREAS_IMPROVE_V2.SHOULDERS) ? "#C5169D" : "#30396F"}
                    selected={selectedOptions.includes(WHICH_AREAS_IMPROVE_V2.SHOULDERS)}
                  />
                </S.LineShouldersPassive>
                <S.LineArmsPassive>
                  <LineArms fill={selectedOptions.includes(WHICH_AREAS_IMPROVE_V2.ARMS) ? "#C5169D" : "#30396F"} selected={selectedOptions.includes(WHICH_AREAS_IMPROVE_V2.ARMS)} />
                </S.LineArmsPassive>
                <S.LineBellyPassive>
                  <LineBelly
                    fill={selectedOptions.includes(WHICH_AREAS_IMPROVE_V2.BELLY) ? "#C5169D" : "#30396F"}
                    selected={selectedOptions.includes(WHICH_AREAS_IMPROVE_V2.BELLY)}
                  />
                </S.LineBellyPassive>
                <S.LineButtPassive>
                  <LineButt fill={selectedOptions.includes(WHICH_AREAS_IMPROVE_V2.BUTT) ? "#C5169D" : "#30396F"} selected={selectedOptions.includes(WHICH_AREAS_IMPROVE_V2.BUTT)} />
                </S.LineButtPassive>
                <S.LineLegsPassive>
                  <LineLegs fill={selectedOptions.includes(WHICH_AREAS_IMPROVE_V2.LEGS) ? "#C5169D" : "#30396F"} selected={selectedOptions.includes(WHICH_AREAS_IMPROVE_V2.LEGS)} />
                </S.LineLegsPassive>
              </S.Lines>
            </>

            <S.Image
              initial={{ opacity: 0 }}
              animate={{
                opacity: imageLoaded ? 1 : 0,
              }}
              transition={{ opacity: { delay: 0, duration: 0.4 } }}
            >
              <LazyLoadImage img={img} onLoad={(loaded) => setImageLoaded(loaded)} alt="" />
            </S.Image>
          </S.BodyArea>
        </S.Content>
        <CustomButton width={height / 2} text="Continue" customDisabled={selectedOptions.length === 0} onClick={onClick} />
      </S.PageContent>
    </S.Main>
  );
};

export default WhichAreasWouldYouLikeToImprove;
