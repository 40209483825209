import zumba from "../../assets/img/V2/danceStyle/zumba.webp";
import latin from "../../assets/img/V2/danceStyle/latin.webp";
import aerobics from "../../assets/img/V2/danceStyle/aerobics.webp";
import hipHop from "../../assets/img/V2/danceStyle/hip-hop.webp";
import belly from "../../assets/img/V2/danceStyle/belly.webp";
import { DANCE_STYLE_V2 } from "../enum";

const danceStyleOptionsV2 = [
  {
    id: 1,
    label: DANCE_STYLE_V2.ZUMBA,
    img: zumba,
  },
  {
    id: 2,
    label: DANCE_STYLE_V2.LATIN,
    img: latin,
  },
  {
    id: 3,
    label: DANCE_STYLE_V2.AEROBICS,
    img: aerobics,
  },
  {
    id: 4,
    label: DANCE_STYLE_V2.HIPHOP,
    img: hipHop,
  },
  {
    id: 5,
    label: DANCE_STYLE_V2.BELLY,
    img: belly,
  },
];

export default danceStyleOptionsV2;
