import { CustomOption } from "../../model";
import one_two from "../../assets/img/V2/howOftenWork/1_2.webp";
import three_four from "../../assets/img/V2/howOftenWork/3_4.webp";
import five_six from "../../assets/img/V2/howOftenWork/5_6.webp";

const howOftenWorkOptions: CustomOption[] = [
  {
    id: 1,
    value: 0,
    payload: {
      title: "1-2 time/week",
      subTitle: "Start slowly with 1-2 days per week.",
      img: one_two,
    },
  },
  {
    id: 2,
    value: 50,
    payload: {
      title: "3-4 time/week",
      subTitle: "Aim for 3-4 days weekly.",
      img: three_four,
    },
  },
  {
    id: 3,
    value: 100,
    payload: {
      title: "5-6 time/week",
      subTitle: "Push yourself with 5-6 days a week.",
      img: five_six,
    },
  },
];

export default howOftenWorkOptions;
