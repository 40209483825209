const enum WHICH_AREAS_IMPROVE_V2 {
  FULL_BODY = "Full Body",
  SHOULDERS = "Shoulders",
  ARMS = "Arms",
  BELLY = "Belly",
  BUTT = "Butt",
  LEGS = "Legs",
}

export default WHICH_AREAS_IMPROVE_V2;
