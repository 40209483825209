import { CustomOption } from "../../model";
import targetBodyShapeOption1 from "../../assets/img/targetBodyShape/targetBodyShapeOption1.webp";
import targetBodyShapeOption2 from "../../assets/img/targetBodyShape/targetBodyShapeOption2.webp";
import targetBodyShapeOption3 from "../../assets/img/targetBodyShape/targetBodyShapeOption3.webp";

const targetBodyShapeOptions: CustomOption[] = [
  {
    id: 1,
    value: 0,
    payload: {
      title: "Medium",
      subTitle: "",
      img: targetBodyShapeOption1,
    },
  },
  {
    id: 2,
    value: 1,
    payload: {
      title: "",
      subTitle: "",
      img: targetBodyShapeOption2,
    },
  },
  {
    id: 3,
    value: 2,
    payload: {
      title: "Curvy",
      subTitle: "",
      img: targetBodyShapeOption3,
    },
  },
];

export default targetBodyShapeOptions;
