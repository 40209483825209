import { Cohort } from "../utils/constants/rootConstants";
import { PAGE_ID } from "../utils/enum";
import Welcome from "../pages/Cohort1/Welcome/Welcome";
import Part1Goal from "../pages/Cohort1/Part1Goal/Part1Goal";
import Motivates from "../pages/Cohort1/Motivates/Motivates";
import CurrentBodyShape from "../pages/Cohort1/CurrentBodyShape/CurrentBodyShape";
import TargetBodyShape from "../pages/Cohort1/TargetBodyShape/TargetBodyShape";
import WhichAreasImprove from "../pages/Cohort1/WhichAreasImprove/WhichAreasImprove";
import Height from "../pages/Cohort1/Height/Height";
import Part2Goal from "../pages/Cohort1/Part2Goal/Part2Goal";
import CurrentWeight from "../pages/Cohort1/CurrentWeight/CurrentWeight";
import GoalWeight from "../pages/Cohort1/GoalWeight/GoalWeight";
import Part3Goal from "../pages/Cohort1/Part3Goal/Part3Goal";
import AgeRange from "../pages/Cohort1/AgeRange/AgeRange";
import FavoriteDanceStyle from "../pages/Cohort1/FavoriteDanceStyle/FavoriteDanceStyle";
import PreferredMusicStyle from "../pages/Cohort1/PreferredMusicStyle/PreferredMusicStyle";
import Part4Goal from "../pages/Cohort1/Part4Goal/Part4Goal";
import ActivityLevel from "../pages/Cohort1/ActivityLevel/ActivityLevel";
import DanceLevel from "../pages/Cohort1/DanceLevel/DanceLevel";
import PhysicalActivity from "../pages/Cohort1/PhysicalActivity/PhysicalActivity";
import Part2Final from "../pages/Cohort1/Part2Final/Part2Final";
import WantToImproveYourWellbeingByDancing from "../pages/Cohort1/WantToImproveYourWellbeingByDancing/WantToImproveYourWellbeingByDancing";
import WantToPowerUpYourCharm from "../pages/Cohort1/WantToPowerUpYourCharm/WantToPowerUpYourCharm";
import WantToDanceYourWayToAFitterYou from "../pages/Cohort1/WantToDanceYourWayToAFitterYou/WantToDanceYourWayToAFitterYou";
import WhatOurMembersAreSaying from "../pages/Cohort1/WhatOurMembersAreSaying/WhatOurMembersAreSaying";
import PreparingYourPlan from "../pages/Cohort1/PreparingYourPlan/PreparingYourPlan";
import AnalysisResult from "../pages/Cohort1/AnalysisResult/AnalysisResult";
import SignUp from "../pages/Cohort1/SignUp/SignUp";
// import SignIn from "../pages/Cohort1/SignIn/SignIn";
import AccountIsReady from "../pages/Cohort1/AccountIsReady/AccountIsReady";
import Payment from "../pages/Cohort1/Payment/Payment";

// V2
import Landing from "../pages/Cohort2/Landing/Landing";
import PersonalFitnessCoach from "../pages/Cohort2/PersonalFitnessCoach/PersonalFitnessCoach";
import Part1Goals from "../pages/Cohort2/Part1Goals/Part1Goals";
import MainGoal from "../pages/Cohort2/MainGoal/MainGoal";
import ChooseYourCurrentBodyShape from "../pages/Cohort2/ChooseYourCurrentBodyShape/ChooseYourCurrentBodyShape";
import ChooseYourTargetBodyShape from "../pages/Cohort2/ChooseYourTargetBodyShape/ChooseYourTargetBodyShape";
import WhichAreasWouldYouLikeToImprove from "../pages/Cohort2/WhichAreasWouldYouLikeToImprove/WhichAreasWouldYouLikeToImprove";
import Part2BodyData from "../pages/Cohort2/Part2BodyData/Part2BodyData";
import HowOldAreYou from "../pages/Cohort2/HowOldAreYou/HowOldAreYou";
import WhatIsYourHeight from "../pages/Cohort2/WhatIsYourHeight/WhatIsYourHeight";
import WhatIsYourCurrentWeight from "../pages/Cohort2/WhatIsYourCurrentWeight/WhatIsYourCurrentWeight";
import WhatIsYourTargetWeight from "../pages/Cohort2/WhatIsYourTargetWeight/WhatIsYourTargetWeight";
import YouWillAchieveAHealthierPhysique from "../pages/Cohort2/YouWillAchieveAHealthierPhysique/YouWillAchieveAHealthierPhysique";
import Part3FitnessAnalysis from "../pages/Cohort2/Part3FitnessAnalysis/Part3FitnessAnalysis";
import WhatIsYourActivityLevel from "../pages/Cohort2/WhatIsYourActivityLevel/WhatIsYourActivityLevel";
import WhatIsYourFitnessLevel from "../pages/Cohort2/WhatIsYourFitnessLevel/WhatIsYourFitnessLevel";
import WhichPlaceDoYouPreferForYourWorkout from "../pages/Cohort2/WhichPlaceDoYouPreferForYourWorkout/WhichPlaceDoYouPreferForYourWorkout";
import WorkoutPlace from "../pages/Cohort2/WorkoutPlace/WorkoutPlace";
import HowOftenWouldYouLikeToWorkOut from "../pages/Cohort2/HowOftenWouldYouLikeToWorkOut/HowOftenWouldYouLikeToWorkOut";
import Part4DanceAnalysis from "../pages/Cohort2/Part4DanceAnalysis/Part4DanceAnalysis";
import ChooseYourDanceLevel from "../pages/Cohort2/ChooseYourDanceLevel/ChooseYourDanceLevel";
import SelectYourFavoriteDanceStyle from "../pages/Cohort2/SelectYourFavoriteDanceStyle/SelectYourFavoriteDanceStyle";
import SelectPreferredMusicStyle from "../pages/Cohort2/SelectPreferredMusicStyle/SelectPreferredMusicStyle";
import CalorieBurningWorkout from "../pages/Cohort2/CalorieBurningWorkout/CalorieBurningWorkout";
import YourPersonalizedFitnessPlan from "../pages/Cohort2/YourPersonalizedFitnessPlan/YourPersonalizedFitnessPlan";
import AppSignUp from "../pages/Cohort2/AppSignUp/AppSignUp";
import YourAccountIsReady from "../pages/Cohort2/YourAccountIsReady/YourAccountIsReady";
import YourPlanIsReady from "../pages/Cohort2/YourPlanIsReady/YourPlanIsReady";

export const ID_TO_PAGE_MAP = {
  welcome: Welcome,
  part1Goal: Part1Goal,
  motivates: Motivates,
  currentBodyShape: CurrentBodyShape,
  targetBodyShape: TargetBodyShape,
  whichAreasImprove: WhichAreasImprove,
  part2Goal: Part2Goal,
  height: Height,
  currentWeight: CurrentWeight,
  goalWeight: GoalWeight,
  part2Final: Part2Final,
  part3Goal: Part3Goal,
  ageRange: AgeRange,
  favoriteDanceStyle: FavoriteDanceStyle,
  preferredMusicStyle: PreferredMusicStyle,
  part4Goal: Part4Goal,
  activityLevel: ActivityLevel,
  danceLevel: DanceLevel,
  physicalActivity: PhysicalActivity,
  whatOurMembersAreSaying: WhatOurMembersAreSaying,
  wantToImproveYourWellbeingByDancing: WantToImproveYourWellbeingByDancing,
  wantToPowerUpYourCharm: WantToPowerUpYourCharm,
  wantToDanceYourWayToAFitterYou: WantToDanceYourWayToAFitterYou,
  preparingYourPlan: PreparingYourPlan,
  analysisResult: AnalysisResult,
  signUp: SignUp,
  // signIn: SignIn,
  accountIsReady: AccountIsReady,
  payment: Payment,

  // V2
  landing: Landing,
  personalFitnessCoach: PersonalFitnessCoach,
  part1Goals: Part1Goals,
  mainGoal: MainGoal,
  chooseYourCurrentBodyShape: ChooseYourCurrentBodyShape,
  chooseYourTargetBodyShape: ChooseYourTargetBodyShape,
  whichAreasWouldYouLikeToImprove: WhichAreasWouldYouLikeToImprove,
  part2BodyData: Part2BodyData,
  howOldAreYou: HowOldAreYou,
  whatIsYourHeight: WhatIsYourHeight,
  whatIsYourCurrentWeight: WhatIsYourCurrentWeight,
  whatIsYourTargetWeight: WhatIsYourTargetWeight,
  youWillAchieveAHealthierPhysique: YouWillAchieveAHealthierPhysique,
  part3FitnessAnalysis: Part3FitnessAnalysis,
  whatIsYourActivityLevel: WhatIsYourActivityLevel,
  whatIsYourFitnessLevel: WhatIsYourFitnessLevel,
  whichPlaceDoYouPreferForYourWorkout: WhichPlaceDoYouPreferForYourWorkout,
  workoutPlace: WorkoutPlace,
  howOftenWouldYouLikeToWorkOut: HowOftenWouldYouLikeToWorkOut,
  part4DanceAnalysis: Part4DanceAnalysis,
  chooseYourDanceLevel: ChooseYourDanceLevel,
  selectYourFavoriteDanceStyle: SelectYourFavoriteDanceStyle,
  selectPreferredMusicStyle: SelectPreferredMusicStyle,
  calorieBurningWorkout: CalorieBurningWorkout,
  yourPersonalizedFitnessPlan: YourPersonalizedFitnessPlan,
  appSignUp: AppSignUp,
  yourAccountIsReady: YourAccountIsReady,
  yourPlanIsReady: YourPlanIsReady,
};

export const V1: { id: PAGE_ID }[] = [
  { id: PAGE_ID.WELCOME },
  { id: PAGE_ID.PART_1_GOAL },
  { id: PAGE_ID.MOTIVATES },
  { id: PAGE_ID.CURRENT_BODY_SHAPE },
  { id: PAGE_ID.TARGET_BODY_SHAPE },
  { id: PAGE_ID.WHICH_AREAS_IMPROVE },
  { id: PAGE_ID.PART_2_GOAL },
  { id: PAGE_ID.HEIGHT },
  { id: PAGE_ID.CURRENT_WEIGHT },
  { id: PAGE_ID.GOAL_WEIGHT },
  { id: PAGE_ID.PART_2_FINAL },
  { id: PAGE_ID.PART_3_GOAL },
  { id: PAGE_ID.AGE_RANGE },
  { id: PAGE_ID.FAVORITE_DANCE_STYLE },
  { id: PAGE_ID.PREFERRED_MUSIC_STYLE },
  { id: PAGE_ID.PART_4_GOAL },
  { id: PAGE_ID.ACTIVITY_LEVEL },
  { id: PAGE_ID.DANCE_LEVEL },
  { id: PAGE_ID.PHYSICAL_ACTIVITY },
  { id: PAGE_ID.WHAT_OUR_MEMBERS_ARE_SAYING },
  { id: PAGE_ID.WANT_TO_IMPROVE_YOUR_WELLBEING_BY_DANCING },
  { id: PAGE_ID.WANT_TO_POWER_UP_YOUR_CHARM },
  { id: PAGE_ID.WANT_TO_DANCE_YOUR_WAY_TO_A_FITTER_YOU },
  { id: PAGE_ID.PREPARING_YOUR_PLAN },
  { id: PAGE_ID.ANALYSIS_RESULT },
  { id: PAGE_ID.SIGN_UP },
  // { id: PAGE_ID.SIGN_IN },
  { id: PAGE_ID.ACCOUNT_IS_READY },
  { id: PAGE_ID.PAYMENT },
];

export const V2: { id: PAGE_ID }[] = [
  { id: PAGE_ID.LANDING },
  { id: PAGE_ID.PERSONAL_FITNESS_COACH },
  { id: PAGE_ID.PART_1_GOALS },
  { id: PAGE_ID.MAIN_GOAL },
  { id: PAGE_ID.CHOOSE_YOUR_CURRENT_BODY_SHAPE },
  { id: PAGE_ID.CHOOSE_YOUR_TARGET_BODY_SHAPE },
  { id: PAGE_ID.WHICH_AREAS_WOULD_YOU_LIKE_TO_IMPROVE },
  { id: PAGE_ID.PART_2_BODY_DATA },
  { id: PAGE_ID.HOW_OLD_ARE_YOU },
  { id: PAGE_ID.WHAT_IS_YOUR_HEIGHT },
  { id: PAGE_ID.WHAT_IS_YOUR_CURRENT_WEIGHT },
  { id: PAGE_ID.WHAT_IS_YOUR_TARGET_WEIGHT },
  { id: PAGE_ID.YOU_WILL_ACHIEVE_A_HEALTHIER_PHYSIQUE },
  { id: PAGE_ID.PART_3_FITNESS_ANALYSIS },
  { id: PAGE_ID.WHAT_IS_YOUR_ACTIVITY_LEVEL },
  { id: PAGE_ID.WHAT_IS_YOUR_FITNESS_LEVEL },
  { id: PAGE_ID.WHICH_PLACE_DO_YOU_PREFER_FOR_YOUR_WORKOUT },
  { id: PAGE_ID.WORKOUT_PLACE },
  { id: PAGE_ID.HOW_OFTEN_WOULD_YOU_LIKE_TO_WORKOUT },
  { id: PAGE_ID.PART_4_DANCE_ANALYSIS },
  { id: PAGE_ID.CHOOSE_YOUR_DANCE_LEVEL },
  { id: PAGE_ID.SELECT_YOUR_FAVORITE_DANCE_STYLE },
  { id: PAGE_ID.SELECT_PREFERRED_MUSIC_STYLE },
  { id: PAGE_ID.CALORIE_BURNING_WORKOUT },
  { id: PAGE_ID.YOUR_PERSONALIZED_FITNESS_PLAN },
  { id: PAGE_ID.APP_SIGN_UP },
  { id: PAGE_ID.YOUR_ACCOUNT_IS_READY },
  { id: PAGE_ID.YOUR_PLAN_IS_READY },
];

export const DYNAMIC_PAGES_MAP = {
  [Cohort.V1]: V1,
  [Cohort.V2]: V2,
};
