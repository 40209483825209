import { createRef, lazy, useState } from "react";
import { Box } from "@mui/material";
import { useAutoScrollDown, useGoBack, useNextStep, useWindowSize } from "../../../hooks";
import { CustomOption, TPage } from "../../../model";
import { targetBodyShapeOptions } from "../../../utils/constants";
import { useAnswersContext } from "../../../context";
import * as S from "./style";

const CustomHeader = lazy(() => import("../../../components/CustomHeader/CustomHeader"));
const CustomButton = lazy(() => import("../../../components/Base/CustomButton/CustomButton"));
const CustomStepper = lazy(() => import("../../../components/CustomStepper/CustomStepper"));
const CustomOptionProgressBar = lazy(() => import("../../../components/CustomOptionProgressBar/CustomOptionProgressBar"));
const ImageSlider = lazy(() => import("../../../components/ImageSlider/ImageSlider"));

const TargetBodyShape: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });
  const handleGoBack = useGoBack({ pageId, nextPagePath });
  const { height } = useWindowSize();

  const { answers } = useAnswersContext();
  const prevTargetBodyShapeId: number | undefined = Number(answers["targetBodyShape"]?.join(""));
  const prevTargetBodyShape = targetBodyShapeOptions.find((c) => c.id === prevTargetBodyShapeId);

  const [selectedOption, setSelectedOption] = useState<CustomOption>(prevTargetBodyShape || targetBodyShapeOptions[0]);

  const myRef = createRef<HTMLDivElement>();
  useAutoScrollDown(myRef);

  const onClick = () => {
    handleContinue(selectedOption?.id.toString());
  };

  return (
    <S.Main>
      <S.PageContent width={height / 2}>
        <CustomHeader part={1} header="GOAL" />
        <CustomStepper step={0} onBack={handleGoBack} />
        <S.Content>
          <S.Title>
            <Box>Choose your target</Box>
            <Box>body shape</Box>
          </S.Title>
          <S.StyledImageSliderContent>
            <ImageSlider
              options={targetBodyShapeOptions}
              onSelectedOptionChange={(selectedOption) => {
                setSelectedOption(selectedOption);
              }}
              selectedOption={selectedOption}
              height={height}
            />
          </S.StyledImageSliderContent>
          {/* <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" margin="2rem 0" gap="1rem">
          <Box fontSize="0.875rem" fontWeight="600">
            {selectedOption.payload.title}
          </Box>
        </Box> */}
          <Box ref={myRef} display="flex" justifyContent="center" alignItems="center">
            <CustomOptionProgressBar
              height={height}
              options={targetBodyShapeOptions}
              onSelectedOptionChange={(selectedOption) => {
                setSelectedOption(selectedOption);
              }}
              selectedOption={selectedOption}
            />
          </Box>
        </S.Content>
        <CustomButton width={height / 2} text="Continue" onClick={onClick} />
      </S.PageContent>
    </S.Main>
  );
};

export default TargetBodyShape;
