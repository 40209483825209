import { Box, keyframes } from "@mui/material";
import { styled } from "@mui/material";

export const Main = styled(Box)(() => ({
  width: "100%",
  height: "calc(100% - 5rem)",
  overflowX: "hidden",
  overflowY: "auto",
  WebkitOverflowScrolling: "auto",
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "@media (max-width: 768px)": {
    height: "calc(100% - 2.5rem)",
  },
}));

export const PageContent = styled(Box)(({ width }: { width: number | string }) => ({
  position: "relative",
  width: `${width ? `${width}px` : "25rem"}`,
  // width: "50%",
  height: "100%",
  "@media (max-width: 768px)": {
    width: "100%",
  },
}));

export const Content = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  marginTop: "1rem",
  "@media (max-width: 768px)": {
    marginTop: "0rem",
  },
}));

export const ContentWrapper = styled(Box)(() => ({
  width: "90%",
  margin: "0 auto",
  position: "relative",
}));

export const Title = styled(Box)(() => ({
  marginBottom: "2rem",
  textAlign: "center",
  fontWeight: "600",
  fontSize: "1.25rem",
  "@media (max-width: 768px)": {
    marginBottom: "1rem",
  },
}));

export const ImageContent = styled(Box)(({ dark, shine, selected }: { dark: number; shine: number; selected: boolean }) => ({
  position: "relative",
  cursor: "pointer",
  width: "100%",
  height: "100%",
  background: dark === 1 ? "#CDD4ED" : "#fff",
  borderRadius: "0.5rem",
  ...(!selected && {
    "&::before": {
      content: '""',
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundImage: "linear-gradient(120deg,transparent 30%,rgba(244, 246, 252, .5),transparent 90%)",
      top: 0,
      left: "-100px",
      animation: `${shineAnimation} 5s normal linear`,
    },
    ...(shine === 1 && {
      "&::after": {
        content: '""',
        position: "absolute",
        width: "100%",
        height: "100%",
        backgroundImage: "linear-gradient(120deg,transparent 30%,rgba(244, 246, 252, .5),transparent 90%)",
        top: 0,
        left: "-100px",
        animation: `${shineAnimation} 5s normal linear`,
      },
    }),
  }),
}));

export const ImageText = styled(Box)(() => ({
  position: "absolute",
  width: "100%",
  textAlign: "center",
  top: "0.5rem",
  color: "#232D49",
  fontSize: "1rem",
  fontWeight: "normal",
}));

export const Image = styled("img")(() => ({
  width: "100%",
  height: "100%",
}));

export const Column = styled(Box)(() => ({
  gap: "1rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const shineAnimation = keyframes`
0% {left: -100%}
20% {left: 100%}
100% {left: 100%}
}
`;
