import { lazy } from "react";
import { Box } from "@mui/material";
import img from "../../../assets/img/part1Goal/group.webp";
import { useNextStep, useWindowSize } from "../../../hooks";
import { TPage } from "../../../model";
import * as S from "./style";

const CustomHeader = lazy(() => import("../../../components/CustomHeader/CustomHeader"));
const CustomButton = lazy(() => import("../../../components/Base/CustomButton/CustomButton"));

const Part3Goal: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });
  const { height } = useWindowSize();

  return (
    <S.Main>
      <S.PageContent height={height}>
        <CustomHeader part={3} header="GOAL" />
        <S.Content>
          <S.ImageContent>
            <S.Image src={img} alt="" />
          </S.ImageContent>
          <S.ContentText>
            <Box padding="2rem">Knowledge of your gender, hobbies and lifestyle will help you create a personalized weight loss plan and get closer to your success.</Box>
          </S.ContentText>
        </S.Content>
        <CustomButton width={height / 2} text="Continue" onClick={() => handleContinue()} />
      </S.PageContent>
    </S.Main>
  );
};

export default Part3Goal;
