import { lazy } from "react";
import { Box } from "@mui/material";
import img from "../../../assets/img/part1Goal/group.webp";
import { useNextStep, useWindowSize } from "../../../hooks";
import { TPage } from "../../../model";
import * as S from "./style";

const CustomHeader = lazy(() => import("../../../components/CustomHeader/CustomHeader"));
const CustomButton = lazy(() => import("../../../components/Base/CustomButton/CustomButton"));
const Tip = lazy(() => import("../../../components/Base/Tip/Tip"));

const Part1Goal: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const { height } = useWindowSize();
  const handleContinue = useNextStep({ pageId, nextPagePath });

  return (
    <S.Main>
      <S.PageContent height={height}>
        <CustomHeader part={1} header="GOAL" />
        <S.Content>
          <S.HeaderText>
            <Box>Hi there! I'm your personal</Box>
            <Box>dance coach</Box>
          </S.HeaderText>
          <S.ImageContent>
            <S.Image src={img} alt="" />
          </S.ImageContent>
          <S.ContentText>
            <Box>I'll ask you some questions to create your</Box>
            <Box>personalized dance plan.</Box>
          </S.ContentText>
        </S.Content>
        <Tip width={height / 2}>
          <>Healthy body structure is the first step in creating a healthy lifestyle. Knowing your own body type helps determine what goal you want to achieve.</>
        </Tip>
        <CustomButton width={height / 2} text="Continue" onClick={() => handleContinue()} />
      </S.PageContent>
    </S.Main>
  );
};

export default Part1Goal;
