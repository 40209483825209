import React from "react";
import Svg from "../../../../components/Base/Svg/Svg";

type LineBellyProps = {
  fill?: string;
  color?: string;
  size?: string;
};

const LineBelly: React.FC<LineBellyProps> = ({ fill, color, size = "20px" }) => (
  <Svg fill="none" color={color} size={size} path="" width="12rem" height="4rem" viewBox="0 0 194 54">
    <path d="M1 53H149.965L189.03 5.73987" stroke={fill} strokeWidth="2" strokeLinecap="round" />
    <circle cx="189" cy="5" r="3.75" fill={fill} stroke="white" strokeWidth="1.5" />
  </Svg>
);

export default LineBelly;
