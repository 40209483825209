import { useEffect, useRef, useState, lazy } from "react";
import { ArrowBackIos } from "@mui/icons-material";
import { currentBodyShapeOptionsV2, targetBodyShapeOptionsV2 } from "../../../utils/constants";
import { useAnswersContext } from "../../../context";
import { useGoBack, useNextStep, useWindowSize } from "../../../hooks";
import { TPage } from "../../../model";
import { WEIGHT_TYPE } from "../../../utils/enum";
import leftArrow from "../../../assets/img/V2/healthierPhysique/leftArrow.svg";
import rightArrow from "../../../assets/img/V2/healthierPhysique//rightArrow.svg";
import downArrow from "../../../assets/img/V2/healthierPhysique/downArrow.svg";
import mask from "../../../assets/img/V2/healthierPhysique/mask.svg";
import { FireworksOptions, FireworksHandlers } from "@fireworks-js/react";
import { calculateTargetWeight, determineTargetBodyShape } from "../../../utils/helpers";
import * as S from "./style";

const CustomButton = lazy(() => import("../../../components/Base/CustomButtonV2/CustomButton"));
const CustomStepProgressBar = lazy(() => import("../../../components/CustomStepProgressBar/CustomStepProgressBar"));

const options: FireworksOptions = {
  hue: {
    min: 0,
    max: 345,
  },
  delay: {
    min: 30,
    max: 45,
  },
  rocketsPoint: {
    min: 30,
    max: 50,
  },
  acceleration: 1.2,
  friction: 0.96,
  gravity: 1,
  particles: 90,
  traceSpeed: 3,
  explosion: 6,
  autoresize: true,
  brightness: {
    min: 50,
    max: 80,
  },
  decay: {
    min: 0.015,
    max: 0.03,
  },
};

const YouWillAchieveAHealthierPhysique: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });
  const handleGoBack = useGoBack({ pageId, nextPagePath });

  const [currentImageLoaded, setCurrentImageLoaded] = useState(false);
  const [targetImageLoaded, setTargetImageLoaded] = useState(false);

  const ref = useRef<FireworksHandlers>(null);

  const { height } = useWindowSize();

  const { answers } = useAnswersContext();

  useEffect(() => {
    if (!ref.current) return;
    if (ref.current.isRunning) {
      ref.current.stop();
    }

    const timeout = setTimeout(() => {
      if (!ref.current) return;
      ref.current.start();
    }, 4000);

    return () => clearTimeout(timeout);
  }, []);

  // Selected Current Body Shape
  const selectedCurrentBodyShapeTitle: string | undefined = answers["chooseYourCurrentBodyShape"]?.join("");
  const selectedCurrentBodyShape = currentBodyShapeOptionsV2.find((c) => c.payload.title === selectedCurrentBodyShapeTitle);
  const selectedCurrentBodyShapeImg = selectedCurrentBodyShape?.payload.img || currentBodyShapeOptionsV2[currentBodyShapeOptionsV2.length - 1].payload.img;

  // Selected Current Weight
  const selectedCurrentWeight = answers["whatIsYourCurrentWeight"]?.join("").split(" ");
  const selectedCurrentWeightValue: number | undefined = selectedCurrentWeight ? Number(selectedCurrentWeight[0]) : undefined;
  const selectedCurrentWeightType: WEIGHT_TYPE | undefined = selectedCurrentWeight?.[1] as WEIGHT_TYPE;

  // Selected Target Body Shape
  const selectedTargetBodyShapeTitle: string | undefined = answers["chooseYourTargetBodyShape"]?.join("");
  const selectedTargetBodyShape = targetBodyShapeOptionsV2.find((c) => c.payload.title === selectedTargetBodyShapeTitle);
  // const selectedTargetBodyShapeImg = selectedTargetBodyShape?.payload.img || targetBodyShapeOptionsV2[0].payload.img;

  // Selected Target Weight
  const selectedTargetWeight = answers["whatIsYourTargetWeight"]?.join("").split(" ");
  const selectedTargetWeightValue: number | undefined = selectedTargetWeight ? Number(selectedTargetWeight[0]) : undefined;
  const selectedTargetWeightType: WEIGHT_TYPE | undefined = selectedTargetWeight?.[1] as WEIGHT_TYPE;

  const calculatedTargetWeight = calculateTargetWeight(selectedCurrentWeightType, selectedTargetWeightValue, selectedTargetWeightType);

  const determinedTargetBodyShape = determineTargetBodyShape(
    selectedCurrentBodyShape?.payload.title,
    selectedTargetBodyShape?.payload.title,
    selectedCurrentWeightValue,
    calculatedTargetWeight
  );

  const onClick = () => {
    handleContinue();
  };

  return (
    <S.StyledFireworks ref={ref} options={options} heightval={height}>
      <S.Main>
        <S.PageContent height={height}>
          <S.Content>
            <S.HeaderContent>
              <S.StyledButton onClick={handleGoBack} disableRipple>
                <ArrowBackIos />
              </S.StyledButton>
              <S.PartContent>
                <CustomStepProgressBar part={2} step={9} />
              </S.PartContent>
            </S.HeaderContent>
            <S.WeightChange>
              <S.ImageContent>
                <S.Current
                  style={currentImageLoaded && targetImageLoaded ? { minHeight: "unset" } : { minHeight: "15rem" }}
                  initial={{ translateX: "60%", rotate: "0.5deg", scale: 1.2, zIndex: 2 }}
                  animate={
                    currentImageLoaded && targetImageLoaded
                      ? { translateX: "5%", rotate: "-10deg", scale: 0.9, zIndex: 1 }
                      : { translateX: "60%", rotate: "0.5deg", scale: 1.2, zIndex: 2 }
                  }
                  transition={currentImageLoaded && targetImageLoaded ? { duration: 2, delay: 1 } : { delay: 1000 }}
                >
                  <S.CurrentBodyShape src={selectedCurrentBodyShapeImg} heightval={height} alt="" onLoad={() => setCurrentImageLoaded(true)} />
                  {currentImageLoaded && targetImageLoaded && selectedCurrentWeightValue && (
                    <S.CurrentWeight>
                      {selectedCurrentWeightValue} {selectedCurrentWeightType}
                    </S.CurrentWeight>
                  )}
                </S.Current>

                <S.Target
                  initial={{ translateX: "-50%", scale: 0.2, zIndex: 0 }}
                  animate={currentImageLoaded && targetImageLoaded ? { translateX: "5%", rotate: "10deg", scale: 1, zIndex: 1 } : { translateX: "-50%", scale: 0.2, zIndex: 0 }}
                  transition={currentImageLoaded && targetImageLoaded ? { duration: 2, delay: 1 } : { delay: 1000 }}
                >
                  <S.TargetBodyShape src={determinedTargetBodyShape} heightval={height} alt="" onLoad={() => setTargetImageLoaded(true)} />

                  {currentImageLoaded && targetImageLoaded && selectedTargetWeightValue && (
                    <S.TargetWeight>
                      {calculatedTargetWeight} {selectedCurrentWeightType}
                      <S.DownArrow src={downArrow} alt="" />
                    </S.TargetWeight>
                  )}

                  <S.LeftArrow src={leftArrow} alt="" />
                  <S.RightArrow src={rightArrow} alt="" />
                </S.Target>
              </S.ImageContent>
              <S.Arrow
                initial={{ translateX: "-30%", opacity: 0 }}
                animate={{ translateX: "5%", rotate: "10deg", opacity: 1 }}
                transition={{ duration: 2, delay: 3 }}
                src={mask}
                alt=""
              />
            </S.WeightChange>
            <S.Title>
              You will achieve a healthier <S.ColoredTextBox>body!</S.ColoredTextBox>
            </S.Title>
            <S.SubTitle>
              <S.ColoredText>%93</S.ColoredText> of members achieved noticeable physique changes by using <S.ColoredText>Fitness By Dancing</S.ColoredText>
            </S.SubTitle>
          </S.Content>
        </S.PageContent>
        <CustomButton width={height / 2} text="Continue" onClick={onClick} />
      </S.Main>
    </S.StyledFireworks>
  );
};

export default YouWillAchieveAHealthierPhysique;
