import { Reducer } from "react";
import { Auth, SET_AUTH, SetAuthAction } from "./types";
import { storage } from "../../utils";

const email = storage.get("email");

export const initialAuthState: Auth = { isLoggedIn: email ? true : false, user: { email: email || "" } };

export const authReducer: Reducer<Auth, SetAuthAction> = (state: Auth, action: SetAuthAction): Auth => {
  switch (action.type) {
    case SET_AUTH:
      storage.set("email", action.payload.user.email);
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
