import { Box, Divider, Modal, styled } from "@mui/material";

export const StyledModal = styled(Modal)(() => ({
  // position: "absolute",
  width: "100%",
  top: "5rem",
  border: "none !important",
  left: "0",
  height: "100%",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  outline: 0,
  bottom: "5rem",
  fontFamily: "Space Grotesk",
  "@media (max-width: 768px)": {
    top: "2.5rem",
  },
}));

export const Container = styled(Box)(() => ({
  // position: "absolute",
  // top: "20%",
  // left: "50%",
  // transform: "translate(-50%, -20%)",
  // zIndex: 10,
  position: "relative",
  width: "100%",
  height: "100%",
  overflowY: "auto",
  overflowX: "hidden",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  // marginTop: "7rem",
  // paddingTop: "4rem",
  // marginBottom: "1rem",

  "&::-webkit-scrollbar": {
    display: "none",
    width: "0 !important",
  },

  "&::-webkit-scrollbar-thumb": {
    background: "#232D49 !important",
  },

  // marginTop: "-6rem",
  // paddingTop: "5rem",
  // position: "relative",
  // zIndex: 10,
  // width: "100%",
  // height: "100%",
  // overflowY: "auto",
  // overflowX: "hidden",
  // display: "flex",
  // flexDirection: "column",
  // alignItems: "center",
  "@media (max-width: 768px)": {
    width: "calc(100% - 2rem)",
  },
}));

export const Content = styled(Box)(({ height }: { height: number }) => ({
  width: `${height / 2}px`,
  height: "200vh",
  background: "#232D49",
  padding: "1rem",
  marginBottom: "7rem",
  borderRadius: "0.75rem",
  "@media (max-width: 768px)": {
    width: "calc(100% - 2rem)",
  },
}));

export const Title = styled(Box)(() => ({
  fontSize: "1.5rem",
  fontWeight: "600",
  marginBottom: "1rem",
  background: "#333D5A",
  padding: "2rem 1rem",
  color: "#fff",
  borderRadius: "0.75rem",
}));

export const Price = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  background: "#333D5A",
  borderRadius: "0.75rem",
  padding: "1rem",
  marginBottom: "1rem",
}));

export const Hr = styled(Divider)(() => ({
  borderColor: "#414C6D",
  margin: "0.5rem 0",
  borderWidth: "0.0625rem",
}));

export const Total = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  gap: "0.5rem",
}));

export const Bottom = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  background: "#333D5A",
  borderRadius: "0.75rem",
}));

export const BottomTitle = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "0.5rem",
  padding: "1rem 1rem 0 1rem",
  color: "#fff",
}));

export const BankCards = styled("img")(() => ({
  width: "80%",
  margin: "1rem auto",
}));

export const BottomDesc = styled(Box)(() => ({
  color: "#FFFFFFB2",
  textAlign: "center",
  fontWeight: "400",
  fontSize: "0.875rem",
  padding: "0 0.5rem 1rem 0.5rem",
}));
