import { Box, Button,styled } from "@mui/material";

export const Main = styled(Box)(() => ({
  width: "100%",
  height: "calc(100% - 1rem)",
  overflowY: "auto",
  overflowX: "hidden",
  position: "relative",
  WebkitOverflowScrolling: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const Background = styled(Box)(() => ({
  position: "fixed",
  top: "0",
  left: "0",
  height: "100vh",
  width: "100%",
  background: "#DDDDDD",
  zIndex: -1,
}));

export const Overlay = styled(Box)(() => ({
  background: "rgba(0, 0, 0, 0.3)",
  position: "fixed",
  top: "0",
  right: "0",
  bottom: "0",
  left: "0",
  zIndex: 10,
}));

export const PageContent = styled(Box)(({ height }: { height: number }) => ({
  position: "relative",
  width: `${height / 2}px`,
  // width: "50%",
  height: "100%",
  "@media (max-width: 768px)": {
    width: "100%",
  },
}));

export const Content = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "1rem",
  // marginTop: "3rem",
  "@media (max-width: 768px)": {
    // width: "90%",
    width: "23rem",
    margin: "0 auto",
  },
}));

export const Header = styled(Box)(() => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  width: "100%",
  marginTop: "1.5rem",
  marginBottom: "1.5rem",
  "@media (max-width: 768px)": {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
}));

export const StyledButton = styled(Button)(() => ({
  marginTop: "0.25rem !important",
  minWidth: "1rem",
  padding: "0",
  margin: "0",
  textTransform: "unset",
  color: "#8E98B4",
  fontSize: "1rem",
  position: "absolute",
  left: "0.5rem",
  "&:hover": {
    backgroundColor: "unset !important",
  },
}));

export const HeaderTitle = styled(Box)(() => ({
  textAlign: "center",
  fontWeight: "600",
  fontSize: "1.25rem",
}));

export const Price = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
}));

export const TimeRange = styled(Box)(() => ({
  color: "rgba(210, 206, 248, 0.7)",
  fontSize: "0.75rem",
}));

export const PriceText = styled(Box)(() => ({
  fontSize: "0.875rem",
}));
