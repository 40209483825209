const enum MUSIC_STYLE {
  ELECTRONIC = "Electronic",
  POP = "Pop",
  LATIN = "Latin",
  COUNTRY = "Country",
  KPOP = "K-pop",
  NON_PREFERENCE = "Non preference",
}

export default MUSIC_STYLE;
