import { Box, Button, styled } from "@mui/material";
import { CheckCircleRounded } from "@mui/icons-material";

export const Main = styled(Box)(({ heightvalue }: { heightvalue: number }) => ({
  position: "relative",
  width: `${heightvalue / 2}px`,
  height: "100%",
  display: "flex",
  flexDirection: "column",
  marginTop: "2rem",
  overflow: "hidden",
  touchAction: "none",
  fontFamily: "Space Grotesk",
  userSelect: "none",
  "@media (max-width: 768px)": {
    width: "90%",
    margin: "0 auto",
  },
}));

export const Container = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  background: "linear-gradient(180deg, #C5169D 24.5%, #1D1630 100%)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: "1rem",
  fontFamily: "Space Grotesk",
  backgroundColor: "rgba(35,45,73,1)",
  "&::before": {
    content: '""',
    position: "absolute",
    width: "calc(100% - 2px)",
    height: "calc(100% - 2px)",
    top: 0,
    left: 0,
    zIndex: 1,
    border: "1px solid #FFFFFF1A",
    borderRadius: "1rem",
  },
}));

export const C = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "7rem",
  position: "relative",
}));

export const CheckIcon = styled(CheckCircleRounded)(() => ({
  zIndex: "1",
  color: "#E5FA40",
  width: "5rem",
  height: "5rem",
}));

export const CustomButton = styled(Button)(() => ({
  width: "90%",
  borderRadius: "1.5rem",
  height: "3rem",
  background: "#E5FA40",
  textTransform: "unset",
  margin: "1.5rem 0",
  fontFamily: "Space Grotesk",
  position: "relative",
  color: "#232D49 !important",
  fontWeight: "700",
  marginBottom: "2rem",
  marginRight: "0.5rem",
  marginLeft: "0.5rem",
  "&::before": {
    content: '""',
    position: "absolute",
    width: "calc(100% - 2px)",
    height: "calc(100% - 2px)",
    top: 0,
    left: 0,
    zIndex: 1,
    border: "1px solid #FFFFFF2A",
    borderRadius: "1.5rem",
  },

  "&:hover": {
    background: "#E5FA40 !important",
  },
}));

export const Title = styled(Box)(() => ({
  color: "#fff",
  textAlign: "center",
  fontSize: "1.75rem",
  fontWeight: "700",
  marginBottom: "1rem",
}));

export const SubTitle = styled(Box)(() => ({
  color: "#FFFFFFB2",
  marginBottom: "1rem",
  textAlign: "center",
  fontSize: "0.875rem",
  fontWeight: "lighter",
}));
