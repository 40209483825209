import { useCallback, useEffect, useState } from "react";
import { Stripe } from "../model";
import { GetPrices } from "../model/Stripe";
import { Cohort } from "../utils/constants/rootConstants";
import stripeService from "../services/stripe.service";
import { captureException } from "../services/sentry.service";
import { useAlertContext } from "../context";

const usePrices = (currentCohort: Cohort) => {
  const [prices, setPrices] = useState<Stripe.StripePrice[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { showAlert } = useAlertContext();

  const pricesFetch = useCallback(() => {
    setLoading(true);
    const request: GetPrices = {
      configId: window.config.CONFIG_ID,
      cohortId: currentCohort,
    };

    stripeService
      .getPrices(request)
      .then((prices) => setPrices(prices))
      .catch((err) => {
        captureException(err);
        showAlert("Prices could not be reached. Please try again later.");
      })
      .finally(() => {
        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCohort]);

  useEffect(() => {
    if (prices.length === 0) pricesFetch();
  }, [prices.length, pricesFetch]);

  return { prices, pricesFetch, loading };
};

export default usePrices;
