import { Box, Button, LinearProgress, keyframes, styled } from "@mui/material";

export const Main = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  overflowY: "auto",
  overflowX: "hidden",
  position: "relative",
  WebkitOverflowScrolling: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const PageContent = styled(Box)(({ height }: { height: number }) => ({
  position: "relative",
  width: `${height / 2}px`,
  // width: "50%",
  height: "100%",
  "@media (max-width: 768px)": {
    width: "100%",
  },
}));

export const Content = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  marginTop: "1rem",
  "@media (max-width: 768px)": {
    margin: "1rem auto 0",
    width: "calc(100% - 2rem)",
  },
}));

export const Header = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  marginTop: "1.5rem",
  marginBottom: "2.5rem",
  "@media (max-width: 768px)": {
    marginBottom: "1rem",
  },
}));

export const StyledButton = styled(Button)(() => ({
  marginTop: "0.25rem !important",
  minWidth: "1rem",
  padding: "0",
  margin: "0",
  textTransform: "unset",
  color: "#8E98B4",
  fontSize: "1rem",
  position: "absolute",
  left: "0.5rem",
  "&:hover": {
    backgroundColor: "unset !important",
  },
}));

export const Title = styled(Box)(() => ({
  textAlign: "center",
  fontWeight: "600",
  fontSize: "1.25rem",
}));

export const Progress = styled(Box)(() => ({
  marginBottom: "0.5rem",
}));

export const ProgressTitle = styled(Box)(() => ({
  fontSize: "1rem",
  fontWeight: 500,
}));

export const ProgressContent = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  gap: "1.5rem",
}));

export const ProgressBox = styled(Box)(() => ({
  width: "100%",
  display: "block",
}));

export const StyledProgress = styled(LinearProgress)(() => ({
  height: "1rem",
  backgroundColor: "rgb(255,255,255)",
  borderRadius: "0.5rem",
  "& .MuiLinearProgress-bar": {
    backgroundColor: "rgb(58,193,65)",
    background: "linear-gradient(90deg, rgba(58,193,65,1) 0%, rgba(124,239,130,1) 100%)",
  },
  "& .MuiLinearProgress-bar1Indeterminate": {
    width: "auto",
    animation: `${indeterminate1} 6s linear forwards !important`,
  },
  "& .MuiLinearProgress-bar2Indeterminate": {
    display: "none",
  },
}));

const indeterminate1 = keyframes`
0% {
  transform: translateY(0);
}
100% {
  transform: translateY(100%) !important;
}
`;

export const ProgressText = styled(Box)(() => ({
  fontSize: "1.75rem",
  fontWeight: "600",
  color: "#232D49",
}));

export const Reviews = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  "@media (max-width: 768px)": {
    width: "calc(100% - 3rem)",
    margin: "0 auto",
  },
}));

export const ReviewTitle = styled(Box)(() => ({
  fontSize: "1rem",
  fontWeight: "600",
  color: "#232D49",
  margin: "1.5rem 0",
  "@media (max-width: 768px)": {
    margin: "0 0 1rem 0",
  },
}));

export const ReviewCard = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  alignItems: "flex-start",
  border: "0.0625rem solid #ededed",
  fontSize: "0.75rem",
  padding: "1rem",
  background: "#fff",
  borderRadius: "0.5rem",
  lineHeight: "1.25rem",
  gap: "1rem",
}));

export const CardHeader = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "0.75rem",
}));

export const ProfileName = styled(Box)(() => ({
  fontSize: "0.875rem",
  fontWeight: "500",
}));

export const ReviewText = styled(Box)(() => ({
  color: "#8E98B4",
}));

export const Image = styled("img")(() => ({
  width: "50%",
  height: "auto",
}));
