import { Box, styled } from "@mui/material";
import group from "../../../../assets/img/V2/planIsReady/group.webp";
import Lottie from "lottie-react";
import { motion } from "framer-motion";

export const ColoredText = styled("span")(() => ({
  color: "#E5FA40",
  userSelect: "none",
}));

export const ContentHeader = styled(Box)(() => ({
  width: "100%",
  textAlign: "start",
  color: "#E5FA40",
  userSelect: "none",
  fontSize: "1.25rem",
  fontWeight: "600",
  marginBottom: "0.5rem",
}));

export const ContenWrapper = styled(Box)(() => ({
  width: "100%",
  // marginBottom: "1.5rem",
}));

export const ContentData = styled(Box)(() => ({
  position: "relative",
  display: "flex",
  alignItems: "start",
  width: "100%",
  gap: "1rem",
  background: "#1C255E66",
  border: "1px solid #151E56",
  borderRadius: "1rem",
  padding: "1rem 0",

  "@media (max-width: 768px)": {
    gap: "0.5rem",
  },

  "@media only screen and (min-width: 350px) and (max-width: 390px)": {
    gap: "0.125rem",
  },
}));

export const ContentDataLeft = styled(Box)(() => ({
  width: "50%",
  // padding: "1rem 0",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  gap: "1rem",
}));

export const ContentDataRight = styled(Box)(() => ({
  width: "50%",
  height: "20rem",
  position: "relative",
  // padding: "1rem 0",
}));

export const AreaToImproveImg = styled("img")(() => ({
  position: "absolute",
  bottom: "1rem",
  right: 0,
  width: "14rem",
  height: "auto",
  "@media (max-width: 768px)": {
    width: "12rem",
    bottom: "4.5rem",
  },
}));

export const BodyShapeImgContent = styled(Box)(() => ({
  position: "absolute",
  top: 0,
  right: 0,
  height: "100%",
  width: "calc(100% - 12rem)",
  "@media (max-width: 768px)": {
    width: "calc(100% - 9rem)",
  },
}));

export const BodyShapeImg = styled(Box)(() => ({
  position: "relative",
  width: "100%",
  height: "100%",
  display: "flex",
  overflow: "clip",
  // overflow: "hidden",
}));

export const CurrentBodyShapeImg = styled("img")(() => ({
  position: "absolute",
  top: 0,
  right: "2rem",
  height: "100%",
  width: "100%",
  // zIndex: 1,
  filter: "blur(3px)",
  transform: "translateY(10%) scale(0.8) rotateZ(-5deg)",
  objectFit: "contain",
  // boxShadow: "-8.25px 4.12px 11.65px 0px #00000040",
  "@media (max-width: 768px)": {
    right: "3rem",
  },
  "@media only screen and (min-width: 350px) and (max-width: 390px)": {
    right: "2rem",
  },
}));

export const TargetBodyShapeImg = styled("img")(() => ({
  position: "absolute",
  top: 0,
  right: "-1.5rem",
  // zIndex: 2,
  height: "100%",
  width: "100%",
  objectFit: "contain",
}));

export const BeforeAfter = styled(Box)(() => ({
  position: "absolute",
  bottom: 0,
  right: 0,
  width: "100%",
  // zIndex: 2,
  borderBottomRightRadius: "1rem",
  background: `url(${group})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  height: "3rem",
  color: "#fff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: "700",
  gap: "0.5rem",
}));

export const Union = styled("img")(() => ({
  width: "3rem",
  height: "auto",
}));

export const Line = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "1rem",
  width: "100%",
  // padding: "0 1rem",

  "@media (max-width: 768px)": {
    gap: "0.5rem",
  },
}));

export const Hr = styled(Box)(() => ({
  width: "calc(100% - 1rem)",
  background: "#151E56",
  height: "0.125rem",
  margin: "0 auto",
}));

export const Icon = styled(Box)(() => ({
  fontSize: "1.75rem",
  paddingLeft: "0.5rem",
  // paddingLeft: "1rem",
}));

export const TextBody = styled(Box)(() => ({
  lineHeight: "1.5rem",
}));

export const TextHeader = styled(Box)(() => ({
  color: "#ADADAD",
  fontSize: "0.8rem",
}));

export const TextContent = styled(Box)(() => ({
  color: "#ffffff",
  fontSize: "1rem",
  maxWidth: "8rem",
  // zIndex: 2,
  position: "relative",
  // textOverflow: "ellipsis",
  // whiteSpace: "nowrap",
  // overflow: "hidden",

  "@media (max-width: 768px)": {
    whiteSpace: "normal",
    overflow: "visible",
  },
}));

export const BMIContent = styled(Box)(() => ({
  background: "#1C255E66",
  border: "1px solid #151E56",
  borderRadius: "1rem",
  width: "100%",
  marginTop: "3.5rem",
  marginBottom: "2.5rem",
}));

export const BMITextContent = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "1rem",
  marginBottom: "1rem",
}));

export const BMIText = styled(Box)(() => ({
  color: "#ffffff",
  fontSize: "1rem",
  fontWeight: "500",
}));

export const Triangle = styled("span")(() => ({
  display: "flex",
  width: "0px",
  height: "0px",
  borderStyle: "solid",
  borderWidth: "0 6px 10.4px 6px",
  borderColor: "transparent transparent #E5FA40 transparent",
  transform: "rotate(0deg)",
}));

export const BMIValue = styled(Box)(() => ({
  color: "#ffffff",
  fontSize: "1.125rem",
  fontWeight: "600",
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
}));

export const BMIValueText = styled(Box)(() => ({}));

export const BMISliderContent = styled(Box)(() => ({
  padding: "1rem",
}));

export const GraphContent = styled(Box)(() => ({
  width: "100%",
  height: "25rem",
  position: "relative",
  marginTop: "1rem",
}));

export const CurrentW = styled(motion.div)(() => ({
  position: "absolute",
  color: "#fff",
  top: "7rem",
  left: "1.2rem",
  // zIndex: 1,
  fontSize: "0.75rem",
  fontWeight: 600,

  "@media only screen and (max-height: 720px)": {
    top: "8.25rem",
    left: "0.875rem",
  },

  "@media (min-width: 769px) and (max-width: 1024px)": {
    top: "5.125rem",
    left: "1.675rem",
  },

  "@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)": {
    top: "7.275rem",
    left: "1.125rem",
  },

  "@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3)": {
    top: "7.75rem",
    left: "1rem",
  },

  "@media only screen and (width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3)": {
    top: "7.675rem",
    left: "1rem",
  },
}));

export const TargetW = styled(motion.div)(() => ({
  position: "absolute",
  right: "2.3rem",
  top: "12.8rem",
  color: "#232D49",
  zIndex: 1,
  fontWeight: "600",
  fontSize: "1.125rem",
  pointerEvents: "none",

  "@media (min-width: 769px) and (max-width: 1024px)": {
    right: "3.25rem",
    top: "12.5rem",
  },

  "@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)": {
    right: "2rem",
    top: "12.875rem",
  },

  "@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3)": {
    right: "1.75rem",
    top: "12.85rem",
  },

  "@media only screen and (width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3)": {
    right: "1.75rem",
    top: "12.75rem",
  },

  "@media only screen and (min-width: 350px) and (max-width: 389px)": {
    top: "13rem",
    right: "1.5rem",
  },
}));

export const GraphContentHeader = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  color: "#fff",
  fontWeight: "600",
  lineHeight: "2.5rem",
  fontSize: "1.5rem",
  paddingTop: "2rem",

  // marginBottom: "1.5rem",
  // marginTop: "1.5rem",
}));

export const GraphContentHeaderSubTitle = styled(Box)(() => ({
  display: "flex",
  gap: "0.5rem",
}));

export const GraphTextBackGround = styled(Box)(() => ({
  // position: "absolute",
  color: "#E5FA40",
  width: "8rem",
  // zIndex: 2,
  borderBottomRightRadius: "1rem",
  background: `url(${group})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  height: "2.5rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledLottie = styled(Lottie)(() => ({
  // position: "absolute",
  width: "100%",
  height: "25rem !important",
  position: "relative",
  // background: `url(${graph})`,
  // backgroundRepeat: "no-repeat",
  // backgroundSize: "contain",
  svg: {
    height: "25rem !important",
    position: "absolute",
    top: "2rem",
  },
}));

export const GraphContentBodyText = styled(Box)(() => ({
  width: "calc(100% - 0.5rem)",
  color: "#fff",
  fontWeight: "600",
  lineHeight: "2.5rem",
  fontSize: "1.25rem",
  display: "flex",
  justifyContent: "flex-end",
  paddingTop: "1rem",
  // gap: "0.5rem",
}));

export const GraphContentBodySubText = styled(Box)(() => ({
  width: "calc(100% - 0.5rem)",
  color: "#fff",
  fontWeight: "600",
  lineHeight: "2.5rem",
  fontSize: "1.25rem",
  display: "flex",
  justifyContent: "flex-start",
  paddingLeft: "0.5rem",
  // zIndex: 1,
  position: "absolute",
  bottom: "5.5rem",
  zIndex: 1,
}));

export const GraphContentBodyTextBackGround = styled(Box)(() => ({
  color: "#E5FA40",
  width: "9rem",
  // zIndex: 2,
  borderBottomRightRadius: "1rem",
  background: `url(${group})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  height: "2.5rem",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  paddingLeft: "1.5rem",
}));

export const Shoulders = styled(Box)(() => ({
  position: "absolute",
  left: "0.5rem",
  top: "1rem",
  svg: {
    width: "4rem",
    height: "7rem",
  },
  // Ipad
  "@media (min-width: 769px) and (max-width: 1024px)": {
    left: "3.75rem",
    top: "1.25rem",
    svg: {
      width: "4rem",
      height: "7rem",
    },
  },
  // Iphone 11
  "@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)": {
    left: "1.5rem",
    top: "0.75rem",
    svg: {
      width: "4rem",
      height: "5rem",
    },
  },

  // Iphone 13
  "@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3)": {
    left: "1.25rem",
    top: "0.75rem",
    svg: {
      width: "4rem",
      height: "5rem",
    },
  },

  // Iphone 15 Pro
  "@media only screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3)": {
    left: "0.5rem",
    top: "3.25rem",
    svg: {
      width: "4rem",
      height: "5rem",
    },
  },
  // Iphone 15 Pro Max
  "@media only screen and (device-width: 430px) and (device-height: 932px)": {
    left: "2.25rem",
    top: "0.75rem",
    svg: {
      width: "3.5rem",
      height: "5rem",
    },
  },

  "@media only screen and (min-width: 350px) and (max-width: 389px)": {
    left: "0rem",
    top: "3rem",
    svg: {
      width: "3.5rem",
      height: "5rem",
    },
  },
}));

export const Arms = styled(Box)(() => ({
  position: "absolute",
  left: "-0.75rem",
  top: "3rem",
  svg: {
    width: "5rem",
    height: "9rem",
  },
  "@media (min-width: 769px) and (max-width: 1024px)": {
    left: "3.15rem",
    top: "4rem",
    svg: {
      width: "4rem",
      height: "7rem",
    },
  },

  "@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)": {
    left: "1rem",
    top: "2.75rem",
    svg: {
      width: "4rem",
      height: "5rem",
    },
  },

  "@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3)": {
    left: "0.25rem",
    top: "3rem",
    svg: {
      width: "4rem",
      height: "5rem",
    },
  },

  "@media only screen and (width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3)": {
    left: "0.5rem",
    top: "1.15rem",
    svg: {
      width: "4rem",
      height: "5rem",
    },
  },

  "@media only screen and (device-width: 430px) and (device-height: 932px)": {
    left: "1.75rem",
    top: "3rem",
    svg: {
      width: "3.5rem",
      height: "5rem",
    },
  },

  "@media only screen and (min-width: 350px) and (max-width: 389px)": {
    left: "0rem",
    top: "1rem",
    svg: {
      width: "3.5rem",
      height: "5rem",
    },
  },
}));

export const Belly = styled(Box)(() => ({
  position: "absolute",
  left: "3.25rem",
  top: "6.25rem",
  svg: {
    width: "6rem",
    height: "6rem",
  },
  "@media (min-width: 769px) and (max-width: 1024px)": {
    left: "6.75rem",
    top: "6.75rem",
    svg: {
      width: "6.25rem",
      height: "6rem",
    },
  },

  "@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)": {
    left: "4rem",
    top: "5.25rem",
    svg: {
      width: "6.25rem",
      height: "5rem",
    },
  },

  "@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3)": {
    left: "3rem",
    top: "5.25rem",
    svg: {
      width: "6.25rem",
      height: "5rem",
    },
  },

  "@media only screen and (width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3)": {
    left: "3.25rem",
    top: "5rem",
    svg: {
      width: "6.25rem",
      height: "5rem",
    },
  },

  "@media only screen and (device-width: 430px) and (device-height: 932px)": {
    left: "4.25rem",
    top: "5.5rem",
    svg: {
      width: "6.25rem",
      height: "4.5rem",
    },
  },

  "@media only screen and (min-width: 350px) and (max-width: 389px)": {
    left: "2.5rem",
    top: "5.5rem",
    svg: {
      width: "6.25rem",
      height: "4.5rem",
    },
  },
}));

export const Butt = styled(Box)(() => ({
  position: "absolute",
  left: "-1rem",
  top: "9.5rem",
  svg: {
    width: "8rem",
    height: "8rem",
  },
  "@media (min-width: 769px) and (max-width: 1024px)": {
    left: "2.5rem",
    top: "11rem",
    svg: {
      width: "6.25rem",
      height: "6rem",
    },
  },

  "@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)": {
    left: "0.75rem",
    top: "8.15rem",
    svg: {
      width: "6.25rem",
      height: "6rem",
    },
  },

  "@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3)": {
    left: "0rem",
    top: "8.25rem",
    svg: {
      width: "6.25rem",
      height: "6rem",
    },
  },

  "@media only screen and (width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3)": {
    left: "0rem",
    top: "8.15rem",
    svg: {
      width: "6.25rem",
      height: "6rem",
    },
  },

  "@media only screen and (device-width: 430px) and (device-height: 932px)": {
    left: "1.25rem",
    top: "7.75rem",
    svg: {
      width: "6rem",
      height: "6.5rem",
    },
  },

  "@media only screen and (min-width: 350px) and (max-width: 389px)": {
    left: "-0.65rem",
    top: "8rem",
    svg: {
      width: "6rem",
      height: "6.5rem",
    },
  },
}));

export const Legs = styled(Box)(() => ({
  position: "absolute",
  left: "-0.5rem",
  top: "12.5rem",
  svg: {
    width: "6rem",
    height: "8rem",
  },
  "@media (min-width: 769px) and (max-width: 1024px)": {
    left: "3rem",
    top: "12.75rem",
    svg: {
      width: "6.25rem",
      height: "8rem",
    },
  },

  "@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)": {
    left: "0.5rem",
    top: "11rem",
    svg: {
      width: "6.25rem",
      height: "6rem",
    },
  },

  "@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3)": {
    left: "0rem",
    top: "11rem",
    svg: {
      width: "6.25rem",
      height: "6rem",
    },
  },

  "@media only screen and (width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3)": {
    left: "0rem",
    top: "11rem",
    svg: {
      width: "6.25rem",
      height: "6rem",
    },
  },

  "@media only screen and (device-width: 430px) and (device-height: 932px)": {
    left: "1rem",
    top: "10.5rem",
    svg: {
      width: "6rem",
      height: "6.5rem",
    },
  },

  "@media only screen and (min-width: 350px) and (max-width: 389px)": {
    left: "-0.75rem",
    top: "10.5rem",
    svg: {
      width: "6rem",
      height: "6.5rem",
    },
  },
}));

export const InMonths = styled(motion.div)(() => ({
  display: "flex",
  alignItems: "center",
  padding: "0 0.5rem",
  fontWeight: "600",
  fontSize: "1.125rem",
  color: "#fff",
  position: "absolute",
  bottom: "-1rem",

  "@media (max-width: 768px)": {
    fontSize: "1rem",
  },
}));
