import { Box, Button, styled } from "@mui/material";
import backgrounImg from "../../../assets/img/analysisResult/shutterstock.webp";

export const Main = styled(Box)(({ height }: { height: number }) => ({
  position: "fixed",
  height: "calc(100% - 6rem)",
  margin: "0.5rem 0",
  background: `url(${backgrounImg})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  width: `${height / 2}px`,
  borderRadius: "0.25rem",
  display: "flex",
  justifyContent: "center",
  "@media (max-width: 768px)": {
    width: "100%",
    margin: "0",
    height: "calc(100% - 2.5rem)",
    backgroundSize: "cover",
    transform: "scale(1.01)",
  },
}));

export const Image = styled("img")(() => ({
  width: "100%",
  height: "calc(100% - 1rem)",
  margin: "0.5rem auto",
  position: "absolute",
  bottom: "0",
  top: "0",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "0.25rem",
  objectFit: "cover",
  "@media (max-width: 768px)": {
    margin: "0",
    height: "100%",
    transform: "scale(1.01)",
  },
}));

export const CheckIcon = styled("img")(({ theme }) => ({
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  margin: "0 auto",
  marginTop: "1rem",
  [theme.breakpoints.up("xl")]: {
    width: "10rem",
  },
}));

export const HeaderText = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "25%",
  color: "#fff",
  width: "100%",
  textAlign: "center",
  fontSize: "1.75rem",
  fontWeight: "800",
  marginBottom: "1rem",
}));

export const HeaderSubText = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "40%",
  color: "#E0E0E0",
  width: "100%",
  textAlign: "center",
  fontSize: "0.875rem",
  fontWeight: "400",
}));

export const SubTextHighlight = styled(Box)(() => ({
  color: "#fff",
  fontWeight: "500",
}));

export const ButtonContent = styled(Box)(() => ({
  position: "absolute",
  bottom: "1rem",
  left: "0.5rem",
  right: "0.5rem",
  width: "calc(100% - 1rem)",
  height: "3rem",
  "@media (max-width: 768px)": {
    left: "1rem",
    right: "1rem",
    width: "calc(100% - 2rem)",
  },
}));

export const PercentageText = styled(Box)(({ theme }) => ({
  color: "#ffa000",
  position: "absolute",
  left: "0",
  right: "0",
  width: "100%",
  textAlign: "center",
  fontSize: "2rem",
  fontWeight: "600",
  top: "17%",
}));

export const ContentText = styled(Box)(({ theme }) => ({
  color: "#e0e0e0",
  position: "absolute",
  top: "25%",
  left: "0",
  right: "0",
  width: "100%",
  textAlign: "center",
  fontSize: "0.875rem",
  fontWeight: "400",
}));

export const ContentImage = styled("img")(() => ({
  position: "absolute",
  width: "95%",
  margin: "0 auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  left: "0",
  right: "0",
  bottom: "20%",
}));

export const BottomText = styled(Box)(({ theme }) => ({
  color: "#e0e0e0",
  position: "absolute",
  bottom: "10%",
  left: "0",
  right: "0",
  width: "100%",
  textAlign: "center",
  fontSize: "0.75rem",
  fontWeight: "400",
}));

export const CustomButtonContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: "1rem",
  left: "0.5rem",
  right: "0.5rem",
  width: "calc(100% - 1rem)",
  height: "3rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "@media (max-width: 768px)": {
    bottom: "0.5rem",
    left: "1rem",
    right: "1rem",
    width: "calc(100% - 2rem)",
    margin: "0 auto",
  },
}));

export const CustomButton = styled(Button)(({ theme }) => ({
  width: "100%",
  // margin: "2rem 0",
  borderRadius: "0.5rem",
  height: "100%",
  background: "#7b66ff",

  "&:hover": {
    background: "#7b66ff !important",
  },
}));
