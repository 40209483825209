import { Box, Button, styled } from "@mui/material";
import LoadingButton from "../../../components/Base/LoadingButton/LoadingButton";

export const Main = styled(Box)(({ heightvalue }: { heightvalue: number }) => ({
  position: "relative",
  width: `${heightvalue / 2}px`,
  height: "100%",
  display: "flex",
  flexDirection: "column",
  marginTop: "2rem",
  overflow: "hidden",
  touchAction: "none",
  "@media (max-width: 768px)": {
    width: "90%",
    margin: "1.5rem auto 0",
  },
}));

export const Header = styled(Box)(() => ({
  background: "linear-gradient(90deg, rgba(123,102,255,1) 0%, rgba(67,68,179,1) 100%)",
  padding: "1rem 0",
  color: "#fff",
  margin: "1rem 0",
  width: "100%",
  borderRadius: "0.25rem",
}));

export const HeaderText = styled(Box)(() => ({
  fontSize: "1.5rem",
  padding: "0 1rem",
  fontWeight: "600",
  marginBottom: "0.25rem",
}));

export const HeaderSubText = styled(Box)(() => ({
  width: "100%",
  fontSize: "0.875rem",
  fontWeight: "400",
  padding: "0 1rem",
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
}));

export const Content = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  background: "#fff",
  padding: "1rem 0",
  fontSize: "0.75rem",
  fontWeight: "400",
  alignItems: "center",
  gap: "0.5rem",
  marginBottom: "1rem",
  borderRadius: "0.25rem",
}));

export const Bottom = styled(Box)(({ heightvalue }: { heightvalue: number }) => ({
  // width: "25rem",
  width: `${heightvalue / 2}px`,
  display: "flex",
  flexDirection: "column",
  fontSize: "0.75rem",
  fontWeight: "400",
  alignItems: "center",
  "@media (max-width: 768px)": {
    width: "100%",
    margin: "0 auto",
  },
}));

export const StyledButton = styled(LoadingButton)(({ disabled }: { disabled?: boolean }) => ({
  borderRadius: "0.5rem",
  height: "3rem",
  background: "#7b66ff",
  color: "#fff",
  textTransform: "unset",
  width: "100%",
  fontSize: "1rem",
  "&:hover": {
    background: "#7b66ff !important",
  },
  ...(disabled && {
    background: "#BDB3FF !important",
    color: "rgba(255,255,255, 0.8) !important",
  }),
}));

export const SignUpButton = styled(Button)(() => ({
  textTransform: "unset",
  color: "#fff",
  textDecoration: "underline",
  fontSize: "0.875rem",
  fontWeight: "500",
  padding: "0",
  minWidth: "unset",

  "&:hover": {
    textDecoration: "underline",
    background: "transparent",
  },
}));

export const StyledTextField = styled("input")(({ error }: { error: boolean }) => ({
  border: "0.0625rem solid #DCDCDC",
  background: "#fff",
  color: "#232D49",
  width: "-webkit-fill-available",
  padding: "1rem",
  borderRadius: "0.25rem",
  marginBottom: "0.5rem",
  fontSize: "1rem",
  "&:focus-visible": {
    outline: "#7b66ff auto 0.0625rem",
    background: "rgba(190, 200, 255, 0.1)",
    ...(error && {
      outline: "#F02222 auto 0.0625rem",
      background: "rgba(240, 34, 34, 0.1)",
    }),
  },
}));

export const InputLabel = styled("div")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "unset",
  color: "#232D49",
}));

export const WarningContent = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  fontSize: "0.875rem",
  gap: "0.5rem",
  color: "#F02222",
}));

export const ErrorContent = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  marginTop: "1rem",
  fontSize: "0.875rem",
  gap: "0.5rem",
  color: "#F02222",
}));

export const Form = styled("form")(() => ({
  width: "100%",
}));
