import { Box, Button, styled } from "@mui/material";

export const Main = styled(Box)(() => ({
  width: "100%",
  height: "calc(100% - 5rem)",
  overflow: "hidden",
  touchAction: "none",
  position: "relative",
  WebkitOverflowScrolling: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "Space Grotesk",
  userSelect: "none",
  "@media (max-width: 768px)": {
    height: "calc(100% - 2.5rem)",
  },
}));

export const PageContent = styled(Box)(({ height }: { height: number }) => ({
  position: "relative",
  width: `${height / 2}px`,
  height: "calc(100% - 1rem)",
  margin: "0.5rem 0",
  "@media (max-width: 768px)": {
    width: "100%",
    height: "100%",
    margin: "0",
  },
}));

export const Content = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

export const HeaderContent = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  padding: "1rem 0.5rem",
  margin: "0.5rem 0 1rem 0",
}));

export const PartContent = styled(Box)(() => ({
  position: "absolute",
  left: 0,
  right: 0,
  margin: "0 auto",
  width: "16rem",
}));

export const Title = styled(Box)(() => ({
  margin: "0.5rem 0 1rem 0",
  textAlign: "center",
  fontWeight: "600",
  fontSize: "1.25rem",
  color: "#fff",
  userSelect: "none",
}));

export const SubTitle = styled(Box)(() => ({
  textAlign: "center",
  fontSize: "0.875rem",
  color: "#B4B4B4",
  userSelect: "none",
  marginBottom: "1rem",
  "@media (max-width: 768px)": {
    marginBottom: "2rem",
  },
}));

export const StyledButton = styled(Button)(() => ({
  marginTop: "0 !important",
  minWidth: "1rem",
  padding: "0 1rem",
  margin: "0",
  textTransform: "unset",
  color: "#8E98B4",
  fontSize: "1rem",
  "&:hover": {
    backgroundColor: "unset !important",
  },
}));

export const OptionsContent = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "1rem",
  position: "relative",
  "@media (max-width: 768px)": {
    width: "90%",
    margin: "0 auto",
  },
}));

export const OptionColumn = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  textAlign: "center",
  borderRadius: "0.5rem",
  color: "#232D49",
  gap: "1rem",
}));

export const Option = styled(Box)(({ dark }: { dark: number }) => ({
  width: "100%",
  background: dark === 1 ? "#CDD4ED" : "#fff",
  padding: "1rem 0",
  textAlign: "center",
  borderRadius: "0.5rem",
  color: "#232D49",
  cursor: "pointer",
  position: "relative",
  "@media (max-width: 768px)": {
    padding: "0.75rem 0",
  },
}));

export const CustomButtonContainer = styled(Box)(() => ({
  position: "absolute",
  bottom: "1rem",
  left: "1rem",
  right: "1rem",
  width: "calc(100% - 2rem)",
  height: "3rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "@media (max-width: 768px)": {
    left: "1rem",
    right: "1rem",
    width: "calc(100% - 2rem)",
  },
}));

export const CustomButton = styled(Button)(() => ({
  width: "25rem",
  // margin: "2rem 0",
  borderRadius: "2rem",
  height: "3rem",
  background: "#E5FA40",
  color: "#232D49",
  textTransform: "none",
  fontWeight: 500,
  fontFamily: "Space Grotesk",
  "&:disabled": {
    background: "#899628 !important",
    color: "#232D49 !important",
  },
  "&:hover": {
    background: "#E5FA40 !important",
  },
}));

export const Tip = styled(Box)(({ width }: { width?: number }) => ({
  position: "fixed",
  bottom: "5rem",
  display: "flex",
  alignItems: "flex-start",
  fontSize: "0.75rem",
  padding: "1rem 1rem 1rem 0",
  background: "#1C255E",
  borderRadius: "1rem",
  lineHeight: "1.25rem",
  width: `${width ? `${width - 18}px` : "25rem"}`,
  "@media (max-width: 768px)": {
    width: "calc(100% - 3rem)",
    left: "1rem",
    right: "1rem",
  },
}));

export const Light = styled(Box)(() => ({
  fontSize: "2rem",
  margin: "0.5rem",
}));

export const InfoHeader = styled(Box)(() => ({
  fontSize: "1.125rem",
  margin: "0 0.25rem 0.5rem 0.25rem",
  color: "#E5FA40",

  "@media only screen and (min-width: 350px) and (max-width: 390px)": {
    fontSize: "1rem",
  },
}));

export const InfoContent = styled(Box)(() => ({
  fontSize: "0.75rem",
  margin: "0 0.25rem",
  color: "#fff",
}));
