import { memo } from "react";
import { useNextStep, useWindowSize } from "../../../hooks";
import { TPage } from "../../../model";
import fitnesscoach from "../../../assets/img/V2/fitnessCoach/fitnesscoach.webp";
import * as S from "./style";

const PersonalFitnessCoach: React.FC<TPage> = memo(({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });
  const { height } = useWindowSize();

  return (
    <S.Main height={height}>
      <S.PageContent height={height}>
        <S.Content>
          <S.MainInfoBox initial={{ opacity: 0, y: 500 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 2, delay: 3 }}>
            <S.MainInfo>
              Hi there! I’m your personal <S.Colored>fitness coach</S.Colored>
            </S.MainInfo>
            <S.Triangle />
          </S.MainInfoBox>

          <S.SubInfoBox initial={{ opacity: 0, y: 300 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1, delay: 4.5 }}>
            <S.SubInfo>
              I’ll ask you some questions to create your <S.Colored>personalized fitness plan.</S.Colored>
            </S.SubInfo>
            <S.Triangle />
          </S.SubInfoBox>

          <S.ImageContent initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ opacity: { delay: 1, duration: 0.9 } }}>
            <S.Image src={fitnesscoach} alt="" initial={{ scale: 1.5, y: -200 }} animate={{ scale: 1.25, y: 0 }} transition={{ duration: 1.5, delay: 2 }} />
          </S.ImageContent>
        </S.Content>
        <S.CustomButtonContainer initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: 5.5 }}>
          <S.CustomButton size="large" variant="contained" onClick={() => handleContinue()}>
            Get Started
          </S.CustomButton>
        </S.CustomButtonContainer>
        <S.TextButtonsContainer initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: 6 }}>
          <S.TextButton target="_blank" rel="noopener noreferrer" href="https://fitnessbydance.com/policy" underline="always">
            Privacy Policy
          </S.TextButton>
          <S.TextButton target="_blank" rel="noopener noreferrer" href="https://fitnessbydance.com/tos" underline="always">
            Terms of Use
          </S.TextButton>
        </S.TextButtonsContainer>
      </S.PageContent>
    </S.Main>
  );
});

export default PersonalFitnessCoach;
