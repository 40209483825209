import React from "react";
import Svg from "../../../../components/Base/Svg/Svg";

type LineFullBodyProps = {
  fill?: string;
  color?: string;
  size?: string;
};

const LineFullBody: React.FC<LineFullBodyProps> = ({ fill, color, size = "20px" }) => (
  <Svg fill="none" color={color} size={size} path="" width="12rem" height="3rem" viewBox="0 0 183 37">
    <path d="M178 32H118.796L1.5 1.5" stroke={fill} strokeWidth="2" strokeLinecap="round" />
    <circle cx="178" cy="32" r="3.75" fill={fill} stroke="white" strokeWidth="1.5" />
  </Svg>
);

export default LineFullBody;
