import { Box, Button, styled } from "@mui/material";

export const Main = styled(Box)(() => ({
  width: "100%",
  height: "calc(100% - 5rem)",
  overflow: "hidden",
  touchAction: "none",
  position: "relative",
  WebkitOverflowScrolling: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "Space Grotesk",
  userSelect: "none",
  "@media (max-width: 768px)": {
    height: "calc(100% - 2.5rem)",
  },
}));

export const PageContent = styled(Box)(({ height }: { height: number }) => ({
  position: "relative",
  width: `${height / 2}px`,
  height: "calc(100% - 1rem)",
  margin: "0.5rem 0",
  "@media (max-width: 768px)": {
    width: "100%",
    height: "100%",
    margin: "0",
  },
}));

export const Content = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  marginTop: "1rem",
  "@media (max-width: 768px)": {
    marginTop: "0rem",
  },
}));

export const HeaderContent = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  padding: "1rem 0.5rem",
  margin: "0.5rem 0 1rem 0",
}));

export const PartContent = styled(Box)(() => ({
  position: "absolute",
  left: 0,
  right: 0,
  margin: "0 auto",
  width: "16rem",
}));

export const StyledButton = styled(Button)(() => ({
  marginTop: "0 !important",
  minWidth: "1rem",
  padding: "0 1rem",
  margin: "0",
  textTransform: "unset",
  color: "#8E98B4",
  fontSize: "1rem",
  "&:hover": {
    backgroundColor: "unset !important",
  },
}));

export const Title = styled(Box)(() => ({
  margin: "0.5rem 0 2rem 0",
  textAlign: "center",
  fontWeight: "600",
  fontSize: "1.25rem",
  color: "#fff",
  "@media (max-width: 768px)": {
    marginBottom: "2rem",
  },
}));

export const ColoredText = styled("span")(() => ({
  color: "#E5FA40",
}));

export const OptionsContent = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  gap: "1.25rem",
  marginBottom: "1.25rem",
  "@media (max-width: 768px)": {
    width: "90%",
    margin: "0 auto",
    gap: "1.25rem",
    marginBottom: "1.25rem",
  },
}));

export const Option = styled(Box)(({ selected }: { selected: number }) => ({
  width: "calc(100% - 2rem)",
  height: "100%",
  display: "flex",
  alignItems: "center",
  gap: "1rem",
  padding: "1.25rem 1rem",
  background: selected === 1 ? "linear-gradient(180deg, #C5169D 24.5%, #1D1630 100%)" : "#202853",
  textAlign: "center",
  borderRadius: "0.5rem",
  color: "#fff",
  cursor: "pointer",
  position: "relative",
  outline: selected === 1 ? "2px solid #C5169D" : "none",
  outlineOffset: "4px",
  userSelect: "none",

  "@media (max-width: 768px)": {
    padding: "1rem",
  },

  "@media (max-height: 700px) and (max-width: 768px)": {
    padding: "0.75rem 1rem",
  },
}));

export const NonPreferenceOption = styled(Box)(({ selected }: { selected: number }) => ({
  width: "calc(100% - 6rem)",
  height: "100%",
  padding: "0.75rem 1rem",
  background: selected === 1 ? "linear-gradient(180deg, #C5169D 24.5%, #1D1630 100%)" : "#202853",
  textAlign: "center",
  borderRadius: "1.5rem",
  color: "#fff",
  cursor: "pointer",
  position: "relative",
  outline: selected === 1 ? "2px solid #C5169D" : "none",
  outlineOffset: "4px",
  userSelect: "none",
  margin: "0 auto",

  // "@media (max-width: 768px)": {
  //   padding: "0.75rem 0",
  // },
}));

export const OptionSelected = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const ImageSelected = styled("img")(() => ({
  position: "absolute",
  right: 0,
  height: "100%",
  borderRadius: "0.5rem",
}));

export const RadioButton = styled(Box)(() => ({
  width: "1.25rem",
  height: "1.25rem",
  border: "2px solid #475399",
  borderRadius: "50%",
}));
