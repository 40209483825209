import { styled } from "@mui/material";

export const SVG = styled("svg")(({ fill, color }: { fill?: string; color?: string }) => ({
  fill: `${fill}`,
  // width: `${widthValue}`,
  // height: `${heightValue}`,
  display: "inline-block",
  // fontSize: `${size}`,
  transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  flexShrink: "0",
  userSelect: "none",
  // backgroundColor: `${bgcolor}`,
  color: `${color}`,
}));
