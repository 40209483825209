import { Box, Button, styled } from "@mui/material";
import { motion } from "framer-motion";

export const Main = styled(Box)(() => ({
  width: "100%",
  height: "calc(100% - 5rem)",
  overflow: "hidden",
  touchAction: "none",
  position: "relative",
  WebkitOverflowScrolling: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "Space Grotesk",
  userSelect: "none",
  "@media (max-width: 768px)": {
    height: "calc(100% - 2.5rem)",
  },
}));

export const PageContent = styled(Box)(({ height }: { height: number }) => ({
  position: "relative",
  width: `${height / 2}px`,
  height: "calc(100% - 1rem)",
  margin: "0.5rem 0",
  "@media (max-width: 768px)": {
    width: "100%",
    height: "100%",
    margin: "0",
  },
}));

export const Content = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  // marginTop: "1rem",
  // "@media (max-width: 768px)": {
  //   marginTop: "0.5rem",
  // },
}));

export const HeaderContent = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  padding: "1rem 0.5rem",
  margin: "0.5rem 0 1rem 0",
}));

export const PartContent = styled(Box)(() => ({
  position: "absolute",
  left: 0,
  right: 0,
  margin: "0 auto",
  width: "16rem",
}));

export const Title = styled(Box)(() => ({
  margin: "0.5rem 0 0 0",
  textAlign: "center",
  fontWeight: "600",
  fontSize: "1.25rem",
  color: "#fff",
}));

export const StyledButton = styled(Button)(() => ({
  marginTop: "0 !important",
  minWidth: "1rem",
  padding: "0 1rem",
  margin: "0",
  textTransform: "unset",
  color: "#8E98B4",
  fontSize: "1rem",
  "&:hover": {
    backgroundColor: "unset !important",
  },
}));

export const OptionsContent = styled(Box)(({ heightval }: { heightval: number }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  // marginTop: "1rem",
  transform: `translateY(${heightval / 20}px)`,
  gap: "3rem",
  "@media (max-width: 768px)": {
    width: "90%",
    margin: "0 auto",
    gap: "3rem",
    height: `${heightval / 2.25}px`,
    flexFlow: "wrap",
    alignContent: "space-between",
  },
}));

export const Option = styled(Box)(() => ({
  width: "100%",
  borderRadius: "0.5rem",
  color: "#fff",
  cursor: "pointer",
  position: "relative",
}));

export const OptionButton = styled(motion.div)(({ selected, height }: { selected: boolean; height: number }) => ({
  width: "100%",
  background: "#202853",
  border: "1px solid #30396F",
  textAlign: "left",
  borderRadius: "1.25rem",
  color: "#fff",
  cursor: "pointer",
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  outline: selected ? "2px solid #C5169D" : "none",
  outlineOffset: "4px",
  ...(selected && {
    background: "linear-gradient(180deg, #C5169D 24.5%, #1D1630 100%)",
  }),

  "@media (max-width: 768px)": {
    height: `${height / 10}px`,
  },

  "@media only screen and (min-width: 350px) and (max-width: 390px)": {
    height: `${height / 12}px`,
  },
}));

export const Image = styled("img")(({ height }: { height: number }) => ({
  width: "10rem",
  height: "auto",
  minHeight: "5.125rem",
  borderTopRightRadius: "1.25rem",
  borderBottomRightRadius: "1.25rem",
  userSelect: "none",
  "@media (max-width: 768px)": {
    minHeight: "4rem",
    height: `${height / 10}px`,
    width: "auto",
  },
  "@media only screen and (min-width: 350px) and (max-width: 390px)": {
    height: `${height / 12}px`,
  },
}));

export const OptionButtonText = styled(Box)(() => ({
  paddingLeft: "1rem",
  userSelect: "none",
}));

export const OptionInfoBox = styled(motion.div)(({ selected, height }: { selected: boolean; height: number }) => ({
  position: "absolute",
  display: "block",
  top: "6.5rem",
  left: 0,
  right: 0,
  margin: "0 auto",
  backgroundColor: "#1C255E",
  width: "100%",
  borderRadius: "1rem",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  opacity: 0,
  pointerEvents: "none",
  // transition: "opacity 0.3s ease",

  ...(selected && {
    opacity: 1,
  }),

  "@media (max-width: 768px)": {
    top: `${height / 8}px`,
  },

  "@media only screen and (min-width: 350px) and (max-width: 390px)": {
    top: `${height / 9}px`,
  },
}));

export const Triangle = styled(Box)(() => ({
  position: "absolute",
  display: "block",
  width: "0",
  height: "0",
  borderStyle: "solid",
  borderWidth: "0.625rem",
  borderTopWidth: "0",
  borderColor: "transparent",
  top: "-0.5rem",
  left: "1.5rem",

  borderBottomColor: "#1C255E",
  "&::after": {
    position: "absolute",
    display: "block",
    width: "0",
    height: "0",
    borderStyle: "solid",
    borderWidth: "0.625rem",
    borderTopWidth: "0",
    borderColor: "transparent",

    top: "0.625rem",
    margin: "-0.5rem",
    content: '" "',
    borderBottomColor: "#1C255E",
  },
}));

export const Info = styled(Box)(({ width }: { width?: number }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  fontSize: "0.75rem",
  padding: "0.5rem 0.5rem 0.5rem 0",
  borderRadius: "0.5rem",
  lineHeight: "1.25rem",
  minHeight: "5rem",
  "@media (max-width: 768px)": {
    padding: "0.25rem 0.25rem 0.25rem 0",
    minHeight: "4.5rem",
  },
}));

export const InfoHeader = styled(Box)(() => ({
  position: "relative",
  fontSize: "0.875rem",
  color: "#fff",
}));

export const InfoContent = styled(Box)(() => ({
  position: "relative",
  fontSize: "0.75rem",
  margin: "0 0.25rem",
  color: "#B0B0B0",
}));

export const Light = styled(Box)(() => ({
  fontSize: "2rem",
  margin: "0 0.25rem",
  "@media (min-width: 768px)": {
    fontSize: "2rem",
    margin: "0 0.75rem",
  },
}));

export const CustomButtonContainer = styled(Box)(() => ({
  position: "absolute",
  bottom: "1rem",
  left: "1rem",
  right: "1rem",
  width: "calc(100% - 2rem)",
  height: "3rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "@media (max-width: 768px)": {
    left: "1rem",
    right: "1rem",
    width: "calc(100% - 2rem)",
  },
}));

export const CustomButton = styled(Button)(() => ({
  width: "25rem",
  // margin: "2rem 0",
  borderRadius: "2rem",
  height: "3rem",
  background: "#E5FA40",
  color: "#232D49",
  textTransform: "none",
  fontWeight: 500,
  fontFamily: "Space Grotesk",
  "&:disabled": {
    background: "#899628 !important",
    color: "#232D49 !important",
  },
  "&:hover": {
    background: "#E5FA40 !important",
  },
}));
