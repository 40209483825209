import { useEffect, useMemo, useState, lazy } from "react";
import { useAnswersContext } from "../../../../context";
import { currentBodyShapeOptionsV2, targetBodyShapeOptionsV2 } from "../../../../utils/constants";
import { calculateTargetWeight, determineTargetBodyShape, planBodyMassIndex, weightProgress } from "../../../../utils/helpers";
import areaToImproveImg from "../../../../assets/img/V2/whichAreasImprove/img.webp";
import union from "../../../../assets/img/V2/planIsReady/union.webp";
import { Box } from "@mui/material";
import chart from "../../../../assets/lottie/yourPlanIsReady.json";
import { WHICH_AREAS_IMPROVE_V2 } from "../../../../utils/enum";
import * as S from "./style";

const BMISlider = lazy(() => import("../../../../components/V2/BMISlider/BMISlider"));
const Weeks = lazy(() => import("./Weeks/Weeks"));
const AreaArms = lazy(() => import("../../../../components/Base/Svg/Icons/AreaArms"));
const AreaShoulders = lazy(() => import("../../../../components/Base/Svg/Icons/AreaShoulders"));
const AreaBelly = lazy(() => import("../../../../components/Base/Svg/Icons/AreaBelly"));
const AreaButt = lazy(() => import("../../../../components/Base/Svg/Icons/AreaButt"));
const AreaLegs = lazy(() => import("../../../../components/Base/Svg/Icons/AreaLegs"));

const Summary = () => {
  const { answers } = useAnswersContext();

  const [currentWeightVisible, setCurrentWeightVisible] = useState(false);
  const [targetWeightVisible, setTargetWeightVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCurrentWeightVisible(true);
    }, 3750);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTargetWeightVisible(true);
    }, 9500);

    return () => clearTimeout(timeout);
  }, []);

  /*  GOALS */

  // Main Goal
  const selectedMainGoal = answers["mainGoal"]?.join("");

  // Current Body Shape
  const selectedCurrentBodyShape = answers["chooseYourCurrentBodyShape"]?.join("");

  // Target Body Shape
  const selectedTargetBodyShape = answers["chooseYourTargetBodyShape"]?.join("");

  // Area to Improve
  const selectedAreaToImprove = answers["whichAreasWouldsYouLikeToImprove"]?.join(", ");

  /* GOALS */

  /* BODY DATA */

  // Age
  const selectedAge = answers["howOldAreYou"]?.join("");

  // Heigt
  const selectedHeight = answers["whatIsYourHeight"]?.join("").split(" ");
  const heightValue = selectedHeight?.[0];
  const heightType = selectedHeight?.[1];

  // Current Weight
  const selectedCurrentWeight = answers["whatIsYourCurrentWeight"]?.join("").split(" ");
  const currentWeightValue = selectedCurrentWeight?.[0];
  const currentWeightType = selectedCurrentWeight?.[1];

  // Target Weight
  const selectedTargerWeight = answers["whatIsYourTargetWeight"]?.join("").split(" ");
  const targetWeightValue = selectedTargerWeight?.[0];
  const targetWeightType = selectedTargerWeight?.[1];

  const calculatedTargetWeight = calculateTargetWeight(currentWeightType, targetWeightValue, targetWeightType);

  const weightProgressValue = weightProgress(currentWeightValue, calculatedTargetWeight);

  // Selected Current Body Shape Image
  const selectedCurrentBodyShapeTitle: string | undefined = answers["chooseYourCurrentBodyShape"]?.join("");
  const selectedCurrentBodyShapeObj = currentBodyShapeOptionsV2.find((c) => c.payload.title === selectedCurrentBodyShapeTitle);
  const selectedCurrentBodyShapeImg = selectedCurrentBodyShapeObj?.payload.img || currentBodyShapeOptionsV2[currentBodyShapeOptionsV2.length - 1].payload.img;

  // Selected Target Body Shape Image
  const selectedTargetBodyShapeTitle: string | undefined = answers["chooseYourTargetBodyShape"]?.join("");
  const selectedTargetBodyShapeObj = targetBodyShapeOptionsV2.find((c) => c.payload.title === selectedTargetBodyShapeTitle);
  // const selectedTargetBodyShapeImg = selectedTargetBodyShapeObj?.payload.img || targetBodyShapeOptionsV2[0].payload.img;

  const determinedTargetBodyShape = determineTargetBodyShape(
    selectedCurrentBodyShapeObj?.payload.title,
    selectedTargetBodyShapeObj?.payload.title,
    currentWeightValue,
    calculatedTargetWeight
  );
  /* BODY DATA */

  const bmi = useMemo(() => {
    if (heightValue && heightType && currentWeightValue && currentWeightType) {
      const result = planBodyMassIndex({ type: heightType, value: Number(heightValue) }, { type: currentWeightType, value: Number(currentWeightValue) });
      return result;
    }
  }, [currentWeightType, currentWeightValue, heightType, heightValue]);

  return (
    <>
      <S.ContenWrapper>
        <S.ContentHeader>Body Data</S.ContentHeader>
        <S.ContentData sx={{ flexDirection: "column", overflow: "hidden" }}>
          <S.Line>
            <S.Icon>🎂</S.Icon>
            <S.TextBody>
              <S.TextHeader>Age</S.TextHeader>
              <S.TextContent>
                <S.ColoredText>{selectedAge}</S.ColoredText>
              </S.TextContent>
            </S.TextBody>
          </S.Line>
          <S.Hr />
          <S.Line>
            <S.Icon>📏</S.Icon>
            <S.TextBody>
              <S.TextHeader>Height</S.TextHeader>
              <S.TextContent>
                <S.ColoredText>
                  {heightValue} {heightType}
                </S.ColoredText>
              </S.TextContent>
            </S.TextBody>
          </S.Line>
          <S.Hr />
          <S.Line>
            <S.Icon>⏲️</S.Icon>
            <S.TextBody>
              <S.TextHeader>Current Weight</S.TextHeader>
              <S.TextContent>
                <S.ColoredText>
                  {currentWeightValue} {currentWeightType}
                </S.ColoredText>
              </S.TextContent>
            </S.TextBody>
          </S.Line>
          <S.Hr />
          <S.Line>
            <S.Icon>🏆</S.Icon>
            <S.TextBody>
              <S.TextHeader>Target Weight</S.TextHeader>
              <S.TextContent>
                <S.ColoredText>
                  {calculatedTargetWeight} {currentWeightType}
                </S.ColoredText>
              </S.TextContent>
            </S.TextBody>
          </S.Line>
          <S.BodyShapeImgContent>
            <S.BodyShapeImg>
              <S.CurrentBodyShapeImg src={selectedCurrentBodyShapeImg} alt="" />
              <S.TargetBodyShapeImg src={determinedTargetBodyShape} alt="" />
              <S.BeforeAfter>
                <Box>Before</Box>
                <S.Union src={union} alt="" />
                <Box>After</Box>
              </S.BeforeAfter>
            </S.BodyShapeImg>
          </S.BodyShapeImgContent>
        </S.ContentData>
      </S.ContenWrapper>
      <S.GraphContent>
        <S.StyledLottie animationData={chart} loop={false} height={"25rem"} autoPlay={false}>
          <S.CurrentW initial={{ opacity: 0 }} animate={{ opacity: currentWeightVisible ? 1 : 0 }} transition={{ duration: 0.5 }}>
            {currentWeightValue} {currentWeightType}
          </S.CurrentW>

          <S.TargetW initial={{ opacity: 0 }} animate={{ opacity: targetWeightVisible ? 1 : 0 }} transition={{ duration: 0.5 }}>
            {weightProgressValue.firstMonthTargetWeight} {currentWeightType}
          </S.TargetW>
          <S.GraphContentHeader>
            <Box>Are You Ready to Push</Box>
            <S.GraphContentHeaderSubTitle>
              Your <S.GraphTextBackGround> Limits?</S.GraphTextBackGround>
            </S.GraphContentHeaderSubTitle>
          </S.GraphContentHeader>

          <S.GraphContentBodySubText>
            <S.ColoredText>4 Weeks &nbsp;</S.ColoredText> to a New Self
          </S.GraphContentBodySubText>
          {weightProgressValue.totalWeightTime > 1 && (
            <S.InMonths initial={{ opacity: 0 }} animate={{ opacity: targetWeightVisible ? 1 : 0 }} transition={{ duration: 0.5 }}>
              <S.ColoredText>In {weightProgressValue.totalWeightTime} Months,</S.ColoredText>&nbsp;You will react target {calculatedTargetWeight} {currentWeightType}
            </S.InMonths>
          )}
        </S.StyledLottie>
      </S.GraphContent>
      <S.BMIContent>
        <S.BMITextContent>
          <S.BMIText>Body Mass Index (BMI)</S.BMIText>
          <S.BMIValue>
            <S.BMIValueText>
              {bmi?.key} {bmi?.value}
            </S.BMIValueText>
            <S.Triangle />
          </S.BMIValue>
        </S.BMITextContent>
        <S.BMISliderContent>
          <BMISlider selectedOption={bmi} />
        </S.BMISliderContent>
      </S.BMIContent>
      <Weeks />
      <S.ContenWrapper style={{ marginBottom: "2.5rem" }}>
        <S.ContentHeader>Goals</S.ContentHeader>
        <S.ContentData sx={{ flexDirection: "row" }}>
          <S.ContentDataLeft>
            <S.Line>
              <S.Icon>🎯</S.Icon>
              <S.TextBody>
                <S.TextHeader>Main Goal</S.TextHeader>
                <S.TextContent>
                  <S.ColoredText>{selectedMainGoal}</S.ColoredText>
                </S.TextContent>
              </S.TextBody>
            </S.Line>
            <S.Hr />
            <S.Line>
              <S.Icon>👩</S.Icon>
              <S.TextBody>
                <S.TextHeader>Current Body</S.TextHeader>
                <S.TextContent>
                  <S.ColoredText>{selectedCurrentBodyShape}</S.ColoredText>
                </S.TextContent>
              </S.TextBody>
            </S.Line>
            <S.Hr />
            <S.Line>
              <S.Icon>🏆</S.Icon>
              <S.TextBody>
                <S.TextHeader>Target Body</S.TextHeader>
                <S.TextContent>
                  <S.ColoredText>{selectedTargetBodyShape}</S.ColoredText>
                </S.TextContent>
              </S.TextBody>
            </S.Line>
            <S.Hr />
            <S.Line>
              <S.Icon>💪</S.Icon>
              <S.TextBody>
                <S.TextHeader>Area to Improve</S.TextHeader>
                <S.TextContent>
                  <S.ColoredText>{selectedAreaToImprove?.includes(WHICH_AREAS_IMPROVE_V2.FULL_BODY) ? WHICH_AREAS_IMPROVE_V2.FULL_BODY : selectedAreaToImprove}</S.ColoredText>
                </S.TextContent>
              </S.TextBody>
            </S.Line>
          </S.ContentDataLeft>
          <S.ContentDataRight>
            <S.AreaToImproveImg src={areaToImproveImg} alt="" />
            {selectedAreaToImprove?.includes(WHICH_AREAS_IMPROVE_V2.SHOULDERS) && (
              <S.Shoulders>
                <AreaShoulders width="7rem" height="9.5rem" />
              </S.Shoulders>
            )}
            {selectedAreaToImprove?.includes(WHICH_AREAS_IMPROVE_V2.ARMS) && (
              <S.Arms>
                <AreaArms />
              </S.Arms>
            )}
            {selectedAreaToImprove?.includes(WHICH_AREAS_IMPROVE_V2.BELLY) && (
              <S.Belly>
                <AreaBelly width="6rem" height="6rem" />
              </S.Belly>
            )}
            {selectedAreaToImprove?.includes(WHICH_AREAS_IMPROVE_V2.BUTT) && (
              <S.Butt>
                <AreaButt />
              </S.Butt>
            )}
            {selectedAreaToImprove?.includes(WHICH_AREAS_IMPROVE_V2.LEGS) && (
              <S.Legs>
                <AreaLegs />
              </S.Legs>
            )}
          </S.ContentDataRight>
        </S.ContentData>
      </S.ContenWrapper>
    </>
  );
};

export default Summary;
