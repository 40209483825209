import { SetAnswersAction, SET_ANSWER } from "./types";

const convertToArray = (x: string | string[] | null) => (x === null ? [""] : Array.isArray(x) ? x : [x]);

export const setAnswersAction = (answers: { pageId: string; answer: string | string[] | null }, dispatch: (action: SetAnswersAction) => void): void => {
  dispatch({
    type: SET_ANSWER,
    payload: {
      pageId: answers.pageId,
      answer: convertToArray(answers.answer),
    },
  });
};
