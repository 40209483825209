import { TPage } from "../../../model";
import { useNextStep, useWindowSize } from "../../../hooks";
import { Box } from "@mui/material";
import * as S from "./style";

const YourAccountIsReady: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });
  const { height } = useWindowSize();
  return (
    <S.Main heightvalue={height}>
      <S.Container>
        <S.C>
          <S.CheckIcon />
        </S.C>
        <S.Title>
          <Box>Your account</Box>
          <Box>is ready!</Box>
        </S.Title>
        <S.SubTitle>
          <Box>Now save your personalized plan by</Box>
          <Box>setting up an account</Box>
        </S.SubTitle>
        <S.CustomButton size="large" variant="contained" onClick={() => handleContinue()}>
          Get Personal Plan
        </S.CustomButton>
      </S.Container>
    </S.Main>
  );
};

export default YourAccountIsReady;
