import { Box, Button, styled } from "@mui/material";
import backgrounImg from "../../../assets/img/welcome/shutterstock.webp";

export const Main = styled(Box)(({ height }: { height: number }) => ({
  position: "fixed",
  height: "calc(100% - 6rem)",
  margin: "0.5rem 0",
  background: `url(${backgrounImg})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  width: `${height / 2}px`,
  borderRadius: "0.25rem",
  overflow: "hidden",
  "@media (max-width: 768px)": {
    width: "100%",
    margin: "0",
    height: "calc(100% - 2.5rem)",
    backgroundSize: "cover",
    transform: "scale(1.01)",
  },
}));

export const ButtonContent = styled(Box)((theme) => ({
  position: "absolute",
  bottom: "1rem",
  left: "0.5rem",
  right: "0.5rem",
  width: "calc(100% - 1rem)",
  height: "3rem",
  "@media (max-width: 768px)": {
    left: "1rem",
    right: "1rem",
    width: "calc(100% - 2rem)",
  },
}));

export const HeaderText = styled(Box)(() => ({
  color: "#fff",
  position: "absolute",
  left: "0",
  right: "0",
  top: "5%",
  width: "100%",
  textAlign: "center",
  fontSize: "1.25rem",
  fontWeight: "600",
}));

export const PercentageText = styled(Box)(() => ({
  color: "#ffa000",
  position: "absolute",
  left: "0",
  right: "0",
  width: "100%",
  textAlign: "center",
  fontSize: "2rem",
  fontWeight: "600",
  top: "17%",
  // marginBottom: "5%",
}));

export const ContentText = styled(Box)(() => ({
  color: "#e0e0e0",
  position: "absolute",
  top: "25%",
  left: "0",
  right: "0",
  // marginBottom: "5%",
  width: "100%",
  textAlign: "center",
  fontSize: "0.875rem",
  fontWeight: "400",
}));

export const ContentImage = styled("img")(() => ({
  position: "absolute",
  width: "20rem",
  margin: "0 auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // marginBottom: "5%",
  left: "0",
  right: "0",
  bottom: "7rem",
}));

export const BottomText = styled(Box)(() => ({
  color: "#e0e0e0",
  position: "absolute",
  bottom: "5rem",
  left: "0",
  right: "0",
  width: "100%",
  textAlign: "center",
  fontSize: "0.75rem",
  fontWeight: "400",
}));

export const CustomButtonContainer = styled(Box)(() => ({
  position: "absolute",
  bottom: "1rem",
  left: "0.5rem",
  right: "0.5rem",
  width: "calc(100% - 1rem)",
  height: "3rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "@media (max-width: 768px)": {
    left: "1rem",
    right: "1rem",
    width: "calc(100% - 2rem)",
  },
}));

export const CustomButton = styled(Button)(() => ({
  width: "25rem",
  // margin: "2rem 0",
  borderRadius: "0.5rem",
  height: "3rem",
  background: "#7b66ff",

  "&:hover": {
    background: "#7b66ff !important",
  },
}));
