import { createRef, lazy, useState } from "react";
import { Box } from "@mui/material";
import { useAutoScrollDown, useGoBack, useNextStep, useWindowSize } from "../../../hooks";
import { TPage } from "../../../model";
import { danceLevelOptions } from "../../../utils/constants";
import { useAnswersContext } from "../../../context";
import * as S from "./style";

const CustomHeader = lazy(() => import("../../../components/CustomHeader/CustomHeader"));
const CustomButton = lazy(() => import("../../../components/Base/CustomButton/CustomButton"));
const CustomStepper = lazy(() => import("../../../components/CustomStepper/CustomStepper"));
const CustomCircularProgressBar = lazy(() => import("../../../components/CustomCircularProgressBar/CustomCircularProgressBar"));
const CustomOptionProgressBar = lazy(() => import("../../../components/CustomOptionProgressBar/CustomOptionProgressBar"));

const DanceLevel: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });
  const handleGoBack = useGoBack({ pageId, nextPagePath });

  const { height } = useWindowSize();

  const { answers } = useAnswersContext();

  const prevDanceLevelTitle = answers[pageId]?.join("");
  const prevDanceLevelOption = danceLevelOptions.find((f) => f.payload.title === prevDanceLevelTitle);

  const [selectedOption, setSelectedOption] = useState(prevDanceLevelOption || danceLevelOptions[0]);

  const myRef = createRef<HTMLDivElement>();
  useAutoScrollDown(myRef);

  const onClick = () => {
    handleContinue(selectedOption.payload.title);
  };

  return (
    <S.Main>
      <S.PageContent height={height}>
        <CustomHeader part={4} header="FITNESS ANALYSIS" />
        <CustomStepper step={3} onBack={handleGoBack} />
        <S.Content>
          <S.Title>Choose your dance level</S.Title>
          <S.OptionsContent>
            <CustomCircularProgressBar
              options={danceLevelOptions}
              onSelectedOptionChange={(selectedOption) => {
                setSelectedOption(selectedOption);
              }}
              selectedOption={selectedOption}
              height={height}
            />
          </S.OptionsContent>
          <S.SelectedOption ref={myRef}>
            <Box fontSize="0.875rem" fontWeight="600">
              {selectedOption.payload.title}
            </Box>
            <Box fontSize="0.875rem">{selectedOption.payload.subTitle}</Box>
          </S.SelectedOption>
          <Box display="flex" justifyContent="center" alignItems="center">
            <CustomOptionProgressBar
              height={height}
              options={danceLevelOptions}
              onSelectedOptionChange={(selectedOption) => {
                setSelectedOption(selectedOption);
              }}
              selectedOption={selectedOption}
            />
          </Box>
        </S.Content>
        <CustomButton width={height / 2} text="Continue" onClick={onClick} />
      </S.PageContent>
    </S.Main>
  );
};

export default DanceLevel;
