import React, { ComponentProps, Context, createContext, FC, ReactNode, Reducer, useContext, useReducer } from "react";
import { Auth, IAuthContext, SetAuthAction } from "./types";
import { authReducer, initialAuthState } from "./reducer";
import { setAuthAction } from "./actions";

type Props = {
  children: ReactNode;
};

type CP = ComponentProps<FC> & Props;

export const AuthContext: Context<IAuthContext> = createContext<IAuthContext>({} as IAuthContext);

export const useAuthContext = (): IAuthContext => useContext(AuthContext);

export const AuthContextProvider: React.FC<Props> = ({ children }: CP): JSX.Element => {
  const [authContext, dispatch] = useReducer<Reducer<Auth, SetAuthAction>>(authReducer, initialAuthState);

  const setAuthContext = (auth: Auth): void => {
    setAuthAction(auth, dispatch);
  };

  return <AuthContext.Provider value={{ authContext, setAuthContext }}>{children}</AuthContext.Provider>;
};
