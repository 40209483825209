const weekMap = new Map<string, number>([
  ["day", 0.7],
  ["week", 1],
  ["month", 4.3], // 4.3 = 52/12
  ["year", 52],
]);

const getWeekCount = (recurring: string): number => {
  const weekCount = weekMap.get(recurring);
  if (weekCount) {
    return weekCount;
  }
  return 1;
};

export default getWeekCount;
