import { Button, ButtonProps, CircularProgress } from "@mui/material";

type LoadingButtonProps = ButtonProps & {
  loading: boolean;
  text: string;
  loadingColor?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
};

const LoadingButton: React.FC<LoadingButtonProps> = ({ loading, text, loadingColor = "inherit", ...props }) => {
  if (loading)
    return (
      <Button {...props}>
        <CircularProgress color={loadingColor} size={30} />
      </Button>
    );
  else return <Button {...props}>{text}</Button>;
};

export default LoadingButton;
