import { createRef, lazy, useState } from "react";
import { Box } from "@mui/material";
import { useAutoScrollDown, useGoBack, useNextStep, useWindowSize } from "../../../hooks";
import { TPage } from "../../../model";
import { preferredMusicStyleOptions } from "../../../utils/constants";
import { MUSIC_STYLE } from "../../../utils/enum";
import { useAlertContext, useAnswersContext } from "../../../context";
import { ArrowBackIos } from "@mui/icons-material";
import { AnimatePresence } from "framer-motion";
import * as S from "./style";

const CustomButton = lazy(() => import("../../../components/Base/CustomButtonV2/CustomButton"));
const CustomStepProgressBar = lazy(() => import("../../../components/CustomStepProgressBar/CustomStepProgressBar"));

const SelectPreferredMusicStyle: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });
  const handleGoBack = useGoBack({ pageId, nextPagePath });

  const { height } = useWindowSize();

  const { showAlert } = useAlertContext();

  const { answers } = useAnswersContext();
  const prevPreferredMusicStyle = answers["selectPreferredMusicStyle"] as MUSIC_STYLE[];

  const [selectedOptions, setSelectedOptions] = useState<MUSIC_STYLE[]>(prevPreferredMusicStyle || []);

  const myRef = createRef<HTMLDivElement>();
  useAutoScrollDown(myRef);

  const onClick = () => {
    if (selectedOptions.length > 0) handleContinue(selectedOptions);
    else showAlert("Select an Option", "warning", { vertical: "top", horizontal: "right" });
  };

  const onSelected = (musicStyle: MUSIC_STYLE) => {
    const newSelectedOptions = [...selectedOptions];
    if (musicStyle === MUSIC_STYLE.NON_PREFERENCE) {
      if (newSelectedOptions.includes(MUSIC_STYLE.NON_PREFERENCE)) {
        setSelectedOptions([]);
      } else {
        setSelectedOptions([MUSIC_STYLE.NON_PREFERENCE]);
      }
    } else {
      if (newSelectedOptions.includes(MUSIC_STYLE.NON_PREFERENCE)) {
        const index = newSelectedOptions.findIndex((i) => i === MUSIC_STYLE.NON_PREFERENCE);
        newSelectedOptions.splice(index, 1);
      }
      if (newSelectedOptions.includes(musicStyle)) {
        const index = newSelectedOptions.findIndex((i) => i === musicStyle);
        newSelectedOptions.splice(index, 1);
      } else {
        newSelectedOptions.push(musicStyle);
      }

      setSelectedOptions(newSelectedOptions);
    }
  };

  return (
    <S.Main>
      <S.PageContent height={height}>
        <S.HeaderContent>
          <S.StyledButton onClick={handleGoBack} disableRipple>
            <ArrowBackIos />
          </S.StyledButton>
          <S.PartContent>
            <CustomStepProgressBar part={4} step={17} />
          </S.PartContent>
        </S.HeaderContent>
        <S.Content>
          <S.Title>
            <Box>Select preferred</Box>
            <Box>
              <S.ColoredText>music</S.ColoredText> style (s)
            </Box>
          </S.Title>

          <AnimatePresence>
            <S.OptionsContent>
              {preferredMusicStyleOptions.map((musicStyle) => (
                <S.Option key={musicStyle.id} onClick={() => onSelected(musicStyle.label)} selected={selectedOptions.includes(musicStyle.label) ? 1 : 0}>
                  <S.OptionSelected>
                    {selectedOptions.includes(musicStyle.label) ? (
                      <svg width="1.5rem" height="1.5rem" viewBox="0 0 20 20" fill="none">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM14.0303 6.96967C14.3232 7.26256 14.3232 7.73744 14.0303 8.03033L9.03033 13.0303C8.73744 13.3232 8.26256 13.3232 7.96967 13.0303L5.96967 11.0303C5.67678 10.7374 5.67678 10.2626 5.96967 9.96967C6.26256 9.67678 6.73744 9.67678 7.03033 9.96967L8.5 11.4393L10.7348 9.2045L12.9697 6.96967C13.2626 6.67678 13.7374 6.67678 14.0303 6.96967Z"
                          fill="white"
                        />
                      </svg>
                    ) : (
                      <S.RadioButton />
                    )}
                  </S.OptionSelected>
                  <S.ImageSelected src={musicStyle.img} alt="" />
                  {musicStyle.label}
                </S.Option>
              ))}
            </S.OptionsContent>
          </AnimatePresence>
          <S.NonPreferenceOption onClick={() => onSelected(MUSIC_STYLE.NON_PREFERENCE)} selected={selectedOptions.includes(MUSIC_STYLE.NON_PREFERENCE) ? 1 : 0}>
            {MUSIC_STYLE.NON_PREFERENCE}
          </S.NonPreferenceOption>
        </S.Content>
        <CustomButton width={height / 2} text="Continue" customDisabled={selectedOptions.length === 0} onClick={onClick} />
      </S.PageContent>
    </S.Main>
  );
};

export default SelectPreferredMusicStyle;
