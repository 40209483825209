import React, { ReactNode, createContext, useContext, useState } from "react";
import { Alert, AlertColor } from "@mui/material";
import { IAlertContext, Origin } from "./types";
import * as S from "./style";

export const initialAlertState = {
  showAlert: () => {},
};

export const AlertContext = createContext<IAlertContext>(initialAlertState);

export const useAlertContext = (): IAlertContext => useContext(AlertContext);

export const AlertContextProvider: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [severity, setSeverity] = useState<AlertColor | undefined>("info");
  const [origin, setOrigin] = useState<Origin>({ vertical: "bottom", horizontal: "right" });

  const showAlert = (newMessage: string, newSeverity: AlertColor = "info", newOrigin: Origin = { vertical: "bottom", horizontal: "right" }) => {
    setMessage(newMessage);
    setSeverity(newSeverity);
    setOrigin(newOrigin);
    setOpen(true);
  };

  const hideAlert = () => {
    setOpen(false);
  };

  return (
    <AlertContext.Provider value={{ showAlert }}>
      {children}
      <S.StyledSnackBar anchorOrigin={origin} open={open} autoHideDuration={2000} onClose={hideAlert}>
        <Alert onClose={hideAlert} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </S.StyledSnackBar>
    </AlertContext.Provider>
  );
};
