import { lazy } from "react";
import { useState } from "react";
import { Box } from "@mui/material";
import { useGoBack, useNextStep, useWindowSize } from "../../../hooks";
import { TPage } from "../../../model";
import { MAIN_GOAL } from "../../../utils/enum";
import { useAlertContext, useAnswersContext } from "../../../context";
import { ArrowBackIos } from "@mui/icons-material";
import btn_1 from "../../../assets/img/V2/mainGoal/btn_1.webp";
import btn_2 from "../../../assets/img/V2/mainGoal/btn_2.webp";
import btn_3 from "../../../assets/img/V2/mainGoal/btn_3.webp";
import * as S from "./style";

const CustomButton = lazy(() => import("../../../components/Base/CustomButtonV2/CustomButton"));
const CustomStepProgressBar = lazy(() => import("../../../components/CustomStepProgressBar/CustomStepProgressBar"));

const MainGoal: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });
  const handleGoBack = useGoBack({ pageId, nextPagePath });

  const { height } = useWindowSize();

  const { answers } = useAnswersContext();

  const { showAlert } = useAlertContext();

  const prevSelectedMainGoal: MAIN_GOAL | undefined = answers[pageId]?.join("") as MAIN_GOAL;

  const [selectedOption, setSelectedOption] = useState<MAIN_GOAL | undefined>(prevSelectedMainGoal);

  const onClick = () => {
    if (selectedOption) {
      handleContinue(selectedOption);
    } else {
      showAlert("Select an Option", "warning", { vertical: "top", horizontal: "right" });
    }
  };

  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 3 } },
  };

  return (
    <S.Main>
      <S.PageContent height={height}>
        <S.Content>
          <S.HeaderContent>
            <S.StyledButton onClick={handleGoBack} disableRipple>
              <ArrowBackIos />
            </S.StyledButton>
            <S.PartContent>
              <CustomStepProgressBar part={1} step={1} />
            </S.PartContent>
          </S.HeaderContent>

          <S.Title>
            <Box>What is your main goal?</Box>
          </S.Title>

          <S.OptionsContent heightval={height}>
            <S.Option>
              <S.OptionButton
                height={height}
                selected={selectedOption === MAIN_GOAL.LOSE_WEIGHT}
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (selectedOption === MAIN_GOAL.LOSE_WEIGHT) {
                    setSelectedOption(undefined);
                  } else {
                    setSelectedOption(MAIN_GOAL.LOSE_WEIGHT);
                  }
                }}
                // animate={{ y: selectedOption !== undefined ? "-4.5rem" : 0 }}
                // transition={{ type: "spring", stiffness: 500, damping: 30 }}
                // style={{ filter: selectedOption !== MAIN_GOAL.LOSE_WEIGHT && selectedOption ? "blur(1px)" : "" }}
              >
                <S.OptionButtonText>{MAIN_GOAL.LOSE_WEIGHT}</S.OptionButtonText>

                <S.Image height={height} src={btn_1} alt="" />
              </S.OptionButton>
              <S.OptionInfoBox
                height={height}
                selected={selectedOption === MAIN_GOAL.LOSE_WEIGHT}
                initial={false}
                animate={selectedOption === MAIN_GOAL.LOSE_WEIGHT ? "visible" : "hidden"}
                variants={variants}
              >
                <S.Triangle />
                <S.Info>
                  <S.Light>🔥</S.Light>
                  <S.InfoContent>
                    <S.InfoHeader>Burn Your Body Fat!</S.InfoHeader>
                    <Box>Your personalized plan will be prepared using highly effective and practical fat-burning exercises.</Box>
                  </S.InfoContent>
                </S.Info>
              </S.OptionInfoBox>
            </S.Option>

            <S.Option>
              <S.OptionButton
                height={height}
                selected={selectedOption === MAIN_GOAL.GAIN_MUSCLE}
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (selectedOption === MAIN_GOAL.GAIN_MUSCLE) {
                    setSelectedOption(undefined);
                  } else {
                    setSelectedOption(MAIN_GOAL.GAIN_MUSCLE);
                  }
                }}
                animate={{ y: selectedOption === MAIN_GOAL.LOSE_WEIGHT ? "4.5rem" : 0 }}
                transition={{ type: "spring", stiffness: 500, damping: 30 }}
                // style={{ filter: selectedOption !== MAIN_GOAL.GAIN_MUSCLE && selectedOption ? "blur(1px)" : "" }}
              >
                <S.OptionButtonText>{MAIN_GOAL.GAIN_MUSCLE}</S.OptionButtonText>
                <S.Image height={height} src={btn_2} alt="" />
              </S.OptionButton>
              <S.OptionInfoBox
                height={height}
                selected={selectedOption === MAIN_GOAL.GAIN_MUSCLE}
                initial={false}
                animate={selectedOption === MAIN_GOAL.GAIN_MUSCLE ? "visible" : "hidden"}
                variants={variants}
              >
                <S.Triangle />
                <S.Info>
                  <S.Light>💪</S.Light>
                  <S.InfoContent>
                    <S.InfoHeader>Sculpt Your Body!</S.InfoHeader>
                    <Box>Muscle development encompasses regular and effective training programs alongside optimal nutrition, adequate rest, and motivation strategies.</Box>
                  </S.InfoContent>
                </S.Info>
              </S.OptionInfoBox>
            </S.Option>
            <S.Option>
              <S.OptionButton
                height={height}
                selected={selectedOption === MAIN_GOAL.LEARN_TO_DANCE}
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (selectedOption === MAIN_GOAL.LEARN_TO_DANCE) {
                    setSelectedOption(undefined);
                  } else {
                    setSelectedOption(MAIN_GOAL.LEARN_TO_DANCE);
                  }
                }}
                animate={{ y: selectedOption === MAIN_GOAL.LOSE_WEIGHT || selectedOption === MAIN_GOAL.GAIN_MUSCLE ? "4.5rem" : 0 }}
                transition={{ type: "spring", stiffness: 500, damping: 30 }}
                // style={{ filter: selectedOption !== MAIN_GOAL.LEARN_TO_DANCE && selectedOption ? "blur(1px)" : "" }}
              >
                <S.OptionButtonText>{MAIN_GOAL.LEARN_TO_DANCE}</S.OptionButtonText>
                <S.Image height={height} src={btn_3} alt="" />
              </S.OptionButton>
              <S.OptionInfoBox
                height={height}
                selected={selectedOption === MAIN_GOAL.LEARN_TO_DANCE}
                initial={false}
                animate={selectedOption === MAIN_GOAL.LEARN_TO_DANCE ? "visible" : "hidden"}
                variants={variants}
              >
                <S.Triangle />
                <S.Info>
                  <S.Light>🪩</S.Light>
                  <S.InfoContent>
                    <S.InfoHeader>Stay Fit with Dance!</S.InfoHeader>
                    <Box>Dancing provides a fun workout method that keeps the body in shape. Rhythmic movements improve cardiovascular health and strengthen muscles.</Box>
                  </S.InfoContent>
                </S.Info>
              </S.OptionInfoBox>
            </S.Option>
          </S.OptionsContent>
        </S.Content>
        <CustomButton width={height / 2} text="Continue" customDisabled={selectedOption === undefined} onClick={onClick} />
      </S.PageContent>
    </S.Main>
  );
};

export default MainGoal;
