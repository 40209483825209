const monthMap = new Map<string, number>([
  ["day", 0.0328549], // 1/30.4375
  ["week", 0.230983], // 1/4.34524
  ["month", 1],
  ["year", 12],
]);

const getMonthCount = (recurring: string): number => {
  const monthCount = monthMap.get(recurring);
  if (monthCount) {
    return monthCount;
  }
  return 1;
};

export default getMonthCount;
