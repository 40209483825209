import React from "react";
import Svg from "../../../../components/Base/Svg/Svg";

type LineButtProps = {
  fill?: string;
  color?: string;
  size?: string;
};

const LineButt: React.FC<LineButtProps> = ({ fill, color, size = "20px" }) => (
  <Svg fill="none" color={color} size={size} path="" width="12rem" height="4rem" viewBox="0 0 187 64">
    <path d="M1.5 63H150.965L181.888 5.20239" stroke={fill} strokeWidth="2" strokeLinecap="round" />
    <circle cx="182" cy="5" r="3.75" fill={fill} stroke="white" strokeWidth="1.5" />
  </Svg>
);

export default LineButt;
