import { useCallback } from "react";
import { storage } from "../utils";
import { firebaseService } from "../services/firebase.service";
import userService, { getUserId } from "../services/user.service";

const useAuth = () => {
  const createUserWithEmailAndPassword = useCallback(async (email: string, password: string) => {
    const request = {
      email: email,
      password: password,
      subscriptionId: storage.get("subscriptionId"),
      customerId: storage.get("customerId"),
      answers: {},
    };

    const user = await firebaseService.createUserWithEmailAndPassword(request);

    const userId = await getUserId();

    userService.updateUser({
      _id: userId,
      configId: window.config.CONFIG_ID,
      email: email,
      firebaseUserId: user.user.uid,
    });
  }, []);

  const signInWithEmailAndPassword = useCallback(async (email: string, password: string) => {
    await firebaseService.signInWithEmailAndPassword({ email: email, password: password });
    // firebaseService.getUser({ uid: user.user.uid });
  }, []);

  return { createUserWithEmailAndPassword, signInWithEmailAndPassword };
};

export default useAuth;
