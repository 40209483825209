import { Box, styled } from "@mui/material";

export const Main = styled(Box)(() => ({
  position: "relative",
  width: "100%",
  height: "calc(100% - 5rem)",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  touchAction: "none",
}));

export const PageContent = styled(Box)(({ height }: { height: number }) => ({
  position: "relative",
  width: `${height / 2}px`,
  height: "100%",
  "@media (max-width: 768px)": {
    width: "100%",
  },
}));
export const Content = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  marginTop: "1rem",
}));
export const HeaderText = styled(Box)(() => ({
  color: "#232d49",
  width: "100%",
  textAlign: "center",
  fontSize: "0.8755rem",
}));

export const ImageContent = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  marginBottom: "1rem",
}));

export const Image = styled("img")(() => ({
  width: "15rem",
}));

export const ContentText = styled(Box)(() => ({
  width: "100%",
  textAlign: "center",
  fontSize: "0.875rem",
  fontWeight: "400",
  marginBottom: "1rem",
}));
