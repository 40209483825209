type Storage = {
  keys: typeof storageKeys;
  delete: (key: StorageKey) => void;
  get: (key: StorageKey) => string | null;
  set: (key: StorageKey, value: string | string[] | object | undefined, session?: boolean) => void;
};

type StorageKey = keyof typeof storageKeys;

const storageKeys = {
  answerKey: "answers",
  userId: "userId",
  email: "email",
  subscriptionId: "subscriptionId",
  customerId: "customerId",
  initialUrl: "initialUrl",
  configs: "configs",
};

const storage: Storage = {
  keys: storageKeys,
  get: (key) => {
    return sessionStorage.getItem(storageKeys[key]) || localStorage.getItem(storageKeys[key]) || null;
  },
  set: (key, value, session) => {
    session
      ? sessionStorage.setItem(storageKeys[key], typeof value !== "string" ? JSON.stringify(value) : value)
      : localStorage.setItem(storageKeys[key], typeof value !== "string" ? JSON.stringify(value) : value);
  },
  delete: (key) => {
    sessionStorage.removeItem(storageKeys[key]);
    localStorage.removeItem(storageKeys[key]);
  },
};

export default storage;
