import { lazy, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { Box } from "@mui/material";
import { TPage } from "../../../model";
import { firebaseService } from "../../../services/firebase.service";
import { fbq } from "../../../services/metapixel.service";
import { useAuth, useNextStep, useWindowSize } from "../../../hooks";
import { captureException } from "../../../services/sentry.service";
import { helpers, storage } from "../../../utils";
import { FirebaseError } from "@firebase/util";
import { useAuthContext } from "../../../context";
import * as S from "./style";

const WarningIcon = lazy(() => import("../../../components/Base/Svg/Icons/Warning"));
const EyeIconButton = lazy(() => import("../../../components/Base/Svg/Icons/EyeIconButton/EyeIconButton"));

const initialValues = {
  email: "",
  password: "",
};

const SignUp: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleContinue = useNextStep({ pageId, nextPagePath });
  const navigate = useNavigate();
  const { height } = useWindowSize();

  const { createUserWithEmailAndPassword } = useAuth();
  const { setAuthContext } = useAuthContext();

  const { handleSubmit, handleChange, status, getFieldProps, touched, values, errors } = useFormik({
    initialValues,
    validate: helpers.validateEmailPassword,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      firebaseService.logFirebaseEvent("register_save_button_clicked");
      fbq("trackCustom", "register_save_button_clicked");

      storage.set("email", values.email);

      createUserWithEmailAndPassword(values.email, values.password)
        .then((_) => {
          setAuthContext({ user: { email: values.email }, isLoggedIn: true });
          handleContinue(values.email);
          setLoading(false);
        })
        .catch((err: FirebaseError) => {
          setLoading(false);
          setSubmitting(false);
          captureException(err);
          if (err.code === "auth/email-already-in-use") {
            setStatus("email-already-in-use");
          } else {
            setStatus("The registration details are incorrect");
          }
        });
    },
  });

  return (
    <S.Main heightvalue={height}>
      <S.Form onSubmit={handleSubmit}>
        <S.Header>
          <S.HeaderText>Sign up</S.HeaderText>
          <S.HeaderSubText>
            <span>Already have an account?</span>
            <S.SignUpButton variant="text" onClick={() => navigate("/V1/signIn")}>
              Sign in
            </S.SignUpButton>
          </S.HeaderSubText>
        </S.Header>
        <S.Content>
          <Box sx={{ width: "90%", margin: "0.5rem 0" }}>
            <S.InputLabel>E-mail</S.InputLabel>
            <S.StyledTextField
              {...getFieldProps("email")}
              type="email"
              error={errors.email ? true : false}
              name="email"
              value={values.email}
              onChange={handleChange}
              autoComplete="email"
            />
            {touched.email && errors.email ? (
              <S.WarningContent>
                <WarningIcon />
                <Box>{errors.email}</Box>
              </S.WarningContent>
            ) : null}
          </Box>
          <Box sx={{ width: "90%", margin: "0.5rem 0" }}>
            <S.InputLabel>Password</S.InputLabel>
            <Box sx={{ position: "relative" }}>
              <S.StyledTextField
                type={showPassword ? "text" : "password"}
                error={touched.password && errors.password ? true : false}
                name="password"
                value={values.password}
                onChange={handleChange}
                autoComplete="current-password"
              />
              <EyeIconButton show={showPassword} clicked={() => setShowPassword(!showPassword)} />
            </Box>
            {touched.password && errors.password ? (
              <S.WarningContent>
                <WarningIcon />
                <Box>{errors.password}</Box>
              </S.WarningContent>
            ) : null}
          </Box>
        </S.Content>
        <S.Bottom heightvalue={height}>
          <S.StyledButton loading={loading} text="Create Account" type="submit" disabled={!values.email || !values.password} />
        </S.Bottom>
        {!!status ? (
          <S.ErrorContent>
            <WarningIcon />
            <Box>
              {status === "email-already-in-use" ? (
                <Box>
                  <span>Email already in use. Please Sign in </span>
                </Box>
              ) : (
                <span>{status}</span>
              )}
            </Box>
          </S.ErrorContent>
        ) : null}
      </S.Form>
    </S.Main>
  );
};

export default SignUp;
