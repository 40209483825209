import { feetToCm, lbToKg } from ".";
import { MEASURE_TYPE, WEIGHT_TYPE } from "../enum";

const bmiCalculate = (selectedHeight: { value: number; type: string }, selectedWeight: { value: number; type: string }) => {
  let result = {
    text: "",
    color: "",
    value: "",
    type: "",
  };

  let height: number;
  let weight: number;

  if (selectedHeight.type === MEASURE_TYPE.CM) {
    height = selectedHeight.value;
    if (selectedWeight.type === WEIGHT_TYPE.KG) {
      weight = selectedWeight.value;
    } else {
      weight = lbToKg(selectedWeight.value);
    }
  } else {
    height = feetToCm(selectedHeight.value);
    if (selectedWeight.type === WEIGHT_TYPE.KG) {
      weight = selectedWeight.value;
    } else {
      weight = lbToKg(selectedWeight.value);
    }
  }

  const bmi = (weight / Math.pow(height / 100, 2)).toFixed(1);
  result.value = bmi;
  if (Number(bmi) < 18.5) {
    result.text = "Being underweight, make sure to set your goals reasonably.";
    result.color = "#FF46EE";
    result.type = "Underweight";
  } else if (Number(bmi) >= 18.5 && Number(bmi) <= 24.9) {
    result.text = "You've got a great figure! Keep it up!";
    result.color = "#3AC141";
    result.type = "Normal";
  } else {
    result.text = "You've great potential to get fitter.";
    result.color = "#FF46EE";
    if (Number(bmi) >= 30) {
      result.type = "Obese";
    } else {
      result.type = "Overweight";
    }
  }
  return result;
};

export default bmiCalculate;
