import { lazy, useState } from "react";
import { Box } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNextStep, useWindowSize, useIsMobile, useGoBack } from "../../../hooks";
import { helpers } from "../../../utils";
import { MEASURE_TYPE } from "../../../utils/enum";
import { TPage } from "../../../model";
import { useAnswersContext } from "../../../context";
import * as S from "./style";

const CustomHeader = lazy(() => import("../../../components/CustomHeader/CustomHeader"));
const CustomButton = lazy(() => import("../../../components/Base/CustomButton/CustomButton"));
const CustomToggleButton = lazy(() => import("../../../components/CustomToggleButton/CustomToggleButton"));
const CustomStepper = lazy(() => import("../../../components/CustomStepper/CustomStepper"));
const HeightRuler = lazy(() => import("../../../components/HeightRuler/HeightRuler"));
const Tip = lazy(() => import("../../../components/Base/Tip/Tip"));

const Height: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });
  const handleGoBack = useGoBack({ pageId, nextPagePath });

  const { answers } = useAnswersContext();

  const prevHeight = answers["height"]?.join("").split(" ");
  const prevHeightValue: number | undefined = Number(prevHeight?.[0]);
  const prevHeightType: MEASURE_TYPE | undefined = prevHeight?.[1] as MEASURE_TYPE;

  const [measureType, setMeasureType] = useState<MEASURE_TYPE>(prevHeightType || MEASURE_TYPE.CM);
  const [selectedHeight, setSelectedHeight] = useState<number>(prevHeightValue || 160);

  const { width, height } = useWindowSize();
  const isMobile = useIsMobile();

  const onClick = () => {
    handleContinue(`${selectedHeight} ${measureType}`);
  };

  return (
    <S.Main>
      <S.PageContent height={height}>
        <CustomHeader part={2} header="BODY DATA" />
        <CustomStepper step={1} onBack={handleGoBack} />
        <S.Title>What`s your height?</S.Title>
        <S.Content>
          <Box width="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center" marginBottom="2rem" zIndex={500}>
            <CustomToggleButton
              onChangeValue={(newValue: string) => {
                if (newValue === MEASURE_TYPE.CM) {
                  const newHeight = helpers.feetToCm(selectedHeight);
                  setSelectedHeight(newHeight);
                } else {
                  const newHeight = helpers.cmToFeet(selectedHeight);
                  setSelectedHeight(newHeight);
                }
                setMeasureType(newValue as MEASURE_TYPE);
              }}
              options={[MEASURE_TYPE.CM, MEASURE_TYPE.FT]}
              selected={measureType}
            />
          </Box>
          <Box>
            <S.RulerContent>
              <S.ArrowUpButton
                color="primary"
                onClick={() => {
                  const newHeight = selectedHeight + 1;
                  if (newHeight <= (measureType === MEASURE_TYPE.CM ? 250 : 8.2)) {
                    setSelectedHeight(newHeight);
                  }
                }}
              >
                <KeyboardArrowUpIcon />
              </S.ArrowUpButton>
              <HeightRuler
                width={isMobile ? width : height / 2}
                height={isMobile ? height - 430 : height / 3}
                step={measureType === MEASURE_TYPE.CM ? 1 : 0.01}
                value={selectedHeight}
                max={measureType === MEASURE_TYPE.CM ? 250 : 8.2}
                onChange={(height: number) => {
                  setSelectedHeight(height);
                }}
                type={measureType}
                vertical
              />
              <S.ArrowDownButton
                onClick={() => {
                  const newHeight = selectedHeight - 1;
                  if (newHeight !== 0) {
                    setSelectedHeight(newHeight);
                  }
                }}
              >
                <KeyboardArrowDownIcon />
              </S.ArrowDownButton>
            </S.RulerContent>
          </Box>
        </S.Content>
        <Tip
          width={height / 2}
          sx={{
            left: "0",
            right: "0",
            margin: "0 auto",
            "@media (max-width: 768px)": {
              margin: "0 auto",
              width: "calc(100% - 3rem)",
              fontSize: "0.68rem",
            },
          }}
        >
          <Box sx={{ fontSize: "0.75rem", fontWeight: "600", marginBottom: "0.25rem" }}>Calculating your body mass index</Box>
          <Box>BMI is widely used as a risk factor for the development of or the prevalence of several healt issues.</Box>
        </Tip>
        <CustomButton width={height / 2} text="Continue" onClick={onClick} />
      </S.PageContent>
    </S.Main>
  );
};

export default Height;
