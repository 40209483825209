import { createRef, useEffect, useMemo, useState, lazy } from "react";
import { useAutoScrollDown, useNextStep, useWindowSize } from "../../../hooks";
import { TPage } from "../../../model";
import { Box } from "@mui/material";
import { useAnswersContext } from "../../../context";
import subtrack from "../../../assets/img/V2/preparingPlan/subtract.webp";
import { planBodyMassIndex } from "../../../utils/helpers";
import * as S from "./style";

const CustomReviews = lazy(() => import("../../../components/V2/CustomReviews/CustomReviews"));
const CustomButton = lazy(() => import("../../../components/Base/CustomButtonV2/CustomButton"));
const TimerProgressBar = lazy(() => import("../../../components/V2/TimerProgressBar/TimerProgressBar"));

const YourPersonalizedFitnessPlan: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const [progress1, setProgress1] = useState({ value: 0, duration: 0, done: false });
  const [progress2, setProgress2] = useState({ value: 0, duration: 0, done: false });
  const [progress3, setProgress3] = useState({ value: 0, duration: 0, done: false });
  const [progress4, setProgress4] = useState({ value: 0, duration: 0, done: false });

  const [showGoals, setShowGoals] = useState(false);
  const [showBodyData, setShowBodyData] = useState(false);
  const [showDanceAnalysis, setShowDanceAnalysis] = useState(false);
  const [showFitnessAnalysis, setShowFitnessAnalysis] = useState(false);

  const handleContinue = useNextStep({ pageId, nextPagePath });

  const { height } = useWindowSize();

  const myRef = createRef<HTMLDivElement>();

  useAutoScrollDown(myRef, 730);

  const { answers } = useAnswersContext();

  // Goals
  const selectedMainGoal = answers["mainGoal"]?.join("");
  const selectedBodyShape = answers["chooseYourTargetBodyShape"]?.join("");

  // Body Data
  const selectedWeight = answers["whatIsYourCurrentWeight"]?.join("").split(" ");
  const currentWeightValue = selectedWeight?.[0];
  const currentWeightType = selectedWeight?.[1];

  const selectedHeight = answers["whatIsYourHeight"]?.join("").split(" ");
  const heightValue = selectedHeight?.[0];
  const heightType = selectedHeight?.[1];

  // Dance Analysis:
  const selectedDanceStyles = answers["selectYourFavoriteDanceStyle"];
  const selectedDanceLevel = answers["chooseYourDanceLevel"];

  // Fitness Analysis:
  const selectedAvtivityLevel = answers["whatIsYourActivityLevel"]?.join("");
  const selectedFitnessLevel = answers["whatIsYourFitnessLevel"]?.join("");

  const bmi = useMemo(() => {
    if (heightValue && heightType && currentWeightValue && currentWeightType) {
      const result = planBodyMassIndex({ type: heightType, value: Number(heightValue) }, { type: currentWeightType, value: Number(currentWeightValue) });
      return result;
    }
  }, [currentWeightType, currentWeightValue, heightType, heightValue]);

  useEffect(() => {
    const timer0 = setTimeout(() => {
      setProgress1((prev) => ({ ...prev, value: 100, duration: 5 }));
    }, 100);

    return () => {
      clearTimeout(timer0);
    };
  }, []);

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setProgress1((prev) => ({ ...prev, done: true }));
      setProgress2((prev) => ({ ...prev, value: 100, duration: 5 }));
    }, 5000);

    return () => {
      clearTimeout(timer1);
    };
  }, []);

  useEffect(() => {
    if (progress1.done) {
      setShowGoals(true);
      const timer2 = setTimeout(() => {
        setProgress2((prev) => ({ ...prev, done: true }));
        setProgress3((prev) => ({ ...prev, value: 100, duration: 5 }));
      }, 5000);

      return () => {
        clearTimeout(timer2);
      };
    }
  }, [progress1.done]);

  useEffect(() => {
    if (progress2.done) {
      setShowBodyData(true);
      const timer3 = setTimeout(() => {
        setProgress3((prev) => ({ ...prev, done: true }));
        setProgress4((prev) => ({ ...prev, value: 100, duration: 5 }));
      }, 5000);

      return () => {
        clearTimeout(timer3);
      };
    }
  }, [progress2.done]);

  useEffect(() => {
    if (progress3.done) {
      setShowFitnessAnalysis(true);
      const timer4 = setTimeout(() => {
        setProgress4((prev) => ({ ...prev, done: true }));
      }, 5000);

      return () => {
        clearTimeout(timer4);
      };
    }
  }, [progress3.done]);

  useEffect(() => {
    if (progress4.done) {
      setShowDanceAnalysis(true);
    }
  }, [progress4.done]);

  return (
    <S.Main>
      <S.PageContent width={height / 2}>
        <S.Header>
          <S.Color />
          <S.Title>
            <Box>Your personalized fitness </Box>
            <Box>
              plan is <S.ColoredText>{!progress1.done || !progress2.done || !progress3.done || !progress4.done ? "being prepared..." : "ready!"} </S.ColoredText>
            </Box>
          </S.Title>
        </S.Header>
        <S.Content>
          <S.Progress>
            <S.ProgressTitle>
              <S.Dot shine={showGoals ? 1 : 0} />
              <S.Key shine={showGoals ? 1 : 0}>Goals:</S.Key>
              {showGoals && (
                <S.ValueContent initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
                  <S.Value>
                    {selectedMainGoal}, {selectedBodyShape}
                  </S.Value>
                  <S.Icon src={subtrack} alt="" />
                </S.ValueContent>
              )}
            </S.ProgressTitle>
            {!progress1.done && (
              <S.TimerProgressBarContainer animate={{ y: !progress1.done ? "1rem" : 0 }} exit={{ y: 0 }} transition={{ type: "spring", stiffness: 500, damping: 30 }}>
                <TimerProgressBar value={progress1.value} duration={progress1.duration} />
              </S.TimerProgressBarContainer>
            )}
          </S.Progress>

          <S.Progress>
            <S.ProgressTitle>
              <S.Dot shine={showBodyData ? 1 : 0} />
              <S.Key shine={showBodyData ? 1 : 0}>Body Data:</S.Key>
              {showBodyData && (
                <S.ValueContent initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
                  <S.Value>
                    {currentWeightValue} {currentWeightType}, {heightValue} {heightType} <br /> BMI: {bmi ? bmi.value : 0} - {bmi ? bmi.key : ""}
                  </S.Value>
                  <S.Icon src={subtrack} alt="" />
                </S.ValueContent>
              )}
            </S.ProgressTitle>

            {!progress2.done && progress1.done && (
              <S.TimerProgressBarContainer
                animate={{ y: !progress2.done && progress1.done ? "1rem" : 0 }}
                exit={{ y: 0 }}
                transition={{ type: "spring", stiffness: 500, damping: 30 }}
              >
                <TimerProgressBar value={progress2.value} duration={progress2.duration} />
              </S.TimerProgressBarContainer>
            )}
          </S.Progress>

          <S.Progress>
            <S.ProgressTitle>
              <S.Dot shine={showFitnessAnalysis ? 1 : 0} />
              <S.Key shine={showFitnessAnalysis ? 1 : 0}>Fitness Analysis:</S.Key>
              {showFitnessAnalysis && (
                <S.ValueContent initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
                  <S.Value>
                    {selectedAvtivityLevel}, {selectedFitnessLevel}
                  </S.Value>
                  <S.Icon src={subtrack} alt="" />
                </S.ValueContent>
              )}
            </S.ProgressTitle>

            {!progress3.done && progress2.done && (
              <S.TimerProgressBarContainer
                animate={{ y: !progress3.done && progress2.done ? "1rem" : 0 }}
                exit={{ y: 0 }}
                transition={{ type: "spring", stiffness: 500, damping: 30 }}
              >
                <TimerProgressBar value={progress3.value} duration={progress3.duration} />
              </S.TimerProgressBarContainer>
            )}
          </S.Progress>

          <S.Progress>
            <S.ProgressTitle>
              <S.Dot shine={showDanceAnalysis ? 1 : 0} />
              <S.Key shine={showDanceAnalysis ? 1 : 0}>Dance Analysis:</S.Key>
              {showDanceAnalysis && (
                <S.ValueContent initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
                  <S.Value>
                    {selectedDanceStyles?.join(", ")}, {selectedDanceLevel}
                  </S.Value>
                  <S.Icon src={subtrack} alt="" />
                </S.ValueContent>
              )}
            </S.ProgressTitle>
            {!progress4.done && progress3.done && (
              <S.TimerProgressBarContainer
                animate={{ y: !progress4.done && progress3.done ? "1rem" : 0 }}
                exit={{ y: 0 }}
                transition={{ type: "spring", stiffness: 500, damping: 30 }}
              >
                <TimerProgressBar value={progress4.value} duration={progress4.duration} />
              </S.TimerProgressBarContainer>
            )}
          </S.Progress>
        </S.Content>
        <CustomReviews height={height} />
        <CustomButton width={height / 2} text="Continue" disabled={!progress1.done || !progress2.done || !progress3.done || !progress4.done} onClick={() => handleContinue()} />
      </S.PageContent>
    </S.Main>
  );
};

export default YourPersonalizedFitnessPlan;
