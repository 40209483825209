import { lazy, useState } from "react";
import { Box } from "@mui/material";
import { useGoBack, useNextStep, useWindowSize } from "../../../hooks";
import { TPage } from "../../../model";
import { WORK_OUT } from "../../../utils/enum";
import { workOutOptionsV2 } from "../../../utils/constants";
import { useAlertContext, useAnswersContext } from "../../../context";
import { ArrowBackIos } from "@mui/icons-material";
import { AnimatePresence } from "framer-motion";
import * as S from "./style";

const CustomButton = lazy(() => import("../../../components/Base/CustomButtonV2/CustomButton"));
const CustomStepProgressBar = lazy(() => import("../../../components/CustomStepProgressBar/CustomStepProgressBar"));

const WhichPlaceDoYouPreferForYourWorkout: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });
  const handleGoBack = useGoBack({ pageId, nextPagePath });

  const { height } = useWindowSize();

  const { showAlert } = useAlertContext();

  const { answers } = useAnswersContext();

  const prevFavoriteWorkoutPlace: WORK_OUT | undefined = answers[pageId]?.join("") as WORK_OUT;

  const [selectedOption, setSelectedOption] = useState<WORK_OUT>(prevFavoriteWorkoutPlace);

  const onClick = () => {
    if (selectedOption) handleContinue(selectedOption);
    else showAlert("Select an Option", "warning", { vertical: "top", horizontal: "right" });
  };

  return (
    <S.Main>
      <S.PageContent height={height}>
        <S.HeaderContent>
          <S.StyledButton onClick={handleGoBack} disableRipple>
            <ArrowBackIos />
          </S.StyledButton>
          <S.PartContent>
            <CustomStepProgressBar part={3} step={12} />
          </S.PartContent>
        </S.HeaderContent>
        <S.Content>
          <S.Title>
            <Box>
              Which <S.ColoredText>place</S.ColoredText> do you prefer
            </Box>
            <Box>for your workout?</Box>
          </S.Title>
          <AnimatePresence>
            <S.OptionsContent>
              {workOutOptionsV2.map((workOutPlace: any) => (
                <S.Option key={workOutPlace.id} onClick={() => setSelectedOption(workOutPlace.label)} selected={selectedOption === workOutPlace.label ? 1 : 0}>
                  <S.OptionSelected>
                    {selectedOption === workOutPlace.label ? (
                      <svg width="1.5rem" height="1.5rem" viewBox="0 0 20 20" fill="none">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM14.0303 6.96967C14.3232 7.26256 14.3232 7.73744 14.0303 8.03033L9.03033 13.0303C8.73744 13.3232 8.26256 13.3232 7.96967 13.0303L5.96967 11.0303C5.67678 10.7374 5.67678 10.2626 5.96967 9.96967C6.26256 9.67678 6.73744 9.67678 7.03033 9.96967L8.5 11.4393L10.7348 9.2045L12.9697 6.96967C13.2626 6.67678 13.7374 6.67678 14.0303 6.96967Z"
                          fill="white"
                        />
                      </svg>
                    ) : (
                      <S.RadioButton />
                    )}
                  </S.OptionSelected>
                  <S.ImageSelected src={workOutPlace.img} alt="" />
                  {workOutPlace.label}
                </S.Option>
              ))}
            </S.OptionsContent>
          </AnimatePresence>
        </S.Content>
        <CustomButton width={height / 2} text="Continue" customDisabled={selectedOption === undefined} onClick={onClick} />
      </S.PageContent>
    </S.Main>
  );
};

export default WhichPlaceDoYouPreferForYourWorkout;
