import { createRef, lazy, useState } from "react";
import { Box } from "@mui/material";
import loseWeight from "../../../assets/img/motivates/loseWeight.webp";
import learnDance from "../../../assets/img/motivates/learnDance.webp";
import getToned from "../../../assets/img/motivates/getToned.webp";
import feelHappier from "../../../assets/img/motivates/feelHappier.webp";
import { useAutoScrollDown, useGoBack, useNextStep, useWindowSize } from "../../../hooks";
import { TPage } from "../../../model";
import { MOTIVATE } from "../../../utils/enum";
import { useAnswersContext } from "../../../context";
import useClickAnimation from "../../../hooks/useClickAnimation";
import * as S from "./style";

const CustomHeader = lazy(() => import("../../../components/CustomHeader/CustomHeader"));
const CustomButton = lazy(() => import("../../../components/Base/CustomButton/CustomButton"));
const CustomStepper = lazy(() => import("../../../components/CustomStepper/CustomStepper"));

const Motivates: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });
  const handleGoBack = useGoBack({ pageId, nextPagePath });
  const { height } = useWindowSize();

  const { answers } = useAnswersContext();
  const prevMotivates: MOTIVATE | undefined = answers["motivates"]?.join("") as MOTIVATE;

  const [selected, setSelected] = useState<MOTIVATE | undefined>(prevMotivates);

  const buttonRef = createRef<HTMLButtonElement>();
  const active = useClickAnimation(buttonRef);

  const myRef = createRef<HTMLDivElement>();
  useAutoScrollDown(myRef);

  const onClick = () => {
    if (selected) handleContinue(selected);
  };

  return (
    <S.Main>
      <S.PageContent width={height / 2}>
        <CustomHeader part={1} header="GOAL" />
        <CustomStepper step={0} onBack={handleGoBack} />
        <S.Content>
          <S.Title>
            <Box>What motivates you</Box>
            <Box>the most?</Box>
          </S.Title>
          <S.ContentWrapper ref={myRef} display="flex" flexDirection="column" gap="1rem">
            <S.Column>
              <S.ImageContent
                shine={active ? 1 : 0}
                dark={selected === MOTIVATE.LOSE_WEIGHT ? 1 : 0}
                selected={!!selected}
                onClick={(e: any) => {
                  e.preventDefault();
                  setSelected(MOTIVATE.LOSE_WEIGHT);
                }}
              >
                <S.ImageText>{MOTIVATE.LOSE_WEIGHT}</S.ImageText>
                <S.Image src={loseWeight} alt="" />
              </S.ImageContent>
              <S.ImageContent
                shine={active ? 1 : 0}
                dark={selected === MOTIVATE.LEARN_TO_DANCE ? 1 : 0}
                selected={!!selected}
                onClick={(e: any) => {
                  e.preventDefault();
                  setSelected(MOTIVATE.LEARN_TO_DANCE);
                }}
              >
                <S.ImageText>{MOTIVATE.LEARN_TO_DANCE}</S.ImageText>
                <S.Image src={learnDance} alt="" />
              </S.ImageContent>
            </S.Column>

            <Box gap="1rem" display="flex" justifyContent="center" alignItems="center">
              <S.ImageContent
                shine={active ? 1 : 0}
                dark={selected === MOTIVATE.GET_TONED ? 1 : 0}
                selected={!!selected}
                onClick={(e: any) => {
                  e.preventDefault();
                  setSelected(MOTIVATE.GET_TONED);
                }}
              >
                <S.ImageText>{MOTIVATE.GET_TONED}</S.ImageText>
                <S.Image src={getToned} alt="" />
              </S.ImageContent>
              <S.ImageContent
                shine={active ? 1 : 0}
                dark={selected === MOTIVATE.FEEL_HAPPIER ? 1 : 0}
                selected={!!selected}
                onClick={(e: any) => {
                  e.preventDefault();
                  setSelected(MOTIVATE.FEEL_HAPPIER);
                }}
              >
                <S.ImageText>{MOTIVATE.FEEL_HAPPIER}</S.ImageText>
                <S.Image src={feelHappier} alt="" />
              </S.ImageContent>
            </Box>
          </S.ContentWrapper>
        </S.Content>
        <CustomButton ref={buttonRef} width={height / 2} disabled={selected === undefined} text="Continue" onClick={onClick} />
      </S.PageContent>
    </S.Main>
  );
};

export default Motivates;
