const calculateWeeklyPrice = (unit_amount: number, billingPeriod: string) => {
  let daysInPeriod;

  switch (billingPeriod) {
    case "month":
      daysInPeriod = 30;
      break;
    case "year":
      daysInPeriod = 365;
      break;
    default:
      daysInPeriod = 7;
  }

  const dailyPrice = unit_amount / daysInPeriod;
  const weeklyPrice = dailyPrice * 7;

  return (((weeklyPrice / 100) * 100) / 100).toFixed(2);
};

export default calculateWeeklyPrice;
