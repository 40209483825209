import { Box, styled } from "@mui/material";

export const Main = styled(Box)(() => ({
  width: "100%",
  height: "calc(100% - 5rem)",
  overflowY: "auto",
  overflowX: "hidden",
  position: "relative",
  WebkitOverflowScrolling: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "@media (max-width: 768px)": {
    height: "calc(100% - 2.5rem)",
  },
}));

export const PageContent = styled(Box)(({ height }: { height: number }) => ({
  position: "relative",
  width: `${height / 2}px`,
  // width: "50%",
  height: "100%",
  "@media (max-width: 768px)": {
    width: "100%",
  },
}));

export const Content = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  marginTop: "1rem",
  "@media (max-width: 768px)": {
    marginTop: "0rem",
  },
}));

export const Title = styled(Box)(() => ({
  marginBottom: "2rem",
  textAlign: "center",
  fontWeight: "600",
  fontSize: "1.25rem",
  "@media (max-width: 768px)": {
    marginBottom: "1rem",
  },
}));

export const OptionsContent = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const SelectedOption = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  margin: "0.5rem 0",
  gap: "1rem",
}));
