import { Box, Link, styled } from "@mui/material";
import LoadingButton from "../../../../../components/Base/LoadingButton/LoadingButton";

export const Packages = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  background: "linear-gradient(91.13deg, #1D1630 0.68%, #C5169D 59.54%)",
  boxSizing: "border-box",
  // border: "1px solid #FFFFFF1A",
  padding: "1.5rem",
  gap: "1.5rem",
  borderRadius: "1rem",
  color: "#fff",
  fontSize: "0.875rem",
  zIndex: 0,
  position: "relative",
  "&::before": {
    content: '""',
    position: "absolute",
    width: "calc(100% - 2px)",
    height: "calc(100% - 2px)",
    top: 0,
    left: 0,
    zIndex: 1,
    border: "1px solid #FFFFFF1A",
    borderRadius: "1rem",
  },
}));

export const PackageCard = styled(Box)(({ selected }: { selected: boolean }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "1.5rem 1rem",
  border: "0.125rem solid #FFFFFF33",
  borderRadius: "0.875rem",
  position: "relative",
  background: "transparent",
  cursor: "pointer",
  zIndex: 2,
  ...(selected && {
    background: "rgba(229, 250, 64, 0.1)",
    border: "0.125rem solid #E5FA40",
  }),
}));

export const Card = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "2.5rem",
}));

export const CustomButtonContainer = styled(Box)(() => ({
  position: "fixed",
  width: "100%",
  bottom: "1rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  left: 0,
  right: 0,
  // margin: "0 auto",
  zIndex: 6,
  background: "transparent",
}));

export const CustomButton = styled(LoadingButton)(() => ({
  width: "23rem",
  background: "#E5FA40",
  height: "3rem",
  fontSize: "1.125rem",
  fontWeight: "600",
  color: "#232D49",
  margin: "0.5rem 0",
  position: "sticky",
  textTransform: "unset",
  borderRadius: "1.5rem",
  zIndex: 5,
  bottom: "0",
  "&:hover": {
    background: "#E5FA40 !important",
  },

  "@media (max-width: 768px)": {
    width: "85%",
  },
}));

export const ButtonOverlay = styled(Box)(() => ({
  height: "2rem",
}));

export const Bottom = styled(Box)(() => ({
  fontSize: "0.675rem",
  color: "#fff",
  padding: "0 0.25rem",
  // paddingBottom: "3.5rem",
}));

export const BottomLink = styled(Link)(() => ({
  fontSize: "0.675rem",
  color: "#d2cef8",
  textDecorationColor: "#d2cef8",
  position: "relative",
  zIndex: 1,
  "&:hover": {
    textDecorationColor: "#d2cef8",
  },
}));

export const BestOffer = styled(Box)(() => ({
  display: "flex",
  position: "absolute",
  top: "-1.125rem",
  left: "0",
  right: "0",
  justifyContent: "center",
  color: "#1C255E",
  background: "#E5FA40",
  fontWeight: "600",
  gap: "0.5rem",
  padding: "0.125rem 0.5rem",
  borderRadius: "1rem",
  width: "8.75rem",
  margin: "0 auto",
  fontSize: "0.75rem",
  alignItems: "center",
  textTransform: "uppercase",
}));

export const Fire = styled(Box)(() => ({
  fontSize: "1.5rem",
}));

export const OfferText = styled(Box)(() => ({
  fontSize: "0.875rem",
  paddingRight: "0.25rem",
}));

export const Period = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "0.75rem",
}));

export const Title = styled(Box)(() => ({
  fontSize: "1.125rem",
  fontWeight: "600",
}));

export const Price = styled(Box)(() => ({
  display: "flex",
  alignItems: "flex-end",
  // gap: "0.5rem",
}));

export const PriceText = styled(Box)(() => ({
  fontSize: "1.25rem",
}));

export const TimeRange = styled(Box)(() => ({
  fontSize: "1rem",
}));

export const Header = styled(Box)(() => ({
  display: "flex",
  gap: "0.5rem",
  background: "#0000004D",
  padding: "0.75rem",
  borderRadius: "0.5rem",
  width: "fit-content",
  // alignItems: "flex-end",
}));

export const HeaderBold = styled("span")(() => ({
  color: "#E5FA40",
  fontSize: "1.25rem",
  fontWeight: "700",
  lineHeight: "1.25rem",
}));

export const HeaderThin = styled("span")(() => ({
  fontSize: "0.875rem",
  fontWeight: "300",
  lineHeight: "1.25rem",
}));
