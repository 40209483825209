import { Answer, User } from "../model";
import { helpers, storage } from "../utils";

export class UserService {
  apiUrl: string;

  constructor(apiUrl: string) {
    this.apiUrl = apiUrl;
  }

  async createUser(dto: User): Promise<User> {
    return fetch(`${this.apiUrl}/users`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dto),
    }).then((res) => helpers.json(res));
  }

  async getUser(dto: User) {
    return fetch(`${this.apiUrl}/users/${dto._id}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }).then((res) => helpers.json(res));
  }

  async updateUser(dto: User): Promise<User> {
    return fetch(`${this.apiUrl}/users/${dto._id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dto),
    }).then((res) => helpers.json(res));
  }

  async pushAnswer(userId: string, dto: Answer): Promise<any> {
    return fetch(`${this.apiUrl}/users/${userId}/answers`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dto),
    }).then((res) => helpers.json(res));
  }
}

const userService = new UserService(window.config.API_URL);

export const initializeUser = async () => {
  return userService
    .createUser({
      configId: window.config.CONFIG_ID,
    })
    .then((user) => user._id && storage.set("userId", user._id));
};

export const getUserId = async () => {
  const userId = storage.get("userId");

  if (userId) {
    return userId;
  }

  return initializeUser()
    .then((_) => storage.get("userId"))
    .then((userId) => {
      if (!userId) {
        return "";
      }

      return userId;
    });
};

export default userService;
