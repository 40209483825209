import { Box, keyframes, styled } from "@mui/material";

export const Main = styled(Box)(() => ({
  width: "100%",
  height: "calc(100% - 5rem)",
  overflowY: "auto",
  overflowX: "hidden",
  position: "relative",
  WebkitOverflowScrolling: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "@media (max-width: 768px)": {
    height: "calc(100% - 2.5rem)",
  },
}));

export const PageContent = styled(Box)(({ width }: { width: number | string }) => ({
  position: "relative",
  width: `${width ? `${width}px` : "25rem"}`,
  maxWidth: "25rem",
  // width: "50%",
  height: "100%",
  "@media (max-width: 768px)": {
    width: "100%",
  },
}));

export const Content = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  marginTop: "1rem",
  "@media (max-width: 768px)": {
    marginTop: "0rem",
  },
}));

export const Title = styled(Box)(() => ({
  textAlign: "center",
  fontWeight: "600",
  fontSize: "1.25rem",
}));

export const BodyArea = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  position: "relative",
  width: "24rem",
  "@media (max-width: 768px)": {
    margin: "0 auto",
  },
}));

export const Lines = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  // position: "absolute",
  // left: "6rem",
}));

export const LineFullBodyPassive = styled(Box)(() => ({
  position: "absolute",
  top: "3rem",
  left: "4.5rem",
  "@media (max-width: 768px)": {
    top: "1.75rem",
    left: "5.75rem",
  },
}));
export const LineArmsPassive = styled(Box)(() => ({
  position: "absolute",
  top: "6rem",
  left: "4rem",
  "@media (max-width: 768px)": {
    top: "4rem",
    left: "5.5rem",
  },
}));
export const LineBellyPassive = styled(Box)(() => ({
  position: "absolute",
  top: "9rem",
  left: "5.5rem",
  "@media (max-width: 768px)": {
    top: "7rem",
    left: "7rem",
  },
}));
export const LineButtPassive = styled(Box)(() => ({
  position: "absolute",
  top: "13rem",
  left: "5.5rem",
  "@media (max-width: 768px)": {
    top: "10.5rem",
    left: "7rem",
  },
}));
export const LineLegsPassive = styled(Box)(() => ({
  position: "absolute",
  top: "16.5rem",
  left: "7rem",
  "@media (max-width: 768px)": {
    top: "12.5rem",
    left: "8rem",
  },
}));

export const OptionsContent = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  "@media (max-width: 768px)": {
    // width: "90%",
    // margin: "0 auto",
  },
}));

export const Option = styled(Box)(({ selected, dark, shine }: { selected: boolean; dark: number; shine: number }) => ({
  width: "8rem%",
  minWidth: "8rem",
  background: dark === 1 ? "#3AC141" : "#fff",
  padding: "1.25rem 0",
  textAlign: "center",
  borderRadius: "0.5rem",
  fontSize: "0.875rem",
  color: dark === 1 ? "#fff" : "#232D49",
  cursor: "pointer",
  zIndex: 1,
  position: "relative",

  "@media (max-width: 768px)": {
    padding: "1rem 0",
  },

  ...(!selected && {
    "&::before": {
      content: '""',
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundImage: "linear-gradient(120deg,transparent 30%,rgba(244, 246, 252, .8),transparent 90%)",
      top: 0,
      left: "-100%",
      animation: `${shineAnimation} 5s normal linear`,
    },
    ...(shine === 1 && {
      "&::after": {
        content: '""',
        position: "absolute",
        width: "100%",
        height: "100%",
        backgroundImage: "linear-gradient(120deg,transparent 30%,rgba(244, 246, 252, .8),transparent 90%)",
        top: 0,
        left: "-100%",
        animation: `${shineAnimation} 5s normal linear`,
      },
    }),
  }),
}));

export const Image = styled("img")(() => ({
  height: "25rem",
  width: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "0 auto",

  "@media (max-width: 768px)": {
    height: "20rem",
  },
}));

const shineAnimation = keyframes`
0% {left: -100%}
20% {left: 50%}
100% {left: 50%}
}
`;
