import { Box, styled } from "@mui/material";

export const Main = styled(Box)(() => ({
  width: "100%",
  height: "calc(100% - 10rem)",
  overflowX: "hidden",
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "@media (max-width: 768px)": {
    height: "calc(100% - 2.5rem)",
  },
}));

export const PageContent = styled(Box)(({ height }: { height: number }) => ({
  position: "relative",
  width: `${height / 2}px`,
  // width: "50%",
  height: "100%",
  // marginBottom: "5rem",
  overflowX: "hidden",
  overflowY: "auto",
  WebkitOverflowScrolling: "auto",
  "@media (max-width: 768px)": {
    width: "100%",
  },
}));

export const Content = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  marginTop: "1rem",
  "@media (max-width: 768px)": {
    marginTop: "0rem",
  },
}));

export const Title = styled(Box)(() => ({
  marginBottom: "3rem",
  textAlign: "center",
  fontWeight: "600",
  fontSize: "1.25rem",
  "@media (max-width: 768px)": {
    marginBottom: "1rem",
  },
}));

export const RulerContent = styled(Box)(({ heightvalue }: { heightvalue: number }) => ({
  display: "flex",
  // flexDirection: "column",
  // width: "calc(100% - 10rem)",
  width: "100%",
  margin: "0 auto",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "1rem",
  position: "relative",
  // position: "absolute",
  // bottom: `${heightvalue / 6}px`,
}));

export const WeightContent = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  alignItems: "baseline",
  justifyContent: "center",
  margin: "1rem 0",
  gap: "0.25rem",
}));

export const WeightText = styled(Box)(() => ({
  fontSize: "4rem",
  color: "#232D49",
  fontWeight: "600",
}));

export const WeightType = styled(Box)(() => ({
  fontSize: "1rem",
  color: "#232D49",
  fontWeight: "600",
}));

export const BMIContent = styled(Box)(({ width }: { width: number }) => ({
  position: "fixed",
  bottom: "5rem",
  border: "0.0625rem solid #ededed",
  fontSize: "0.75rem",
  padding: "1rem",
  background: "#fff",
  borderRadius: "0.5rem",
  lineHeight: "1.25rem",
  width: `calc(${width}px - 2rem)`,
  left: "0",
  right: "0",
  margin: "0 auto",

  "@media (max-width: 768px)": {
    width: "calc(100% - 4rem)",
  },
}));

export const GoalHeader = styled(Box)(() => ({
  fontSize: "0.875rem",
  fontWeight: "600",
  marginBottom: "0.25rem",
}));

export const GoalText = styled(Box)(() => ({
  fontSize: "0.875rem",
  color: "#FFA919",
  fontWeight: "500",
  marginBottom: "0.25rem",
}));

export const GoalBottom = styled(Box)(() => ({
  color: "#929292",
}));

export const ArrowLeftButton = styled(Box)(() => ({
  border: "0.0625rem solid #9CE0A0",
  color: "#3AC141",
  background: "#fff",
  borderRadius: "50%",
  width: "2rem",
  height: "2rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  bottom: "0",
  left: "0rem",
  zIndex: "5",
  cursor: "pointer",
  "@media (max-width: 768px)": {
    display: "none",
  },
}));

export const ArrowRightButton = styled(Box)(() => ({
  border: "0.0625rem solid #9CE0A0",
  color: "#3AC141",
  background: "#fff",
  borderRadius: "50%",
  width: "2rem",
  height: "2rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  bottom: "0",
  right: "0rem",
  cursor: "pointer",
  "@media (max-width: 768px)": {
    display: "none",
  },
}));
