import { useEffect, useState } from "react";

const useClickAnimation = (buttonRef: React.RefObject<HTMLButtonElement>): boolean => {
  const [active, setActive] = useState<boolean>(false);

  const onClick = () => {
    setActive(true);
    setTimeout(() => {
      setActive(false);
    }, 1000);
  };

  useEffect(() => {
    buttonRef.current?.addEventListener("mouseup", onClick);
    const cleanUpRef = buttonRef.current;
    return () => {
      cleanUpRef?.removeEventListener("mouseup", onClick);
    };
  }, [buttonRef]);

  return active;
};

export default useClickAnimation;
