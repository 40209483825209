import { useMemo } from "react";
import { Box } from "@mui/material";
import { useNextStep, useWindowSize } from "../../../hooks";
import { TPage } from "../../../model";
import { useAnswersContext } from "../../../context";
import gain from "../../../assets/img/part2Final/gain.webp";
import lose from "../../../assets/img/part2Final/lose.webp";
import * as S from "./style";

const Part2Final: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });
  const { height } = useWindowSize();
  const { answers } = useAnswersContext();

  const cweight = answers["currentWeight"]?.join("").split(" ");
  const currentWeight = cweight?.[0];

  const gweight = answers["goalWeight"]?.join("").split(" ");
  const goalWeight = gweight?.[0];

  const weightImg = useMemo(() => {
    if (currentWeight && goalWeight) {
      if (Number(goalWeight) > Number(currentWeight) || Number(goalWeight) === Number(currentWeight)) return gain;
      return lose;
    }
    return gain;
  }, [currentWeight, goalWeight]);

  return (
    <S.Main height={height}>
      {/* <S.Image src={backgrounImg} /> */}
      <S.HeaderText>
        <Box>You will achieve a</Box>
        <Box>healthier body!</Box>
      </S.HeaderText>

      <S.ContentImage src={weightImg} alt="" />
      <S.CustomButtonContainer>
        <S.CustomButton size="large" variant="contained" onClick={() => handleContinue()}>
          Continue
        </S.CustomButton>
      </S.CustomButtonContainer>
    </S.Main>
  );
};

export default Part2Final;
