import { lazy, useState } from "react";
import { Box } from "@mui/material";
import { useNextStep, useWindowSize, useIsMobile, useGoBack } from "../../../hooks";
import { helpers } from "../../../utils";
import { MEASURE_TYPE } from "../../../utils/enum";
import { TPage } from "../../../model";
import { useAnswersContext } from "../../../context";
import { ArrowBackIos } from "@mui/icons-material";
import img from "../../../assets/img/V2/height/img.webp";
import * as S from "./style";

const CustomToggleButton = lazy(() => import("../../../components/V2/CustomToggleButton/CustomToggleButton"));
const HeightRuler = lazy(() => import("../../../components/V2/HeightRuler/HeightRuler"));
const LazyLoadImage = lazy(() => import("../../../components/LazyLoadImage/LazyLoadImage"));
const CustomButton = lazy(() => import("../../../components/Base/CustomButtonV2/CustomButton"));
const CustomStepProgressBar = lazy(() => import("../../../components/CustomStepProgressBar/CustomStepProgressBar"));

const WhatIsYourHeight: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });
  const handleGoBack = useGoBack({ pageId, nextPagePath });

  const { answers } = useAnswersContext();

  const prevHeight = answers["whatIsYourHeight"]?.join("").split(" ");
  const prevHeightValue: number | undefined = Number(prevHeight?.[0]);
  const prevHeightType: MEASURE_TYPE | undefined = prevHeight?.[1] as MEASURE_TYPE;

  const [measureType, setMeasureType] = useState<MEASURE_TYPE>(prevHeightType || MEASURE_TYPE.CM);
  const [selectedHeight, setSelectedHeight] = useState<number>(prevHeightValue || 165);

  const { width, height } = useWindowSize();
  const isMobile = useIsMobile();

  const onClick = () => {
    handleContinue(`${selectedHeight} ${measureType}`);
  };

  return (
    <S.Main>
      <S.PageContent height={height}>
        <S.HeaderContent>
          <S.StyledButton onClick={handleGoBack} disableRipple>
            <ArrowBackIos />
          </S.StyledButton>
          <S.PartContent>
            <CustomStepProgressBar part={2} step={6} />
          </S.PartContent>
        </S.HeaderContent>
        <S.Title>What`s your height?</S.Title>
        <S.SubTitle>(Determine your age by swiping up or down)</S.SubTitle>
        <S.Content height={height}>
          <Box width="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center" zIndex={500}>
            <CustomToggleButton
              onChangeValue={(newValue: string) => {
                if (newValue === MEASURE_TYPE.CM) {
                  const newHeight = helpers.feetToCm(selectedHeight);
                  setSelectedHeight(newHeight);
                } else {
                  const newHeight = helpers.cmToFeet(selectedHeight);
                  setSelectedHeight(newHeight);
                }
                setMeasureType(newValue as MEASURE_TYPE);
              }}
              options={[MEASURE_TYPE.CM, MEASURE_TYPE.FT]}
              selected={measureType}
            />
          </Box>
          <Box sx={{ position: "relative", height: "100%" }}>
            <S.RulerContent>
              <HeightRuler
                width={isMobile ? width : height / 2}
                height={isMobile ? height - 300 : height / 1.75}
                step={measureType === MEASURE_TYPE.CM ? 1 : 0.01}
                value={selectedHeight}
                max={measureType === MEASURE_TYPE.CM ? 250 : 8.2}
                onChange={(height: number) => {
                  setSelectedHeight(height);
                }}
                color="#fff"
                type={measureType}
              />
              <S.Image heightval={height}>
                <LazyLoadImage img={img} alt="" />
              </S.Image>
            </S.RulerContent>
          </Box>
        </S.Content>
        <CustomButton width={height / 2} text="Continue" onClick={onClick} />
      </S.PageContent>
    </S.Main>
  );
};

export default WhatIsYourHeight;
