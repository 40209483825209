interface WeightProgress {
  firstMonthTargetWeight: number;
  totalWeightTime: number;
}

const weightProgress = (currentWeight?: string | number, targetWeight?: string | number): WeightProgress => {
  // Tip kontrolü ve sayıya dönüştürme
  const currentWeightNumber: number = typeof currentWeight === "string" ? parseFloat(currentWeight) : (currentWeight as number);
  const targetWeightNumber: number = typeof targetWeight === "string" ? parseFloat(targetWeight) : (targetWeight as number);

  // İlk ay için hedef kiloyu hesaplama
  let firstMonthTargetWeight: number;
  if (currentWeightNumber > targetWeightNumber) {
    firstMonthTargetWeight = Math.max(Math.floor(currentWeightNumber - currentWeightNumber * 0.1), targetWeightNumber); // Kilo verme senaryosu
  } else {
    firstMonthTargetWeight = Math.min(Math.ceil(currentWeightNumber + currentWeightNumber * 0.1), targetWeightNumber); // Kilo alma senaryosu
  }

  // İlk ayda kaç kilo değiştiğini hesaplama
  const weightChangeFirstMonth: number = Math.abs(currentWeightNumber - firstMonthTargetWeight);

  // Kalan kilo miktarını bulma
  const remainingWeight: number = Math.abs(targetWeightNumber - currentWeightNumber);

  // Kalan kiloyu kaç ayda alabileceğini veya verebileceğini bulma
  const totalWeightTime: number = Math.ceil(remainingWeight / weightChangeFirstMonth);

  return { firstMonthTargetWeight, totalWeightTime };
};

export default weightProgress;
