import { Box, Button, styled } from "@mui/material";
import { motion } from "framer-motion";

export const Main = styled(Box)(() => ({
  width: "100%",
  height: "calc(100% - 5rem)",
  overflow: "hidden",
  touchAction: "none",
  position: "relative",
  WebkitOverflowScrolling: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "Space Grotesk",
  userSelect: "none",
  "@media (max-width: 768px)": {
    height: "calc(100% - 2.5rem)",
  },
}));

export const PageContent = styled(Box)(({ width }: { width: number | string }) => ({
  position: "relative",
  width: `${width ? `${width}px` : "25rem"}`,
  maxWidth: "25rem",
  // width: "50%",
  height: "100%",
  "@media (max-width: 768px)": {
    width: "100%",
  },
}));

export const Header = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  width: "100%",
  marginTop: "2rem",
  marginBottom: "2.5rem",
  gap: "1rem",
  "@media (max-width: 768px)": {
    marginBottom: "1rem",
    marginLeft: "-0.5rem",
  },
}));

export const Title = styled(Box)(() => ({
  textAlign: "start",
  fontWeight: "600",
  fontSize: "1.5rem",
  lineHeight: "2.5rem",
  color: "#fff",
  userSelect: "none",
}));

export const Color = styled(Box)(() => ({
  width: "0.75rem",
  height: "4.5rem",
  background: "linear-gradient(180deg, #C5169D 24.5%, #1D1630 100%)",
}));

export const ColoredText = styled("span")(() => ({
  color: "#E5FA40",
  userSelect: "none",
}));

export const Content = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  marginTop: "1rem",
  gap: "1rem",
  "@media (max-width: 768px)": {
    marginTop: "0rem",
  },
}));

export const Progress = styled(motion.div)(() => ({
  marginBottom: "0.5rem",
  width: "calc(100% - 3rem)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

export const ProgressTitle = styled(Box)(() => ({
  fontSize: "1rem",
  fontWeight: 500,
  marginBottom: "0.5rem",
  display: "flex",
  alignItems: "center",
}));

export const Dot = styled(Box)(({ shine }: { shine: number }) => ({
  background: "#E5FA40",
  userSelect: "none",
  borderRadius: "50%",
  width: "0.5rem",
  height: "0.5rem",
  marginRight: "0.5rem",
  ...(shine === 0 && {
    background: "#E5FA4066",
  }),
}));

export const Key = styled("span")(({ shine }: { shine: number }) => ({
  color: "#E5FA40",
  userSelect: "none",
  marginRight: "0.5rem",
  minWidth: "8.25rem",
  fontWeight: "400",
  ...(shine === 0 && {
    color: "#C2C2C2",
  }),
}));

export const ValueContent = styled(motion.div)(() => ({
  color: "#fff",
  userSelect: "none",
  display: "flex",
  alignItems: "center",
  width: "calc(100% - 8.25rem)",
  justifyContent: "space-between",
}));

export const Value = styled(Box)(() => ({
  color: "#fff",
  userSelect: "none",
}));

export const Icon = styled("img")(() => ({
  color: "#fff",
  userSelect: "none",
  width: "1.25rem",
  height: "1.25rem",
}));

export const Reviews = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  "@media (max-width: 768px)": {
    width: "calc(100% - 3rem)",
    margin: "0 auto",
  },
}));

export const ReviewCard = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  boxShadow: "0px 14px 9.199999809265137px 0px #00000040",
  fontSize: "0.75rem",
  padding: "1rem",
  background: "#1C255E",
  borderRadius: "1rem",
  lineHeight: "1rem",
  gap: "1rem",
}));

export const CardHeader = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
}));

export const ProfileContent = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
}));

export const ProfileName = styled(Box)(() => ({
  display: "block",
  minWidth: "8rem",
  fontSize: "0.875rem",
  fontWeight: "500",
  color: "#fff",
}));

export const ReviewText = styled(Box)(() => ({
  color: "#fff",
}));

export const Image = styled("img")(() => ({
  width: "50%",
  height: "auto",
  borderRadius: "50%",
}));

export const CustomButtonContainer = styled(Box)(() => ({
  position: "absolute",
  bottom: "1rem",
  left: "1rem",
  right: "1rem",
  width: "calc(100% - 2rem)",
  height: "3rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: "5",
  "@media (max-width: 768px)": {
    left: "1rem",
    right: "1rem",
    width: "calc(100% - 2rem)",
  },
}));

export const CustomButton = styled(Button)(() => ({
  width: "25rem",
  // margin: "2rem 0",
  borderRadius: "2rem",
  height: "3rem",
  background: "#E5FA40",
  color: "#232D49",
  textTransform: "none",
  fontWeight: 500,
  fontFamily: "Space Grotesk",
  "&:disabled": {
    background: "#899628 !important",
    color: "#232D49 !important",
  },
  "&:hover": {
    background: "#E5FA40 !important",
  },
}));

export const TimerProgressBarContainer = styled(motion.div)(() => ({}));
