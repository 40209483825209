import { memo } from "react";
import { Box } from "@mui/material";
import { useNextStep, useWindowSize } from "../../../hooks";
import { TPage } from "../../../model";
import * as S from "./style";

const WantToImproveYourWellbeingByDancing: React.FC<TPage> = memo(({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });
  const { height } = useWindowSize();

  const onClick = (value: string) => {
    handleContinue(value);
  };

  return (
    <S.Main height={height}>
      <S.HeaderText>
        <Box>Want to improve your</Box>
        <Box>wellbeing by dancing?</Box>
      </S.HeaderText>
      <S.CustomButtonContainer>
        <S.CustomLightButton size="large" variant="contained" onClick={() => onClick("No")}>
          No
        </S.CustomLightButton>
        <S.CustomButton size="large" variant="contained" onClick={() => onClick("Yes")}>
          Yes
        </S.CustomButton>
      </S.CustomButtonContainer>
    </S.Main>
  );
});

export default WantToImproveYourWellbeingByDancing;
