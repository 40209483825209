import { CustomOption } from "../../model";
import { targetBodyShapeOptionsV2 } from "../constants";

const getPreviousBodyShape = (selectedCurrentBodyShape: CustomOption): string => {
  const currentIndex = targetBodyShapeOptionsV2.findIndex((option: CustomOption) => option.id === selectedCurrentBodyShape.id);
  if (currentIndex === -1 || currentIndex === 0) {
    return selectedCurrentBodyShape.payload.img;
  }

  return targetBodyShapeOptionsV2[currentIndex - 1].payload.img;
};

const getNextBodyShape = (selectedCurrentBodyShape: CustomOption): string => {
  const currentIndex = targetBodyShapeOptionsV2.findIndex((option: CustomOption) => option.id === selectedCurrentBodyShape.id);
  if (currentIndex === -1 || currentIndex === targetBodyShapeOptionsV2.length - 1) {
    return selectedCurrentBodyShape.payload.img;
  }
  return targetBodyShapeOptionsV2[currentIndex + 1].payload.img;
};

const determineTargetBodyShape = (
  selectedCurrentBodyShapeTitle?: string,
  selectedTargetBodyShapeTitle?: string,
  currentWeight?: string | number,
  targetWeight?: string | number
): string => {
  const currentWeightValue = typeof currentWeight === "string" ? parseFloat(currentWeight) : currentWeight;
  const targetWeightValue = typeof targetWeight === "string" ? parseFloat(targetWeight) : targetWeight;

  const selectedCurrentBodyShape = targetBodyShapeOptionsV2.find((c) => c.payload.title === selectedCurrentBodyShapeTitle);
  const selectedTargetBodyShape = targetBodyShapeOptionsV2.find((c) => c.payload.title === selectedTargetBodyShapeTitle);

  if (!currentWeightValue || !targetWeightValue || !selectedCurrentBodyShape || !selectedTargetBodyShape) {
    return targetBodyShapeOptionsV2[0].payload.img;
  }

  // currentWeight=60, targetWeight=65, selectedCurrentBodyShape = Normal , selectedTargetBodyShape = Slim, return Overweight
  if (currentWeightValue < targetWeightValue && selectedCurrentBodyShape.value >= selectedTargetBodyShape.value) {
    return getNextBodyShape(selectedCurrentBodyShape);
    // currentWeight=65, targetWeight=60, selectedCurrentBodyShape = Normal , selectedTargetBodyShape = Overveight, return Slim
  } else if (currentWeightValue > targetWeightValue && selectedCurrentBodyShape.value <= selectedTargetBodyShape.value) {
    return getPreviousBodyShape(selectedCurrentBodyShape);
  } else {
    return selectedTargetBodyShape.payload.img;
  }
};

export default determineTargetBodyShape;
