import { createRef, lazy, useState } from "react";
import { Box } from "@mui/material";
import { useClickAnimation, useGoBack, useNextStep, useWindowSize } from "../../../hooks";
import { TPage } from "../../../model";
import { PHYSICAL_ACTIVITY } from "../../../utils/enum";
import { physicalActivityOptions } from "../../../utils/constants";
import { useAnswersContext } from "../../../context";
import * as S from "./style";

const CustomHeader = lazy(() => import("../../../components/CustomHeader/CustomHeader"));
const CustomButton = lazy(() => import("../../../components/Base/CustomButton/CustomButton"));
const CustomStepper = lazy(() => import("../../../components/CustomStepper/CustomStepper"));

const PhysicalActivity: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });
  const handleGoBack = useGoBack({ pageId, nextPagePath });

  const { height } = useWindowSize();

  const { answers } = useAnswersContext();

  const buttonRef = createRef<HTMLButtonElement>();
  const active = useClickAnimation(buttonRef);

  const prevSelectedPhysicalActivity: PHYSICAL_ACTIVITY | undefined = answers[pageId]?.join("") as PHYSICAL_ACTIVITY;

  const [selectedOption, setSelectedOption] = useState<PHYSICAL_ACTIVITY>(prevSelectedPhysicalActivity);

  const onClick = () => {
    if (selectedOption) handleContinue(selectedOption);
  };

  return (
    <S.Main>
      <S.PageContent height={height}>
        <CustomHeader part={4} header="FITNESS ANALYSIS" />
        <CustomStepper step={3} onBack={handleGoBack} />
        <S.Content>
          <S.Title>
            <Box>How is your physical</Box>
            <Box>activity?</Box>
          </S.Title>
          <S.OptionsContent gap="0.5rem">
            {physicalActivityOptions.map((physicalActivity) => {
              return (
                <S.Option
                  key={physicalActivity}
                  shine={active ? 1 : 0}
                  dark={selectedOption === physicalActivity ? 1 : 0}
                  selected={!!selectedOption}
                  onClick={() => setSelectedOption(physicalActivity)}
                >
                  {physicalActivity}
                </S.Option>
              );
            })}
          </S.OptionsContent>
        </S.Content>
      </S.PageContent>
      <CustomButton ref={buttonRef} width={height / 2} disabled={selectedOption === undefined} text="Continue" onClick={onClick} />
    </S.Main>
  );
};

export default PhysicalActivity;
