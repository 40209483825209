import { Box, Button, styled } from "@mui/material";

export const Main = styled(Box)(() => ({
  position: "relative",
  width: "100%",
  overflowY: "auto",
  overflowX: "hidden",
  WebkitOverflowScrolling: "touch",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  touchAction: "pan-y",
  // touchAction: "none",
  fontFamily: "Space Grotesk",
  userSelect: "none",
  // height: "calc(100% - 5rem)",
  // marginBottom: "5rem",
  "@media (max-width: 768px)": {
    margin: "0",
  },
}));

export const PageContent = styled(Box)(({ width }: { width: number | string }) => ({
  position: "relative",
  width: `${width}px`,
  // width: "50%",
  height: "100%",
  "@media (max-width: 768px)": {
    width: "calc(100% - 1rem)",
    // marginBottom: "2rem",
  },
}));

export const Overlay = styled(Box)(() => ({
  background: "blur(10px)",
  filter: "blur(8px)",
  position: "absolute",
  width: "100%",
  height: "100%",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 5,
}));

export const Header = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  width: "100%",
  marginTop: "2rem",
  marginBottom: "2.5rem",
  gap: "0.75rem",
  "@media (max-width: 768px)": {
    marginBottom: "1rem",
    marginLeft: "-0.5rem",
  },
}));

export const Color = styled(Box)(() => ({
  width: "0.75rem",
  height: "2rem",
  background: "linear-gradient(180deg, #C5169D 24.5%, #1D1630 100%)",
}));

export const Title = styled(Box)(() => ({
  textAlign: "start",
  fontWeight: "600",
  fontSize: "1.5rem",
  lineHeight: "2.5rem",
  color: "#fff",
  userSelect: "none",
}));

export const ColoredText = styled("span")(() => ({
  color: "#E5FA40",
  userSelect: "none",
}));

export const Content = styled(Box)(() => ({
  // display: "flex",
  // flexDirection: "column",
  // alignItems: "center",
  // justifyContent: "center",
  width: "100%",
  // marginTop: "1rem",
  // gap: "1rem",
  "@media (max-width: 768px)": {
    margin: "0 auto",
    width: "calc(100% - 1rem)",
  },
}));

export const CustomButtonContainer = styled(Box)(() => ({
  position: "fixed",
  bottom: "1rem",
  left: "1rem",
  right: "1rem",
  width: "calc(100% - 2rem)",
  height: "3rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: "5",
  "@media (max-width: 768px)": {
    left: "1rem",
    right: "1rem",
    width: "calc(100% - 2rem)",
  },
}));

export const CustomButton = styled(Button)(() => ({
  width: "24rem",
  borderRadius: "2rem",
  height: "3rem",
  background: "#E5FA40",
  color: "#232D49",
  textTransform: "none",
  fontWeight: 500,
  fontFamily: "Space Grotesk",
  "&:disabled": {
    background: "#899628 !important",
    color: "#232D49 !important",
  },
  "&:hover": {
    background: "#E5FA40 !important",
  },
  "@media (max-width: 768px)": {
    width: "calc(100% - 1rem)",
  },
}));

export const MoneyBackOverlay = styled(Box)(() => ({
  minHeight: "21.5rem",
  "@media (max-width: 768px)": {
    minHeight: "23rem",
  },
}));

export const MoneyBackWrapper = styled(Box)(() => ({
  // paddingBottom: "6rem",
  border: "2px solid #E5FA40",
  borderRadius: "1rem",
  padding: "1.5rem",
  boxShadow: "0rem 0rem 0.75rem 0rem #E5FA40",
  position: "relative",
  zIndex: "1",
}));

export const MoneyBackHeader = styled(Box)(() => ({
  textAlign: "center",
  color: "#fff",
  fontSize: "1.25rem",
  fontWeight: "600",
  marginBottom: "1rem",
}));

export const MoneyBackContent = styled(Box)(() => ({
  color: "#fff",
  fontSize: "0.75rem",
  lineHeight: "1.25rem",
  position: "relative",
  zIndex: 1,
  textAlign: "justify",
}));

export const MoneyBackIconWrapper = styled(Box)(() => ({
  zIndex: 2,
  position: "absolute",
  right: "1rem",
  bottom: "-2rem",
}));

export const MoneyBackIcon = styled("img")(() => ({
  width: "2.5rem",
  height: "auto",
  margin: "auto",
  backgroundColor: "#0D1230",
}));
