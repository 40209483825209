import { styled, Box, Button, Link } from "@mui/material";
import { motion } from "framer-motion";
import Lottie from "lottie-react";

export const Main = styled(Box)(() => ({
  position: "fixed",
  width: "100%",
  height: "calc(100% - 5rem)",
  overflow: "hidden",
  touchAction: "none",
  // position: "relative",
  WebkitOverflowScrolling: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "Space Grotesk",
  userSelect: "none",
  "@media (max-width: 768px)": {
    height: "100%",
    width: "100%",
    margin: "0",
  },
}));

export const PageContent = styled(Box)(({ height }: { height: number }) => ({
  position: "relative",
  width: `${height / 2}px`,
  height: "calc(100% - 1rem)",
  margin: "0.5rem 0",
  "@media (max-width: 768px)": {
    width: "100%",
    height: "100%",
    margin: "0",
  },
}));

export const Content = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  marginTop: "1rem",
  position: "relative",
  "@media (max-width: 768px)": {
    marginTop: "0.5rem",
  },
}));

export const StyledLottie = styled(Lottie)(() => ({
  height: "100%",
  display: "flex",
  alignItems: "center",
  svg: {
    width: `100%`,
    height: "100%",
  },
}));

export const ButtonContent = styled(Box)((theme) => ({
  position: "absolute",
  bottom: "1rem",
  left: "0.5rem",
  right: "0.5rem",
  width: "calc(100% - 1rem)",
  height: "3rem",

  "@media (max-width: 768px)": {
    left: "1rem",
    right: "1rem",
    width: "calc(100% - 2rem)",
  },
}));

export const HeaderText = styled(Box)(() => ({
  color: "#fff",
  position: "absolute",
  left: "0",
  right: "0",
  top: "5%",
  width: "100%",
  textAlign: "center",
  fontSize: "1.25rem",
  fontWeight: "600",
}));

export const PercentageText = styled(Box)(() => ({
  color: "#ffa000",
  position: "absolute",
  left: "0",
  right: "0",
  width: "100%",
  textAlign: "center",
  fontSize: "2rem",
  fontWeight: "600",
  top: "17%",
  // marginBottom: "5%",
}));

export const ContentText = styled(Box)(() => ({
  color: "#e0e0e0",
  position: "absolute",
  top: "25%",
  left: "0",
  right: "0",
  // marginBottom: "5%",
  width: "100%",
  textAlign: "center",
  fontSize: "0.875rem",
  fontWeight: "400",
}));

export const ContentImage = styled("img")(() => ({
  position: "absolute",
  width: "20rem",
  margin: "0 auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // marginBottom: "5%",
  left: "0",
  right: "0",
  bottom: "7rem",
}));

export const BottomText = styled(Box)(() => ({
  color: "#e0e0e0",
  position: "absolute",
  bottom: "5rem",
  left: "0",
  right: "0",
  width: "100%",
  textAlign: "center",
  fontSize: "0.75rem",
  fontWeight: "400",
}));

export const CustomButtonContainer = styled(motion.div)(() => ({
  position: "absolute",
  bottom: "1.75rem",
  left: "0.5rem",
  right: "0.5rem",
  width: "calc(100% - 1rem)",
  height: "3rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "@media (max-width: 768px)": {
    left: "1rem",
    right: "1rem",
    width: "calc(100% - 2rem)",
  },
}));

export const CustomButton = styled(Button)(() => ({
  width: "25rem",
  // margin: "2rem 0",
  borderRadius: "2rem",
  height: "3rem",
  background: "#E5FA40",
  color: "#232D49",
  textTransform: "none",
  fontWeight: 500,
  fontFamily: "Space Grotesk",
  "&:hover": {
    background: "#E5FA40 !important",
  },
}));

export const MainInfoBox = styled(motion.div)(() => ({
  position: "absolute",
  top: "1.75rem",
  left: 0,
  right: 0,
  margin: "0 auto",
  backgroundColor: "#1C255E",
  width: "100%",
  borderRadius: "1rem",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  pointerEvents: "none",
  transition: "opacity 0.3s ease",

  "@media (max-width: 768px)": {
    width: "90%",
  },
}));

export const SubInfoBox = styled(motion.div)(() => ({
  position: "absolute",
  top: "9.75rem",
  left: 0,
  right: 0,
  margin: "0 auto",
  backgroundColor: "#1C255E",
  width: "100%",
  borderRadius: "1rem",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  pointerEvents: "none",
  transition: "opacity 0.3s ease",
  zIndex: 1,

  "@media (max-width: 768px)": {
    width: "90%",
    top: "8.5rem",
  },
}));

export const Triangle = styled(Box)(() => ({
  position: "absolute",
  display: "block",
  width: "0",
  height: "0",
  borderStyle: "solid",
  borderWidth: "10px",
  borderTopWidth: "0",
  borderColor: "transparent",
  bottom: "-8px",
  right: "2.5rem",
  borderBottomColor: "#1C255E",
  transform: "rotateZ(180deg)",
  "&::after": {
    position: "absolute",
    display: "block",
    width: "0",
    height: "0",
    borderStyle: "solid",
    borderWidth: "10px",
    borderTopWidth: "0",
    borderColor: "transparent",
    bottom: "0",
    margin: "-10px",
    content: '" "',
    borderBottomColor: "#1C255E",
  },
}));

export const MainInfo = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  fontSize: "1.5rem",
  fontWeight: "700",
  lineHeight: "2rem",
  padding: "0.75rem 1rem",
  borderRadius: "0.5rem",
  color: "#fff",
  "@media only screen and (min-width: 350px) and (max-width: 390px)": {
    fontSize: "1.25rem",
  },
}));

export const SubInfo = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  fontSize: "0.875rem",
  fontWeight: "400",
  lineHeight: "1.5rem",
  padding: "0.75rem 1rem",
  borderRadius: "0.5rem",
  color: "#fff",
}));

export const Colored = styled("span")(() => ({
  color: "#E5FA40",
}));

export const ImageContent = styled(motion.div)(() => ({
  position: "absolute",
  bottom: "0rem",
  left: 0,
  right: 0,
  margin: "0 auto",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "@media (max-width: 768px)": {
    bottom: "1rem",
  },
}));

export const Image = styled(motion.img)(() => ({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  "@media (max-width: 768px)": {
    width: "90%",
    height: "90%",
  },
}));

export const TextButtonsContainer = styled(motion.div)(() => ({
  position: "absolute",
  bottom: "0",
  left: "0.5rem",
  right: "0.5rem",
  width: "calc(100% - 1rem)",
  display: "flex",
  justifyContent: "center",
  gap: "3rem",
  alignItems: "center",
  "@media (max-width: 768px)": {
    bottom: "0.25rem",
    left: "1rem",
    right: "1rem",
    width: "calc(100% - 2rem)",
  },
}));

export const TextButton = styled(Link)(() => ({
  borderRadius: "2rem",
  color: "#E5FA40",
  opacity: 0.6,
  textTransform: "none",
  fontSize: "0.75rem",
  fontWeight: "400",
  fontFamily: "Space Grotesk",
  textAlign: "center",
  alignContent: "flex-end",
  "&:hover": {
    opacity: 0.75,
    // background: "#E5FA40 !important",
  },
}));
