import { useNextStep, useWindowSize } from "../../../hooks";
import { TPage } from "../../../model";
import group from "../../../assets/img/analysisResult/group.webp";
import check from "../../../assets/img/analysisResult/check.webp";
import { Box } from "@mui/material";
import * as S from "./style";

const AnalysisResult: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });
  const { height } = useWindowSize();

  return (
    <S.Main height={height}>
      {/* <S.Image src={backgrounImg} /> */}
      <S.CheckIcon src={check} alt="" />
      <S.HeaderText>
        <Box>Your Analysis</Box>
        <Box>Result is Successfull</Box>
      </S.HeaderText>
      <S.HeaderSubText>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          The process of <S.SubTextHighlight>&nbsp;“Fitness By Dancing”</S.SubTextHighlight>
        </Box>
        <Box>allows you to reach your goal</Box>
      </S.HeaderSubText>

      <S.ContentImage src={group} alt="" />
      <S.BottomText>Let's say goodbye to boring workouts!</S.BottomText>
      <S.CustomButtonContainer>
        <S.CustomButton size="large" variant="contained" onClick={() => handleContinue()}>
          Let's Start
        </S.CustomButton>
      </S.CustomButtonContainer>
    </S.Main>
  );
};

export default AnalysisResult;
