import { CustomOption } from "../../model";
import not_active from "../../assets/img/V2/activityLevel/not_active.json";
import lightly_active from "../../assets/img/V2/activityLevel/lightly_active.json";
import regular_active from "../../assets/img/V2/activityLevel/regular_active.json";
import very_active from "../../assets/img/V2/activityLevel/very_active.json";

const activityLevelOptionsV2: CustomOption[] = [
  {
    id: 1,
    value: 0,
    payload: {
      title: "Not Active",
      subTitle: "Minimal activity level, mostly sedentary.",
      img: not_active,
    },
  },
  {
    id: 2,
    value: 25,
    payload: {
      title: "Lightly Active",
      subTitle: "Engage in light activities, but mainly sedentary.",
      img: lightly_active,
    },
  },
  {
    id: 3,
    value: 50,
    payload: {
      title: "Regular Active",
      subTitle: "Regular, moderate exercise like sports and workouts a few times a week.",
      img: regular_active,
    },
  },
  {
    id: 4,
    value: 100,
    payload: {
      title: "Very Active",
      subTitle: "Highly active, involving intense workouts or physically demanding work.",
      img: very_active,
    },
  },
];

export default activityLevelOptionsV2;
