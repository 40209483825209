const enum AGE_RANGE {
  _18_24 = "18-24",
  _25_29 = "25-29",
  _30_44 = "30-44",
  _45_49 = "45-49",
  _50_54 = "50-54",
  _54_58 = "54-58",
  _58_PLUS = "58+",
}

export default AGE_RANGE;
