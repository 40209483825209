import { useEffect, useState, lazy } from "react";
import getSymbolFromCurrency from "currency-symbol-map";
import { Box } from "@mui/material";
import { StripePrice } from "../../../../../model/Stripe";
import { useGetPageInfo, usePrices } from "../../../../../hooks";
import { calculateWeeklyPrice, capitalizeFirstLetter, getWeekCount } from "../../../../../utils/helpers";
import * as S from "./style";

const CheckIcon = lazy(() => import("../../../../../components/Base/Svg/Icons/CheckV2/CheckIcon"));

type PricesProps = {
  // prices: StripePrice[];
  onPriceSelected: (price: StripePrice) => void;
  loading: boolean;
  disabled: boolean;
};

const Prices: React.FC<PricesProps> = ({ onPriceSelected, loading, disabled }) => {
  const { currentCohort } = useGetPageInfo();
  const { prices, pricesFetch, loading: pricesLoading } = usePrices(currentCohort);

  const [selectedPrice, setSelectedPrice] = useState<StripePrice>();

  const actives = prices.filter((i) => i.active);
  const weeklyPrices = actives.sort((a, b) => {
    const weeklyA = a.unit_amount / (a.recurring.interval_count * getWeekCount(a.recurring.interval));
    const weeklyB = b.unit_amount / (b.recurring.interval_count * getWeekCount(b.recurring.interval));
    return weeklyA - weeklyB;
  });
  const bestPrice = weeklyPrices[0]; // cheapest weekly

  useEffect(() => {
    setSelectedPrice(bestPrice);
  }, [bestPrice]);

  return (
    <S.Packages>
      {prices.length > 0 ? (
        <>
          <S.Header>
            <S.HeaderBold>FITNESS</S.HeaderBold>
            <S.HeaderThin>by Dancing</S.HeaderThin>
          </S.Header>
          <S.Card>
            {prices
              .filter((plan) => plan.active)
              .map((plan) => (
                <S.PackageCard
                  key={plan.id}
                  selected={selectedPrice?.id === plan.id}
                  onClick={() => {
                    selectedPrice?.id === plan.id ? setSelectedPrice(undefined) : setSelectedPrice(plan);
                  }}
                >
                  {bestPrice.id === plan.id && (
                    <S.BestOffer>
                      <S.Fire>🔥</S.Fire>
                      <S.OfferText>MOST POPULAR</S.OfferText>
                    </S.BestOffer>
                  )}
                  <S.Period>
                    <CheckIcon checked={selectedPrice?.id === plan.id} color="#FFFFFF33" checkedColor="#E5FA40" />
                    <S.Title>{capitalizeFirstLetter(plan.recurring.interval)}ly</S.Title>
                  </S.Period>
                  <Box>
                    <S.Price>
                      {/* <S.TimeRange>
                      {plan.recurring.interval_count} {plan.recurring.interval}
                    </S.TimeRange> */}
                      <S.PriceText>
                        {getSymbolFromCurrency(plan.currency)}
                        {calculateWeeklyPrice(plan.unit_amount, plan.recurring.interval)} /
                      </S.PriceText>
                      <S.TimeRange>&nbsp;week</S.TimeRange>
                    </S.Price>
                  </Box>
                </S.PackageCard>
              ))}
          </S.Card>
          <S.Bottom>
            By continuing, you agree that if you do not cancel at least 24 hours before the end of every period, you will be charged until you cancel in the settings. Learn more
            about cancellation and refund policy in the{" "}
            <S.BottomLink target="_blank" rel="noopener noreferrer" href="https://fitnessbydance.com/subs-terms" underline="always">
              Subscription Terms.
            </S.BottomLink>
          </S.Bottom>
          {/* <S.ButtonOverlay /> */}
          <S.CustomButtonContainer>
            <S.CustomButton
              text="Get My Plan"
              loading={loading}
              disabled={prices.length === 0 || disabled}
              onClick={() => {
                if (!selectedPrice) {
                  return;
                }
                selectedPrice && onPriceSelected(selectedPrice);
              }}
            />
          </S.CustomButtonContainer>
        </>
      ) : (
        // <Box sx={{ padding: "1rem", textAlign: "center" }}>Prices could not be reached.</Box>
        <S.CustomButtonContainer>
          <S.CustomButton text="Try Again" loadingColor="primary" loading={pricesLoading} disabled={pricesLoading || prices.length > 0} onClick={() => pricesFetch()} />
        </S.CustomButtonContainer>
      )}
    </S.Packages>
  );
};

export default Prices;
