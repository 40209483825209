import { Box, Button, styled } from "@mui/material";

export const Main = styled(Box)(() => ({
  width: "100%",
  height: "calc(100% - 5rem)",
  overflow: "hidden",
  touchAction: "none",
  position: "relative",
  WebkitOverflowScrolling: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "Space Grotesk",
  userSelect: "none",
  "@media (max-width: 768px)": {
    height: "calc(100% - 2.5rem)",
  },
}));

export const PageContent = styled(Box)(({ height }: { height: number }) => ({
  position: "relative",
  width: `${height / 2}px`,
  height: "calc(100% - 1rem)",
  margin: "0.5rem 0",
  "@media (max-width: 768px)": {
    width: "100%",
    height: "100%",
    margin: "0",
  },
}));

export const Content = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "calc(100% - 13rem)",
  marginTop: "1rem",
  position: "relative",
  "@media (max-width: 768px)": {
    marginTop: "0rem",
  },
}));

export const HeaderContent = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  padding: "1rem 0.5rem",
  margin: "0.5rem 0 1rem 0",
}));

export const PartContent = styled(Box)(() => ({
  position: "absolute",
  left: 0,
  right: 0,
  margin: "0 auto",
  width: "16rem",
}));

export const Title = styled(Box)(() => ({
  margin: "0.5rem 0 1rem 0",
  textAlign: "center",
  fontWeight: "600",
  fontSize: "1.25rem",
  color: "#fff",
  userSelect: "none",
  "@media (max-width: 768px)": {
    marginTop: "1rem",
  },
}));

export const ColoredText = styled("span")(() => ({
  color: "#E5FA40",
  userSelect: "none",
}));

export const SubTitle = styled(Box)(() => ({
  textAlign: "center",
  fontSize: "0.875rem",
  color: "#B4B4B4",
  marginBottom: "2.5rem",
  userSelect: "none",
  "@media (max-width: 768px)": {
    marginBottom: "2rem",
  },
}));

export const StyledButton = styled(Button)(() => ({
  marginTop: "0 !important",
  minWidth: "1rem",
  padding: "0 1rem",
  margin: "0",
  textTransform: "unset",
  color: "#8E98B4",
  fontSize: "1rem",
  "&:hover": {
    backgroundColor: "unset !important",
  },
}));
export const RulerContent = styled(Box)(({ heightvalue }: { heightvalue: number }) => ({
  display: "flex",
  // flexDirection: "column",
  // width: "calc(100% - 10rem)",
  width: "100%",
  margin: "0 auto",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "1rem",
  position: "relative",
  // position: "absolute",
  // bottom: `${heightvalue / 6}px`,
}));

export const WeightContent = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  alignItems: "baseline",
  justifyContent: "center",
  margin: "2.5rem 0",
  gap: "0.25rem",

  "@media (max-width: 768px)": {
    margin: "2rem 0",
  },

  "@media (max-height: 700px) and (max-width: 768px)": {
    margin: "1.5rem 0",
  },

  "@media (max-height: 780px) and (max-width: 768px)": {
    margin: "0",
  },
}));

export const WeightText = styled(Box)(() => ({
  fontSize: "4rem",
  color: "#fff",
  fontWeight: "600",
}));

export const WeightType = styled(Box)(() => ({
  fontSize: "1rem",
  color: "#8E93B4",
  fontWeight: "600",
}));

export const BMIContent = styled(Box)(({ width }: { width: number }) => ({
  position: "fixed",
  bottom: "5rem",
  fontSize: "0.75rem",
  padding: "1rem",
  background: "#1C255E",
  borderRadius: "1rem",
  lineHeight: "1.25rem",
  width: `calc(${width}px - 2rem)`,
  left: "0",
  right: "0",
  margin: "0 auto",

  "@media (max-width: 768px)": {
    width: "calc(100% - 3rem)",
    padding: "1rem 0.5rem",
  },

  "@media (max-height: 780px) and (max-width: 768px)": {
    padding: "0.25rem",
  },
}));

export const ThumbsUp = styled(Box)(() => ({
  fontSize: "1.5rem",
  marginRight: "0.75rem",
  "@media (max-width: 768px)": {
    marginRight: "0.25rem",
  },
}));

export const GoalHeader = styled(Box)(() => ({
  fontSize: "1rem",
  fontWeight: "600",
  marginBottom: "0.25rem",
  color: "#E5FA40",
}));

export const GoalText = styled(Box)(() => ({
  fontSize: "0.75rem",
  color: "#fff",
  fontWeight: "700",
  marginBottom: "0.25rem",
}));

export const GoalBottom = styled(Box)(() => ({
  fontSize: "0.75rem",
  color: "#fff",
  "@media (max-height: 780px) and (max-width: 768px)": {
    fontSize: "0.675rem",
  },
}));

export const CustomButtonContainer = styled(Box)(() => ({
  position: "absolute",
  bottom: "1rem",
  left: "1rem",
  right: "1rem",
  width: "calc(100% - 2rem)",
  height: "3rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "@media (max-width: 768px)": {
    left: "1rem",
    right: "1rem",
    width: "calc(100% - 2rem)",
  },
}));

export const CustomButton = styled(Button)(() => ({
  width: "25rem",
  // margin: "2rem 0",
  borderRadius: "2rem",
  height: "3rem",
  background: "#E5FA40",
  color: "#232D49",
  textTransform: "none",
  fontWeight: 500,
  fontFamily: "Space Grotesk",
  "&:disabled": {
    background: "#899628 !important",
    color: "#232D49 !important",
  },
  "&:hover": {
    background: "#E5FA40 !important",
  },
}));
