import { memo } from "react";
import { useNextStep, useWindowSize } from "../../../hooks";
import { TPage } from "../../../model";
import members from "../../../assets/img/welcome/members.webp";
import { Box } from "@mui/material";
import * as S from "./style";

const Welcome: React.FC<TPage> = memo(({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });
  const { height } = useWindowSize();
  return (
    <S.Main height={height}>
      <S.HeaderText>
        <Box>Here is what our</Box>
        <Box>members are saying</Box>
      </S.HeaderText>
      <S.PercentageText>86.4%</S.PercentageText>
      <S.ContentText>
        <Box>of users say Fitness By Dancing is the most</Box>
        <Box>enjoyable way to lose weight.</Box>
      </S.ContentText>
      <S.ContentImage src={members} alt="" />
      <S.BottomText>Let's say goodbye to boring workouts!</S.BottomText>
      <S.CustomButtonContainer>
        <S.CustomButton size="large" variant="contained" onClick={() => handleContinue()}>
          Continue
        </S.CustomButton>
      </S.CustomButtonContainer>
    </S.Main>
  );
});

export default Welcome;
