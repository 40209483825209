import { WEIGHT_TYPE } from "../enum";
import kgToLb from "./kgToLb";
import lbToKg from "./lbToKg";

const calculateTargetWeight = (currentWeightType?: string, targetWeightValue?: string | number, tagetWeightType?: string) => {
  if (currentWeightType === WEIGHT_TYPE.KG) {
    if (tagetWeightType === WEIGHT_TYPE.KG) {
      return targetWeightValue;
    } else {
      return lbToKg(Number(targetWeightValue));
    }
  } else {
    if (tagetWeightType === WEIGHT_TYPE.LB) {
      return targetWeightValue;
    } else {
      return kgToLb(Number(targetWeightValue));
    }
  }
};

export default calculateTargetWeight;
