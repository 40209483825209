import { createRef, lazy, useEffect, useState } from "react";
import { captureException } from "../../../services/sentry.service";
import { Box } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import { firebaseService } from "../../../services/firebase.service";
import { getUserId } from "../../../services/user.service";
import stripeService from "../../../services/stripe.service";
import { fbq } from "../../../services/metapixel.service";
import { useAlertContext } from "../../../context";
import { RetrievePaymentIntent } from "../../../model/Stripe";
import { useAutoScrollDown, useGetPageInfo, useGoBack, useWindowSize } from "../../../hooks";
import { storage } from "../../../utils";
import { Stripe, TPage } from "../../../model";
import * as S from "./style";

const Animation = lazy(() => import("./Animation/Animation"));
const Prices = lazy(() => import("./Prices/Prices"));
const DancePlan = lazy(() => import("./DancePlan/DancePlan"));
const Checkout = lazy(() => import("./Checkout/Checkout"));
const PaymentSuccess = lazy(() => import("./PaymentSuccess/PaymentSuccess"));
const PaymentFailed = lazy(() => import("./PaymentFailed/PaymentFailed"));

const Payment: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const handleGoBack = useGoBack({ pageId, nextPagePath });
  const { currentCohort } = useGetPageInfo();

  const { height } = useWindowSize();

  const { showAlert } = useAlertContext();

  const checkoutRef = createRef<HTMLDivElement>();
  useAutoScrollDown(checkoutRef, 5000);

  const [loading, setLoading] = useState<boolean>(true);
  const [show, setShow] = useState<boolean>(false);
  const [plan, setPlan] = useState<Stripe.StripePrice>();
  const [clientSecret, setClientSecret] = useState("");
  const [paymentDisabled, setPaymentDisabled] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentFailed, setPaymentFailed] = useState(false);

  useEffect(() => {
    const paymentIntentId = new URLSearchParams(window.location.search).get("payment_intent");

    if (!paymentIntentId) {
      setLoading(false);
      return;
    }

    // setShow(true);
    // setPaymentDisabled(true);
    const retrievePaymentIntent: RetrievePaymentIntent = {
      configId: window.config.CONFIG_ID,
      id: paymentIntentId,
    };

    stripeService.retrievePaymentIntent(retrievePaymentIntent).then((paymentIntent) => {
      setLoading(false);
      if (!paymentIntent) {
        return;
      }

      switch (paymentIntent.status) {
        case "succeeded":
          onPaymentSuccess(paymentIntent);
          break;
        case "processing":
          break;
        case "requires_payment_method":
          onPaymentFailed();
          break;
        default:
          onPaymentFailed();
          break;
      }
    });
  }, []);

  const onPriceSelected = async (price: Stripe.StripePrice) => {
    firebaseService.logFirebaseEvent("getmyplan_button_clicked");
    fbq("trackCustom", "getmyplan_button_clicked");
    fbq("track", "AddToCart");

    setLoading(true);
    setPlan(price);
    getUserId()
      .then((userId) =>
        stripeService.payment({
          configId: window.config.CONFIG_ID,
          customerId: storage.get("customerId"),
          priceId: price.id,
          subscriptionId: storage.get("subscriptionId"),
          userId: userId,
          cohortId: currentCohort,
          email: storage.get("email"),
          initialUrl: storage.get("initialUrl"),
        })
      )
      .then(({ user, subscription, customer }) => {
        storage.set("subscriptionId", subscription.id);
        storage.set("customerId", customer.id);
        setClientSecret(subscription.latest_invoice.payment_intent.client_secret);
        setLoading(false);
        setShow(true);
      })
      .catch((err) => {
        showAlert("Payment failed");
        captureException(err);
        setLoading(false);
      });
  };

  const onTryAgain = () => {
    setClientSecret("");
    setPaymentDisabled(false);
    setPaymentFailed(false);
    setShow(true);
  };

  const onPaymentSuccess = (paymentIntent: any) => {
    setShow(true);
    setPaymentSuccess(true);
    setPaymentDisabled(true);
    firebaseService.logFirebaseEvent("checkout_payment_succeeded");
    fbq("trackCustom", "checkout_payment_succeeded");
    fbq("track", "Purchase", {
      content_ids: [paymentIntent.id],
      // eventref: 'fb_oea',
      currency: paymentIntent.currency,
      num_items: 1,
      value: paymentIntent.amount / 100.0,
    });
  };

  const onPaymentFailed = () => {
    firebaseService.logFirebaseEvent("checkout_payment_failed");
    fbq("trackCustom", "checkout_payment_failed");
    setPaymentFailed(true);
    setPaymentDisabled(true);
  };

  return (
    <S.Main>
      <S.PageContent height={height}>
        <S.Header>
          <S.StyledButton onClick={handleGoBack}>
            <ArrowBackIos />
          </S.StyledButton>
          <S.HeaderTitle>
            <Box>Your Personalized Plan</Box>
            <Box>Is Ready!</Box>
          </S.HeaderTitle>
        </S.Header>
        <S.Content>
          <Animation />
          <DancePlan />
          <Prices onPriceSelected={onPriceSelected} loading={loading} disabled={paymentDisabled} />
          {clientSecret && !loading && show && (
            <Box ref={checkoutRef}>
              <Checkout
                backdropClick={() => {
                  setShow(false);
                  setClientSecret("");
                  setPaymentDisabled(false);
                }}
                plan={plan}
                clientSecret={clientSecret}
                onPaymentFailed={onPaymentFailed}
                show={show}
              />
            </Box>
          )}
        </S.Content>
      </S.PageContent>
      <PaymentSuccess /* backdropClick={() => setPaymentSuccess(false)} */ show={paymentSuccess} />
      <PaymentFailed /* backdropClick={() => setPaymentFailed(false)} */ show={paymentFailed} onTryAgain={onTryAgain} />
    </S.Main>
  );
};

export default Payment;
