import { createRef, lazy, useCallback, useEffect, useState } from "react";
import { ArrowBackIos } from "@mui/icons-material";
import { useAutoScrollDown, useNextStep, useWindowSize, useIsMobile, useGoBack } from "../../../hooks";
import { TPage } from "../../../model";
import profile from "../../../assets/img/preparingYourPlan/profile.webp";
import { Box, Rating } from "@mui/material";
import * as S from "./style";
import { firebaseService } from "../../../services/firebase.service";

const MusicRhythmModal = lazy(() => import("../../../components/MusicRhythm/MusicRhythm"));
const CalorieConsumptionRecordModal = lazy(() => import("../../../components/CalorieConsumptionRecord/CalorieConsumptionRecord"));
const TimerProgressBar = lazy(() => import("../../../components/TimerProgressBar/TimerProgressBar"));

const PreparingYourPlan: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });
  const handleGoBack = useGoBack({ pageId, nextPagePath });

  const { height } = useWindowSize();
  const isMobile = useIsMobile();

  const [showMusicRhythmModal, setShowMusicRhythmModal] = useState<boolean>(false);
  const [showCalorieConsumptionRecordModal, setShowCalorieConsumptionRecordModal] = useState<boolean>(false);

  const [progress1, setProgress1] = useState({ value: 100, duration: 5, done: false });
  const [progress2, setProgress2] = useState({ value: 0, duration: 0, done: false });
  const [progress3, setProgress3] = useState({ value: 0, duration: 0, done: false });
  const [progress4, setProgress4] = useState({ value: 0, duration: 0, done: false });

  const myRef = createRef<HTMLDivElement>();
  useAutoScrollDown(myRef, 730);

  useEffect(() => {
    const timer = setTimeout(() => {
      setProgress1((prev) => ({ ...prev, done: true }));
      setProgress2((prev) => ({ ...prev, done: true, value: 80, duration: 4 }));
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMusicRhythmModal(true);
    }, 9500);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const step1 = useCallback(() => {
    setShowMusicRhythmModal(false);
    setProgress2((prev) => ({ ...prev, value: 100, duration: 1, done: false }));
    const timer = setTimeout(() => {
      setProgress2((prev) => ({ ...prev, done: true }));
      setProgress3((prev) => ({ ...prev, done: true, value: 100, duration: 5 }));
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const step2 = useCallback(() => {
    const timer = setTimeout(() => {
      setProgress3((prev) => ({ ...prev, done: true }));
      setProgress4((prev) => ({ ...prev, done: true, value: 60, duration: 3 }));
    }, 6000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const step3 = useCallback(() => {
    const timer = setTimeout(() => {
      setShowCalorieConsumptionRecordModal(true);
    }, 9500);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const step4 = useCallback(() => {
    setShowCalorieConsumptionRecordModal(false);
    setProgress4((prev) => ({ ...prev, value: 100, duration: 2, done: true }));
    const timer = setTimeout(() => {
      handleContinue();
    }, 2500);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const musicRhythmOnClick = (answer: string) => {
    firebaseService.logFirebaseEvent(`need_to_match_the_music_rhythm_automatically_${answer}_clicked`);
    step1();
    step2();
    step3();
  };

  const calorieConsumptionRecordOnClick = (answer: string) => {
    firebaseService.logFirebaseEvent(`need_real_time_calorie_consumption_record_${answer}_clicked`);
    step4();
  };

  return (
    <S.Main>
      <S.PageContent height={height}>
        <S.Header>
          <S.StyledButton onClick={handleGoBack} disableRipple>
            <ArrowBackIos />
          </S.StyledButton>
          <S.Title>Preparing your plan</S.Title>
        </S.Header>
        <S.Content>
          <S.Progress>
            <S.ProgressTitle>Current body stiation</S.ProgressTitle>
            <TimerProgressBar value={progress1.value} duration={progress1.duration} />
          </S.Progress>
          <S.Progress>
            <S.ProgressTitle>Dance level</S.ProgressTitle>
            <TimerProgressBar value={progress2.value} duration={progress2.duration} />
          </S.Progress>
          <S.Progress>
            <S.ProgressTitle>Dance preference</S.ProgressTitle>
            <TimerProgressBar value={progress3.value} duration={progress3.duration} />
          </S.Progress>
          <S.Progress>
            <S.ProgressTitle>Pratictice rhythm</S.ProgressTitle>
            <TimerProgressBar value={progress4.value} duration={progress4.duration} />
          </S.Progress>
        </S.Content>

        <S.Reviews>
          <S.ReviewTitle>Trusted by many users</S.ReviewTitle>
          <S.ReviewCard>
            <S.Image src={profile} alt="" />
            <Box>
              <S.CardHeader>
                <S.ProfileName>Angelica/ -8 kg</S.ProfileName>
                <Rating defaultValue={5} size="small" readOnly />
              </S.CardHeader>
              <S.ReviewText>
                "This app is really great! It helped me keep track of my daily calorie intake and exercise routines. I have reached my target weight using the app and feel more
                energetic and healthy."
              </S.ReviewText>
            </Box>
          </S.ReviewCard>
        </S.Reviews>
        <MusicRhythmModal
          width={isMobile ? 300 : height / 2}
          show={showMusicRhythmModal}
          backdropClick={() => {}}
          noClick={() => musicRhythmOnClick("No")}
          yesClick={() => musicRhythmOnClick("Yes")}
        />
        <CalorieConsumptionRecordModal
          width={isMobile ? 300 : height / 2}
          show={showCalorieConsumptionRecordModal}
          backdropClick={() => {}}
          noClick={() => calorieConsumptionRecordOnClick("No")}
          yesClick={() => calorieConsumptionRecordOnClick("Yes")}
        />
      </S.PageContent>
    </S.Main>
  );
};

export default PreparingYourPlan;
