import { createRef, lazy, useState } from "react";
import { Box } from "@mui/material";
import { useAutoScrollDown, useClickAnimation, useGoBack, useNextStep, useWindowSize } from "../../../hooks";
import { TPage } from "../../../model";
import { whichAreasImproveOptions } from "../../../utils/constants";
import { WHICH_AREAS_IMPROVE } from "../../../utils/enum";
import img from "../../../assets/img/whichAreasImprove/img.webp";
import LineFullBody from "./img/LineFullBody";
import LineArms from "./img/LineArms";
import LineBelly from "./img/LineBelly";
import LineButt from "./img/LineButt";
import LineLegs from "./img/LineLegs";
import { useAnswersContext } from "../../../context";
import * as S from "./style";

const CustomHeader = lazy(() => import("../../../components/CustomHeader/CustomHeader"));
const CustomButton = lazy(() => import("../../../components/Base/CustomButton/CustomButton"));
const CustomStepper = lazy(() => import("../../../components/CustomStepper/CustomStepper"));

const WhichAreasImprove: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });
  const handleGoBack = useGoBack({ pageId, nextPagePath });
  const { height } = useWindowSize();

  const { answers } = useAnswersContext();
  const prevWhichAreasImprove = answers["whichAreasImprove"] as WHICH_AREAS_IMPROVE[];

  const buttonRef = createRef<HTMLButtonElement>();
  const active = useClickAnimation(buttonRef);

  const [selectedOptions, setSelectedOptions] = useState<WHICH_AREAS_IMPROVE[]>(prevWhichAreasImprove || []);

  const myRef = createRef<HTMLDivElement>();
  useAutoScrollDown(myRef);

  const onClick = () => {
    if (selectedOptions.length > 0) handleContinue(selectedOptions);
  };

  return (
    <S.Main>
      <S.PageContent width={height / 2}>
        <CustomHeader part={1} header="GOAL" />
        <CustomStepper step={0} onBack={handleGoBack} />
        <S.Content>
          <S.Title>
            <Box>Which area(s) woulds</Box>
            <Box>you like to improve?</Box>
          </S.Title>
          <S.BodyArea>
            <S.OptionsContent gap="0.5rem" ref={myRef}>
              {whichAreasImproveOptions.map((opt) => {
                return (
                  <S.Option
                    key={opt}
                    shine={active ? 1 : 0}
                    selected={selectedOptions.length > 0 ? true : false}
                    dark={selectedOptions.includes(opt) ? 1 : 0}
                    onClick={() => {
                      let newSelectedOptions = [...selectedOptions];
                      if (opt === WHICH_AREAS_IMPROVE.FULL_BODY) {
                        if (newSelectedOptions.includes(WHICH_AREAS_IMPROVE.FULL_BODY)) {
                          setSelectedOptions([]);
                        } else {
                          setSelectedOptions(whichAreasImproveOptions);
                        }
                      } else {
                        if (newSelectedOptions.includes(opt)) {
                          if (newSelectedOptions.includes(WHICH_AREAS_IMPROVE.FULL_BODY)) {
                            const index = newSelectedOptions.findIndex((i) => i === WHICH_AREAS_IMPROVE.FULL_BODY);
                            newSelectedOptions.splice(index, 1);
                          }
                          const index = newSelectedOptions.findIndex((i) => i === opt);
                          newSelectedOptions.splice(index, 1);
                        } else {
                          newSelectedOptions.push(opt);
                          const newWhichAreasImproveOptions = [...whichAreasImproveOptions];
                          const others = newWhichAreasImproveOptions.slice(1);
                          const newOthers = [...others];
                          const newArray = newSelectedOptions.filter((item) => item !== opt);
                          if (newOthers.length === newArray.length + 1) {
                            newSelectedOptions.push(WHICH_AREAS_IMPROVE.FULL_BODY);
                          }
                        }
                        setSelectedOptions(newSelectedOptions);
                      }
                    }}
                  >
                    {opt}
                  </S.Option>
                );
              })}
            </S.OptionsContent>
            <S.Lines>
              <S.LineFullBodyPassive>
                <LineFullBody fill={selectedOptions.includes(WHICH_AREAS_IMPROVE.FULL_BODY) ? "#3AC141" : "#CDD4ED"} />
              </S.LineFullBodyPassive>
              <S.LineArmsPassive>
                <LineArms fill={selectedOptions.includes(WHICH_AREAS_IMPROVE.ARMS) ? "#3AC141" : "#CDD4ED"} />
              </S.LineArmsPassive>
              <S.LineBellyPassive>
                <LineBelly fill={selectedOptions.includes(WHICH_AREAS_IMPROVE.BELLY) ? "#3AC141" : "#CDD4ED"} />
              </S.LineBellyPassive>
              <S.LineButtPassive>
                <LineButt fill={selectedOptions.includes(WHICH_AREAS_IMPROVE.BUTT) ? "#3AC141" : "#CDD4ED"} />
              </S.LineButtPassive>
              <S.LineLegsPassive>
                <LineLegs fill={selectedOptions.includes(WHICH_AREAS_IMPROVE.LEGS) ? "#3AC141" : "#CDD4ED"} />
              </S.LineLegsPassive>
            </S.Lines>
            <Box>
              <S.Image src={img} alt="" />
            </Box>
          </S.BodyArea>
        </S.Content>
        <CustomButton ref={buttonRef} width={height / 2} disabled={selectedOptions.length === 0} text="Continue" onClick={onClick} />
      </S.PageContent>
    </S.Main>
  );
};

export default WhichAreasImprove;
