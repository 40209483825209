import { createRef, memo } from "react";
import { useAutoScrollDown, useNextStep, useWindowSize } from "../../../hooks";
import { TPage } from "../../../model";
import backgrounImg from "../../../assets/img/yourAccountIsReady/frame.webp";
import * as S from "./style";

const AccountIsReady: React.FC<TPage> = memo(({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });
  const { height } = useWindowSize();

  const myRef = createRef<HTMLDivElement>();
  useAutoScrollDown(myRef, 730);

  return (
    <S.Main>
      <S.PageContent height={height}>
        <S.Image src={backgrounImg} alt="" />
        {/* <S.Content /> */}
      </S.PageContent>
      <S.CustomButton height={height} onClick={() => handleContinue()}>
        Go to Personal Plan
      </S.CustomButton>
    </S.Main>
  );
});

export default AccountIsReady;
