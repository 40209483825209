import { Box, styled } from "@mui/material";

export const Main = styled(Box)(() => ({
  width: "100%",
  height: "calc(100% - 5rem)",
  overflow: "hidden",
  // overflowX: "hidden",
  position: "relative",
  // WebkitOverflowScrolling: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  touchAction: "none",
  "@media (max-width: 768px)": {
    height: "calc(100% - 2.5rem)",
  },
}));

export const PageContent = styled(Box)(({ height }: { height: number }) => ({
  position: "relative",
  width: `${height / 2}px`,
  // width: "50%",
  height: "100%",
  "@media (max-width: 768px)": {
    width: "100%",
  },
}));

export const Content = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  marginTop: "1rem",
  position: "relative",
  "@media (max-width: 768px)": {
    marginTop: "0rem",
  },
}));

export const Title = styled(Box)(() => ({
  margin: "1rem 0",
  textAlign: "center",
  fontWeight: "600",
  fontSize: "1.25rem",
}));

export const RulerContent = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "end",
  position: "absolute",
  // height: "calc(100% - 8rem)",
  top: "2rem",

  "@media (max-width: 768px)": {
    top: "-1.5rem",
    left: "0.5rem",
  },
}));

export const ArrowUpButton = styled(Box)(() => ({
  border: "0.0625rem solid #9CE0A0",
  color: "#3AC141",
  background: "#fff",
  borderRadius: "50%",
  width: "2rem",
  height: "2rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: "-2rem",
  left: "-0.5rem",
  cursor: "pointer",
  zIndex: 501,
  "@media (max-width: 768px)": {
    display: "none",
  },
}));

export const ArrowDownButton = styled(Box)(() => ({
  border: "0.0625rem solid #9CE0A0",
  color: "#3AC141",
  background: "#fff",
  borderRadius: "50%",
  width: "2rem",
  height: "2rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  bottom: "-2rem",
  left: "-0.5rem",
  cursor: "pointer",
  zIndex: 501,
  "@media (max-width: 768px)": {
    display: "none",
  },
}));

export const HeightContent = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  alignItems: "baseline",
  justifyContent: "center",
  marginRight: "100px",
  transform: "translateY(-40%)",
  "@media (min-height: 700px)": {
    transform: "translateY(120%)",
  },
}));

export const HeightText = styled(Box)(() => ({
  fontSize: "2.5rem",
  color: "#232D49",
  fontWeight: "600",
}));

export const HeightType = styled(Box)(() => ({
  fontSize: "1rem",
  color: "#232D49",
  fontWeight: "600",
}));
