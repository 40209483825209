import React from "react";
import Svg from "../../../../components/Base/Svg/Svg";

type LineLegsProps = {
  fill?: string;
  color?: string;
  size?: string;
};

const LineLegs: React.FC<LineLegsProps> = ({ fill, color, size = "20px" }) => (
  <Svg fill="none" color={color} size={size} path="" width="13rem" height="6rem" viewBox="0 0 235 95">
    <path d="M1.5 94H150.965L229.315 5.97253" stroke={fill} strokeWidth="2" strokeLinecap="round" />
    <circle cx="230" cy="5" r="3.75" fill={fill} stroke="white" strokeWidth="1.5" />
  </Svg>
);

export default LineLegs;
