import { createRef, lazy, useState } from "react";
import { Box } from "@mui/material";
import { useAutoScrollDown, useClickAnimation, useGoBack, useNextStep, useWindowSize } from "../../../hooks";
import { TPage } from "../../../model";
import { DANCE_STYLE } from "../../../utils/enum";
import { danceStyleOptions } from "../../../utils/constants";
import { useAnswersContext } from "../../../context";
import * as S from "./style";

const CustomHeader = lazy(() => import("../../../components/CustomHeader/CustomHeader"));
const CustomButton = lazy(() => import("../../../components/Base/CustomButton/CustomButton"));
const CustomStepper = lazy(() => import("../../../components/CustomStepper/CustomStepper"));

const FavoriteDanceStyle: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });
  const handleGoBack = useGoBack({ pageId, nextPagePath });

  const { height } = useWindowSize();

  const { answers } = useAnswersContext();
  const prevFavoriteDanceStyle = answers["favoriteDanceStyle"] as DANCE_STYLE[];

  const [selectedOptions, setSelectedOptions] = useState<DANCE_STYLE[]>(prevFavoriteDanceStyle || []);

  const myRef = createRef<HTMLDivElement>();
  useAutoScrollDown(myRef);

  const buttonRef = createRef<HTMLButtonElement>();
  const active = useClickAnimation(buttonRef);

  const onClick = () => {
    if (selectedOptions.length > 0) handleContinue(selectedOptions);
  };

  return (
    <S.Main>
      <S.PageContent height={height}>
        <CustomHeader part={3} header="ABOUT YOU" />
        <CustomStepper step={2} onBack={handleGoBack} />
        <S.Content ref={myRef}>
          <S.Title>
            <Box>Select your favorite</Box>
            <Box>dance style(s)</Box>
          </S.Title>
          <S.OptionsContent gap="0.5rem">
            {danceStyleOptions.map((danceStyle) => {
              return (
                <S.Option
                  key={danceStyle}
                  shine={active ? 1 : 0}
                  selected={selectedOptions.length > 0 ? true : false}
                  dark={selectedOptions.includes(danceStyle) ? 1 : 0}
                  onClick={() => {
                    const newSelectedOptions = [...selectedOptions];
                    if (danceStyle === DANCE_STYLE.NON_PREFERENCE) {
                      if (newSelectedOptions.includes(DANCE_STYLE.NON_PREFERENCE)) {
                        setSelectedOptions([]);
                      } else {
                        setSelectedOptions([DANCE_STYLE.NON_PREFERENCE]);
                      }
                    } else {
                      if (newSelectedOptions.includes(DANCE_STYLE.NON_PREFERENCE)) {
                        const index = newSelectedOptions.findIndex((i) => i === DANCE_STYLE.NON_PREFERENCE);
                        newSelectedOptions.splice(index, 1);
                      }
                      if (newSelectedOptions.includes(danceStyle)) {
                        const index = newSelectedOptions.findIndex((i) => i === danceStyle);
                        newSelectedOptions.splice(index, 1);
                      } else {
                        newSelectedOptions.push(danceStyle);
                      }

                      setSelectedOptions(newSelectedOptions);
                    }
                  }}
                >
                  {danceStyle}
                </S.Option>
              );
            })}
          </S.OptionsContent>
        </S.Content>
        <CustomButton ref={buttonRef} width={height / 2} disabled={selectedOptions.length === 0} text="Continue" onClick={onClick} />
      </S.PageContent>
    </S.Main>
  );
};

export default FavoriteDanceStyle;
