export const SET_ANSWER = "SET_ANSWER";

export type Answers = {
  answers: { [p: string]: string[] | null };
};

export type SetAnswersAction = {
  type: typeof SET_ANSWER;
  payload: {
    pageId: string;
    answer: string[] | null;
  };
};

export interface IAnswersContext {
  answers: {
    [p: string]: string[] | null;
  };
  setAnswersContext: (pageId: string, answer: string | string[] | null) => void;
}
