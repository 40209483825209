import { CustomOption } from "../../model";
import beginner from "../../assets/img/V2/fitnessLevel/beginner.json";
import intermediate from "../../assets/img/V2/fitnessLevel/intermediate.json";
import advanced from "../../assets/img/V2/fitnessLevel/advanced.json";

const fitnessLevelOptionsV2: CustomOption[] = [
  {
    id: 1,
    value: 0,
    payload: {
      title: "Beginner",
      subTitle: "Limited exercise experience, basic fitness level.",
      img: beginner,
    },
  },
  {
    id: 2,
    value: 50,
    payload: {
      title: "Intermediate",
      subTitle: "Moderate exercise experience, improving fitness level.",
      img: intermediate,
    },
  },
  {
    id: 3,
    value: 100,
    payload: {
      title: "Advanced",
      subTitle: "High exercise experience, peak fitness level.",
      img: advanced,
    },
  },
];

export default fitnessLevelOptionsV2;
