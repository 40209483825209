import { lazy, useEffect, useState } from "react";
import { setConfigList } from "./init";
import { initializeFirebase } from "../../services/firebase.service";
import { initializeStripe } from "../../services/stripe.service";
import { initializeSentry } from "../../services/sentry.service";
import { initializeMetaPixel } from "../../services/metapixel.service";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";

const App = lazy(() => import("../App"));
const AppLoading = lazy(() => import("../../components/AppLoading/AppLoading"));

const AppWrapper = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setConfigList()
      .then(() =>
        Promise.all([
          initializeFirebase(window.config.CONFIGS.firebaseConfig),
          initializeStripe(window.config.CONFIGS.stripePublishableKey),
          initializeSentry(window.config.CONFIGS.sentryDsn),
          initializeMetaPixel(window.config.CONFIGS.facebookPixelId),
        ])
      )
      .finally(() => setLoading(false));
  }, []);
  return <ErrorBoundary>{loading ? <AppLoading /> : <App />}</ErrorBoundary>;
};

export default AppWrapper;
