import { Box, Button, TextField, styled } from "@mui/material";
import LoadingButton from "../../../components/Base/LoadingButton/LoadingButton";

export const Main = styled(Box)(({ heightvalue }: { heightvalue: number }) => ({
  position: "relative",
  width: `${heightvalue / 2}px`,
  height: "100%",
  display: "flex",
  flexDirection: "column",
  marginTop: "2rem",
  overflow: "hidden",
  touchAction: "none",
  fontFamily: "Space Grotesk",
  userSelect: "none",
  "@media (max-width: 768px)": {
    width: "90%",
    margin: "1.5rem auto 0",
  },
}));

export const Header = styled(Box)(() => ({
  background: "linear-gradient(180deg, #C5169D 24.5%, #1D1630 100%)",
  padding: "1rem 0",
  color: "#fff",
  margin: "1rem 0",
  width: "100%",
  borderRadius: "0.75rem",
}));

export const HeaderText = styled(Box)(() => ({
  fontSize: "1.75rem",
  padding: "0 1rem",
  fontWeight: "700",
  marginBottom: "0.75rem",
}));

export const HeaderSubText = styled(Box)(() => ({
  width: "100%",
  fontSize: "0.875rem",
  fontWeight: "300",
  padding: "0 1rem",
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
}));

export const Content = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  background: "#232D49",
  padding: "1rem 0",
  fontSize: "0.75rem",
  fontWeight: "400",
  alignItems: "center",
  gap: "0.5rem",
  marginBottom: "1rem",
  borderRadius: "0.75rem",
}));

export const Bottom = styled(Box)(({ heightvalue }: { heightvalue: number }) => ({
  // width: "25rem",
  width: `${heightvalue / 2}px`,
  display: "flex",
  flexDirection: "column",
  fontSize: "0.75rem",
  fontWeight: "400",
  alignItems: "center",
  "@media (max-width: 768px)": {
    width: "100%",
    margin: "0 auto",
  },
}));

export const StyledButton = styled(LoadingButton)(({ disabled }: { disabled?: boolean }) => ({
  borderRadius: "1.5rem",
  height: "3rem",
  background: "#E5FA40",
  textTransform: "unset",
  width: "100%",
  fontSize: "1rem",
  fontWeight: "600",
  color: "#232D49",
  fontFamily: "Space Grotesk",
  letterSpacing: "unset",

  "&:hover": {
    background: "#E5FA40 !important",
  },
  ...(disabled && {
    background: "rgba(229, 250, 64, 0.8) !important",
    color: "rgba(35, 45, 73, 0.8) !important",
  }),
}));

export const SignUpButton = styled(Button)(() => ({
  textTransform: "unset",
  color: "#fff",
  textDecoration: "underline",
  fontSize: "1rem",
  fontWeight: "600",
  padding: "0",
  minWidth: "unset",
  fontFamily: "Space Grotesk",
  "&:hover": {
    textDecoration: "underline",
    background: "transparent",
  },
}));

export const StyledTextField = styled(TextField)(({ error }: { error: boolean }) => ({
  // backgroundColor: error ? "#F022221A" : "#232D49",
  color: "#fff",
  width: "-webkit-fill-available",
  borderRadius: "0.25rem",
  marginBottom: "0.5rem",
  fontSize: "1rem",
  borderColor: error ? "#F02222" : "#E5FA40",

  // "input:-internal-autofill-selected": {
  //   color: "#fff !important",
  //   backgroundColor: "#232D49 !important",
  //   zIndex: 1,
  // },

  input: {
    padding: "1rem !important",
    backgroundColor: "#232D49",
    borderRadius: "0.25rem",
  },

  "& .MuiOutlinedInput-root": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: error ? "#F02222" : "#E5FA40",
      borderWidth: "1px",
      // backgroundColor: error ? "#F022221A" : "#232D49",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: error ? "#F02222" : "#E5FA40",
        borderWidth: "1px",
        // backgroundColor: error ? "#F022221A" : "#232D49",
      },
    },
    "&:hover:not(.Mui-focused)": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: error ? "#F02222" : "#E5FA40",
        // backgroundColor: error ? "#F022221A" : "#232D49",
      },
    },
  },
  "& .MuiInputLabel-outlined": {
    // backgroundColor: error ? "#F022221A" : "#232D49",
    "&.Mui-focused": {
      // backgroundColor: error ? "#F022221A" : "#232D49",
    },
  },
}));

export const InputLabel = styled("div")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "unset",
  color: "#fff",
  marginBottom: "0.5rem",
}));

export const WarningContent = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  fontSize: "0.875rem",
  fontWeight: "400",
  gap: "0.5rem",
  color: "#9B9B9B",
  marginTop: "0.5rem",
}));

export const ErrorContent = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  marginTop: "1rem",
  fontSize: "0.875rem",
  gap: "0.5rem",
  color: "#F02222",
}));

export const Form = styled("form")(() => ({
  width: "100%",
}));
