import { lazy } from "react";
import { createRef, useState } from "react";
import { Box } from "@mui/material";
import { useAutoScrollDown, useGoBack, useIsMobile, useNextStep, useWindowSize } from "../../../hooks";
import { TPage } from "../../../model";
import { danceLevelOptionsV2 } from "../../../utils/constants";
import { useAnswersContext } from "../../../context";
import { ArrowBackIos } from "@mui/icons-material";
import * as S from "./style";

const CustomOptionProgressBar = lazy(() => import("../../../components/V2/CustomOptionProgressBar/CustomOptionProgressBar"));
const CustomCircularProgressBar = lazy(() => import("../../../components/V2/CustomCircularProgressBar/CustomCircularProgressBar"));
const CustomButton = lazy(() => import("../../../components/Base/CustomButtonV2/CustomButton"));
const CustomStepProgressBar = lazy(() => import("../../../components/CustomStepProgressBar/CustomStepProgressBar"));

const ChooseYourDanceLevel: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });
  const handleGoBack = useGoBack({ pageId, nextPagePath });

  const { height } = useWindowSize();

  const { answers } = useAnswersContext();

  const isMobile = useIsMobile();

  const prevDanceLevelTitle = answers[pageId]?.join("");
  const prevDanceLevelOption = danceLevelOptionsV2.find((f) => f.payload.title === prevDanceLevelTitle);

  const [selectedOption, setSelectedOption] = useState(prevDanceLevelOption || danceLevelOptionsV2[0]);

  const myRef = createRef<HTMLDivElement>();
  useAutoScrollDown(myRef);

  const onClick = () => {
    handleContinue(selectedOption.payload.title);
  };

  return (
    <S.Main>
      <S.PageContent height={height}>
        <S.HeaderContent>
          <S.StyledButton onClick={handleGoBack} disableRipple>
            <ArrowBackIos />
          </S.StyledButton>
          <S.PartContent>
            <CustomStepProgressBar part={4} step={15} />
          </S.PartContent>
        </S.HeaderContent>
        <S.Content>
          <S.Title>
            <Box>Choose your</Box>
            <Box>
              <S.ColoredText>dance</S.ColoredText> level
            </Box>
          </S.Title>
          <S.OptionsContent>
            <CustomCircularProgressBar
              options={danceLevelOptionsV2}
              onSelectedOptionChange={(selectedOption) => {
                setSelectedOption(selectedOption);
              }}
              selectedOption={selectedOption}
              height={height}
              isMobile={isMobile}
            />
          </S.OptionsContent>
          <S.SelectedOption ref={myRef}>
            <S.SelectedOptionTitle fontSize="0.875rem" fontWeight="600">
              {selectedOption.payload.title}
            </S.SelectedOptionTitle>
            <S.SelectedOptionSubTitle fontSize="0.875rem">{selectedOption.payload.subTitle}</S.SelectedOptionSubTitle>
          </S.SelectedOption>
          <S.ProgressBarContent>
            <CustomOptionProgressBar
              height={height}
              options={danceLevelOptionsV2}
              onSelectedOptionChange={(selectedOption) => {
                setSelectedOption(selectedOption);
              }}
              selectedOption={selectedOption}
            />
          </S.ProgressBarContent>
        </S.Content>
        <CustomButton width={height / 2} text="Continue" disabled={selectedOption === undefined} onClick={onClick} />
      </S.PageContent>
    </S.Main>
  );
};

export default ChooseYourDanceLevel;
