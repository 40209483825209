import { CustomOption } from "../../model";
import currentBodyShapeOption1 from "../../assets/img/currentBodyShape/currentBodyShapeOption1.webp";
import currentBodyShapeOption2 from "../../assets/img/currentBodyShape/currentBodyShapeOption2.webp";
import currentBodyShapeOption3 from "../../assets/img/currentBodyShape/currentBodyShapeOption3.webp";
import currentBodyShapeOption4 from "../../assets/img/currentBodyShape/currentBodyShapeOption4.webp";
import currentBodyShapeOption5 from "../../assets/img/currentBodyShape/currentBodyShapeOption5.webp";

const currentBodyShapeOptions: CustomOption[] = [
  {
    id: 1,
    value: 0,
    payload: {
      title: "Plump",
      img: currentBodyShapeOption1,
    },
  },
  {
    id: 2,
    value: 1,
    payload: {
      title: "",
      img: currentBodyShapeOption2,
    },
  },
  {
    id: 3,
    value: 2,
    payload: {
      title: "",
      img: currentBodyShapeOption3,
    },
  },
  {
    id: 4,
    value: 3,
    payload: {
      title: "",
      img: currentBodyShapeOption4,
    },
  },
  {
    id: 5,
    value: 4,
    payload: {
      title: "Skinny",
      img: currentBodyShapeOption5,
    },
  },
];

export default currentBodyShapeOptions;
