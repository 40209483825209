import { WEIGHT_TYPE } from "../enum";
import lbToKg from "./lbToKg";

const weightChangeCalculate = (currentWeightParam: { value: number; type: string }, targetWeightParam: { value: number; type: string }) => {
  let currentWeight: number;
  let targetWeight: number;

  if (currentWeightParam.type === WEIGHT_TYPE.KG) {
    currentWeight = currentWeightParam.value;
    if (targetWeightParam.type === WEIGHT_TYPE.KG) {
      targetWeight = targetWeightParam.value;
    } else {
      targetWeight = lbToKg(targetWeightParam.value);
    }
  } else {
    currentWeight = lbToKg(currentWeightParam.value);
    if (targetWeightParam.type === WEIGHT_TYPE.KG) {
      targetWeight = targetWeightParam.value;
    } else {
      targetWeight = lbToKg(targetWeightParam.value);
    }
  }

  const weightChange = targetWeight - currentWeight;
  const percentageChange = Math.abs((weightChange / currentWeight) * 100);

  if (currentWeight > targetWeight) {
    return `You will loose ${percentageChange.toFixed(2)}% of your weight`;
  } else {
    return `You will gain ${percentageChange.toFixed(2)}% of your weight`;
  }
};

export default weightChangeCalculate;
