import { WHICH_AREAS_IMPROVE_V2 } from "../enum";

const whichAreasImproveOptionsV2 = [
  WHICH_AREAS_IMPROVE_V2.FULL_BODY,
  WHICH_AREAS_IMPROVE_V2.SHOULDERS,
  WHICH_AREAS_IMPROVE_V2.ARMS,
  WHICH_AREAS_IMPROVE_V2.BELLY,
  WHICH_AREAS_IMPROVE_V2.BUTT,
  WHICH_AREAS_IMPROVE_V2.LEGS,
];

export default whichAreasImproveOptionsV2;
