import { feetToCm, lbToKg } from ".";
import { MEASURE_TYPE, WEIGHT_TYPE } from "../enum";

const planBodyMassIndex = (selectedHeight: { value: number; type: string }, selectedWeight: { value: number; type: string }) => {
  let result = {
    id: 0,
    key: "",
    value: "",
  };

  let height: number;
  let weight: number;

  if (selectedHeight.type === MEASURE_TYPE.CM) {
    height = selectedHeight.value;
    if (selectedWeight.type === WEIGHT_TYPE.KG) {
      weight = selectedWeight.value;
    } else {
      weight = lbToKg(selectedWeight.value);
    }
  } else {
    height = feetToCm(selectedHeight.value);
    if (selectedWeight.type === WEIGHT_TYPE.KG) {
      weight = selectedWeight.value;
    } else {
      weight = lbToKg(selectedWeight.value);
    }
  }

  const bmi = (weight / Math.pow(height / 100, 2)).toFixed(1);
  result.value = bmi;
  if (Number(bmi) < 18.5) {
    result.key = "Underweight";
    result.id = 10;
  } else if (Number(bmi) >= 18.5 && Number(bmi) <= 24.9) {
    result.key = "Normal";
    result.id = 40;
  } else if (Number(bmi) >= 25 && Number(bmi) <= 29.9) {
    result.key = "Overweight";
    result.id = 65;
  } else {
    result.key = "Obese";
    result.id = 90;
  }
  return result;
};

export default planBodyMassIndex;
