import { Box, Button, styled } from "@mui/material";
import { motion } from "framer-motion";

export const Main = styled(Box)(() => ({
  width: "100%",
  height: "calc(100% - 5rem)",
  overflow: "hidden",
  touchAction: "none",
  position: "relative",
  WebkitOverflowScrolling: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "Space Grotesk",
  userSelect: "none",
  "@media (max-width: 768px)": {
    height: "calc(100% - 2.5rem)",
  },
}));

export const PageContent = styled(Box)(({ height }: { height: number }) => ({
  position: "relative",
  width: `${height / 2}px`,
  height: "calc(100% - 1rem)",
  margin: "0.5rem 0",
  "@media (max-width: 768px)": {
    width: "100%",
    height: "100%",
    margin: "0",
  },
}));

export const Content = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

export const HeaderContent = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  padding: "1rem 0.5rem",
  margin: "0.5rem 0 1rem 0",
  "@media (max-height: 780px) and (max-width: 768px)": {
    margin: "0.5rem 0 0.5rem 0",
  },
}));

export const PartContent = styled(Box)(() => ({
  position: "absolute",
  left: 0,
  right: 0,
  margin: "0 auto",
  width: "16rem",
}));

export const Title = styled(Box)(() => ({
  margin: "0.5rem 0 1rem 0",
  textAlign: "center",
  fontWeight: "600",
  fontSize: "1.25rem",
  color: "#fff",
  "@media (max-width: 768px)": {
    margin: "1rem 0 2rem 0",
  },
  "@media (max-height: 780px) and (max-width: 768px)": {
    margin: "0.5rem 0 0rem 0",
  },
}));

export const StyledButton = styled(Button)(() => ({
  marginTop: "0 !important",
  minWidth: "1rem",
  padding: "0 1rem",
  margin: "0",
  textTransform: "unset",
  color: "#8E98B4",
  fontSize: "1rem",
  "&:hover": {
    backgroundColor: "unset !important",
  },
}));

export const BodyArea = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  position: "relative",
  width: "24rem",
  "@media (max-width: 768px)": {
    width: "90%",
    margin: "0 auto",
  },
}));

export const OptionsContent = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  gap: "1.25rem",
  paddingTop: "1rem",
  "@media (max-width: 768px)": {
    gap: "1rem",
  },
}));

export const Option = styled(motion.div)(({ dark }: { dark: number }) => ({
  width: "7rem",
  minWidth: "7rem",
  background: dark === 1 ? "linear-gradient(180deg, #C5169D 24.5%, #1D1630 100%) padding-box" : "#202853",
  padding: "0.75rem 0",
  textAlign: "center",
  borderRadius: "1rem",
  fontSize: "0.875rem",
  color: "#fff",
  cursor: "pointer",
  zIndex: 2,
  position: "relative",
  userSelect: "none",
  outline: dark === 1 ? "2px solid #C5169D" : "none",
  outlineOffset: "4px",

  // background: "linear-gradient( #050C35, #050C35) padding-box,linear-gradient(92.7deg, #0064fb, red) border-box",

  "@media (max-width: 768px)": {
    padding: "1rem 0",
  },

  "@media (min-width: 600px) and (max-width: 650px)": {
    padding: "0.75rem 0",
  },
}));

export const Image = styled(motion.div)(() => ({
  img: {
    height: "27.5rem",
    width: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto",

    "@media (max-width: 768px)": {
      height: "25rem",
    },
  },
}));

export const CustomButtonContainer = styled(Box)(() => ({
  position: "absolute",
  bottom: "1rem",
  left: "1rem",
  right: "1rem",
  width: "calc(100% - 2rem)",
  height: "3rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "@media (max-width: 768px)": {
    left: "1rem",
    right: "1rem",
    width: "calc(100% - 2rem)",
  },
}));

export const CustomButton = styled(Button)(() => ({
  width: "25rem",
  // margin: "2rem 0",
  borderRadius: "2rem",
  height: "3rem",
  background: "#E5FA40",
  color: "#232D49",
  textTransform: "none",
  fontWeight: 500,
  fontFamily: "Space Grotesk",
  "&:disabled": {
    background: "#899628 !important",
    color: "#232D49 !important",
  },
  "&:hover": {
    background: "#E5FA40 !important",
  },
}));

export const Lines = styled(Box)(({ visible }: { visible: boolean }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  opacity: visible ? 1 : 0,
  // position: "absolute",
  // left: "6rem",
}));

export const LineShouldersPassive = styled(Box)(() => ({
  position: "absolute",
  top: "8rem",
  left: "2.75rem",
  "@media (max-width: 768px)": {
    top: "27%",
    left: "3rem",
  },
}));
export const LineArmsPassive = styled(Box)(() => ({
  position: "absolute",
  top: "12rem",
  left: "2rem",
  "@media (max-width: 768px)": {
    top: "42%",
    left: "1.5rem",
  },
}));
export const LineBellyPassive = styled(Box)(() => ({
  position: "absolute",
  top: "16rem",
  left: "2rem",
  "@media (max-width: 768px)": {
    top: "14.35rem",
    left: "1.25rem",
  },
}));
export const LineButtPassive = styled(Box)(() => ({
  position: "absolute",
  top: "20rem",
  left: "2rem",
  "@media (max-width: 768px)": {
    top: "73%",
    left: "2rem",
  },
}));
export const LineLegsPassive = styled(Box)(() => ({
  position: "absolute",
  top: "23.5rem",
  left: "3rem",
  "@media (max-width: 768px)": {
    top: "89%",
    left: "3rem",
  },
}));
