import React from "react";
import * as S from "./style";

interface ISvg {
  path: string;
  path2?: string;
  fill?: string;
  // bgColor?: string;
  color?: string;
  size?: string;
  width?: string;
  height?: string;
  viewBox?: string;
  children?: React.ReactNode;
  style?: any;
}

const Svg: React.FC<ISvg> = ({ path, path2, fill, color, width = "1em", height = "1em", size = "1.5rem", children, viewBox = "0 0 24 24", style }) => {
  return (
    <S.SVG fill={fill} color={color} fontSize={size} width={width} height={height} viewBox={viewBox} style={style}>
      <path d={path} />
      {path2 ? <path d={path2} /> : null}
      {children}
    </S.SVG>
  );
};

export default Svg;
