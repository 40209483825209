import electronic from "../../assets/img/V2/preferredMusicStyle/electronic.webp";
import pop from "../../assets/img/V2/preferredMusicStyle/pop.webp";
import latin from "../../assets/img/V2/preferredMusicStyle/latin.webp";
import country from "../../assets/img/V2/preferredMusicStyle/country.webp";
import kPop from "../../assets/img/V2/preferredMusicStyle/k-pop.webp";
import { MUSIC_STYLE } from "../enum";

const preferredMusicStyleOptions = [
  {
    id: 1,
    label: MUSIC_STYLE.ELECTRONIC,
    img: electronic,
  },
  {
    id: 2,
    label: MUSIC_STYLE.POP,
    img: pop,
  },
  {
    id: 3,
    label: MUSIC_STYLE.LATIN,
    img: latin,
  },
  {
    id: 4,
    label: MUSIC_STYLE.COUNTRY,
    img: country,
  },
  {
    id: 5,
    label: MUSIC_STYLE.KPOP,
    img: kPop,
  },
];

export default preferredMusicStyleOptions;
