import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { firebaseService } from "../services/firebase.service";
import { TPage } from "../model";

const useGoBack = (params: TPage) => {
  const navigate = useNavigate();

  return useCallback(() => {
    firebaseService.logFirebaseEvent(`${params.pageId}_page_back_button_clicked`);
    navigate(-1);
  }, [params.pageId, navigate]);
};

export default useGoBack;
