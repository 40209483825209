import { createRef, lazy, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { useAnswersContext } from "../../../context";
import { useAutoScrollDown, useNextStep, useWindowSize, useIsMobile, useGoBack } from "../../../hooks";
import { TPage } from "../../../model";
import { helpers } from "../../../utils";
import { WEIGHT_TYPE } from "../../../utils/enum";
import { weightChangeCalculate } from "../../../utils/helpers";
import { ArrowBackIos } from "@mui/icons-material";
import * as S from "./style";

const CustomToggleButton = lazy(() => import("../../../components/V2/CustomToggleButton/CustomToggleButton"));
const WeightRuler = lazy(() => import("../../../components/V2/WeightRuler/WeightRuler"));
const CustomButton = lazy(() => import("../../../components/Base/CustomButtonV2/CustomButton"));
const CustomStepProgressBar = lazy(() => import("../../../components/CustomStepProgressBar/CustomStepProgressBar"));

const WhatIsYourTargetWeight: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });
  const handleGoBack = useGoBack({ pageId, nextPagePath });

  const { height } = useWindowSize();
  const isMobile = useIsMobile();

  const myRef = createRef<HTMLDivElement>();
  useAutoScrollDown(myRef);

  const { answers } = useAnswersContext();

  const selectedTargetWeight = answers["whatIsYourTargetWeight"]?.join("").split(" ");
  const selectedTargetWeightValue: number | undefined = Number(selectedTargetWeight?.[0]);
  const selectedTargetWeightType: WEIGHT_TYPE | undefined = selectedTargetWeight?.[1] as WEIGHT_TYPE;

  const weight = answers["whatIsYourCurrentWeight"]?.join("").split(" ");
  const currentWeight = weight?.[0];
  const currentWeightType = weight?.[1];

  const defaultWeight = useMemo(() => {
    if (selectedTargetWeightValue) {
      return selectedTargetWeightValue;
    } else if (currentWeight && currentWeightType === WEIGHT_TYPE.KG) {
      return Number(currentWeight) - 5;
    } else if (currentWeight && currentWeightType === WEIGHT_TYPE.LB) {
      return Number(currentWeight) - 11;
    } else {
      return 60;
    }
  }, [currentWeight, currentWeightType, selectedTargetWeightValue]);

  const [weightType, setWeightType] = useState<WEIGHT_TYPE>(selectedTargetWeightType || currentWeightType || WEIGHT_TYPE.KG);
  const [selectedWeight, setSelectedWeight] = useState<number>(defaultWeight);

  const weightChange = useMemo(() => {
    if (currentWeight && currentWeightType) {
      const result = weightChangeCalculate({ type: currentWeightType, value: Number(currentWeight) }, { type: weightType, value: selectedWeight });
      return result;
    }
  }, [currentWeight, currentWeightType, selectedWeight, weightType]);

  const onClick = () => {
    handleContinue(`${selectedWeight} ${weightType}`);
  };

  return (
    <S.Main>
      <S.PageContent height={height}>
        <S.HeaderContent>
          <S.StyledButton onClick={handleGoBack} disableRipple>
            <ArrowBackIos />
          </S.StyledButton>
          <S.PartContent>
            <CustomStepProgressBar part={2} step={8} />
          </S.PartContent>
        </S.HeaderContent>
        <S.Title>
          What`s your <S.ColoredText>target</S.ColoredText> weight?
        </S.Title>
        <S.SubTitle>(Determine your weight by swiping right or left)</S.SubTitle>
        <S.Content>
          <Box width="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center" marginBottom="0.5rem">
            <CustomToggleButton
              onChangeValue={(newValue: string) => {
                if (newValue === WEIGHT_TYPE.KG) {
                  const newWeight = helpers.lbToKg(selectedWeight);
                  setSelectedWeight(newWeight);
                } else {
                  const newWeight = helpers.kgToLb(selectedWeight);
                  setSelectedWeight(newWeight);
                }
                setWeightType(newValue as WEIGHT_TYPE);
              }}
              options={[WEIGHT_TYPE.KG, WEIGHT_TYPE.LB]}
              selected={weightType}
            />
          </Box>
          <S.WeightContent>
            <S.WeightText>{selectedWeight}</S.WeightText>
            <S.WeightType>{weightType}</S.WeightType>
          </S.WeightContent>
          <S.RulerContent ref={myRef} heightvalue={height}>
            <WeightRuler
              width={isMobile ? height / 2 : height / 2 - 50}
              height={100}
              step={1}
              value={selectedWeight}
              max={weightType === WEIGHT_TYPE.KG ? 300 : 660}
              color="#fff"
              onChange={(weight: number) => {
                setSelectedWeight(weight);
              }}
            />
          </S.RulerContent>
        </S.Content>

        <S.BMIContent width={height / 2}>
          <Box sx={{ display: "flex" }}>
            <S.ThumbsUp sx={{ fontSize: "1.5rem", marginRight: "0.75rem" }}>👍</S.ThumbsUp>
            <Box>
              <S.GoalHeader>You're on the Right Track!</S.GoalHeader>
              <S.GoalText>{weightChange}</S.GoalText>
              <S.GoalBottom>
                <Box>- This decision can help lower your blood pressure.</Box>
                <Box>-It also reduces the risks associated with obesity.</Box>
                {/* <Box>-You can aim to lose around 5kg in 4 weeks.</Box> */}
              </S.GoalBottom>
            </Box>
          </Box>
        </S.BMIContent>
        <CustomButton width={height / 2} text="Continue" onClick={onClick} />
      </S.PageContent>
    </S.Main>
  );
};

export default WhatIsYourTargetWeight;
