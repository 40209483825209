import { ComponentProps, Context, createContext, FC, ReactNode, Reducer, useContext, useReducer } from "react";
import { setAnswersAction } from "./actions";
import { answersReducer } from "./reducer";
import { Answers, IAnswersContext, SetAnswersAction } from "./types";
import storage from "../../utils/storage";

type Props = {
  children: ReactNode;
};

type CP = ComponentProps<FC> & Props;

let initialAnswersMap: { [p: string]: string[] | null } = {};

const data = storage.get("answerKey");

if (data) {
  initialAnswersMap = JSON.parse(data);
}

export const initialState = {
  answers: initialAnswersMap,
};

export const AnswersContext: Context<IAnswersContext> = createContext<IAnswersContext>({} as IAnswersContext);

export const useAnswersContext = (): IAnswersContext => useContext(AnswersContext);

export const AnswersContextProvider: React.FC<Props> = ({ children }: CP): JSX.Element => {
  const [state, dispatch] = useReducer<Reducer<Answers, SetAnswersAction>>(answersReducer, initialState);

  const setAnswersContext = (pageId: string, answer: string | string[] | null): void => {
    setAnswersAction({ pageId, answer }, dispatch);
  };

  return <AnswersContext.Provider value={{ answers: state.answers, setAnswersContext }}>{children}</AnswersContext.Provider>;
};
