import { lazy } from "react";
import { useState } from "react";
import { Box } from "@mui/material";
import { useGoBack, useIsMobile, useNextStep, useWindowSize } from "../../../hooks";
import { TPage } from "../../../model";
import { useAnswersContext } from "../../../context";
import { ArrowBackIos } from "@mui/icons-material";
import * as S from "./style";

const ScrollablePicker = lazy(() => import("../../../components/ScrollablePicker/ScrollablePicker"));
const CustomButton = lazy(() => import("../../../components/Base/CustomButtonV2/CustomButton"));
const CustomStepProgressBar = lazy(() => import("../../../components/CustomStepProgressBar/CustomStepProgressBar"));

const HowOldAreYou: React.FC<TPage> = ({ pageId, nextPagePath }) => {
  const handleContinue = useNextStep({ pageId, nextPagePath });
  const handleGoBack = useGoBack({ pageId, nextPagePath });

  const { height } = useWindowSize();

  const { answers } = useAnswersContext();

  const isMobile = useIsMobile();

  const prevAgeRange: number | undefined = Number(answers["howOldAreYou"]?.join(""));

  const [selectedOption, setSelectedOption] = useState<number>(prevAgeRange || 25);

  const onClick = () => {
    if (selectedOption) handleContinue(selectedOption.toString());
  };

  const handleNumberChange = (value: { [key: string]: string }) => {
    setSelectedOption(Number(value["number"]));
  };

  return (
    <S.Main>
      <S.PageContent height={height}>
        <S.Content>
          <S.HeaderContent>
            <S.StyledButton onClick={handleGoBack} disableRipple>
              <ArrowBackIos />
            </S.StyledButton>
            <S.PartContent>
              <CustomStepProgressBar part={2} step={5} />
            </S.PartContent>
          </S.HeaderContent>
          <S.Title>How old are you?</S.Title>
          <S.SubTitle>(Determine your age by swiping up or down)</S.SubTitle>
          <S.OptionsContent gap="0.5rem">
            <ScrollablePicker isMobile={isMobile} height={height} value={{ number: selectedOption.toString() }} onChange={handleNumberChange} />
          </S.OptionsContent>
        </S.Content>
        {/* </S.Main2> */}
      </S.PageContent>

      <S.Tip
        width={height / 2}
        sx={{
          left: "0",
          right: "0",
          margin: "0 auto",
          "@media (max-width: 768px)": {
            margin: "0 auto",
            width: "calc(100% - 3rem)",
          },
        }}
      >
        <S.Light>🎂</S.Light>
        <Box>
          <S.InfoHeader sx={{ fontSize: "0.75rem", fontWeight: "600", marginBottom: "0.25rem" }}>Age's Impact on Fitness Plans</S.InfoHeader>
          <S.InfoContent>
            Your age significantly influences your health goals. Older individuals often have more body fat than younger counterparts with the same BMI.
          </S.InfoContent>
        </Box>
      </S.Tip>
      <CustomButton width={height / 2} text="Continue" onClick={onClick} />
    </S.Main>
  );
};

export default HowOldAreYou;
