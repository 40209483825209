import React from "react";
import { WORK_OUT } from "../enum";

import home1 from "../../assets/img/V2/workOutPlace/home/card1.webp";
import home2 from "../../assets/img/V2/workOutPlace/home/card2.webp";
import home3 from "../../assets/img/V2/workOutPlace/home/card3.webp";
import home4 from "../../assets/img/V2/workOutPlace/home/card4.webp";
import gym1 from "../../assets/img/V2/workOutPlace/gym/card1.webp";
import gym2 from "../../assets/img/V2/workOutPlace/gym/card2.webp";
import gym3 from "../../assets/img/V2/workOutPlace/gym/card3.webp";
import gym4 from "../../assets/img/V2/workOutPlace/gym/card4.webp";
import outdoor1 from "../../assets/img/V2/workOutPlace/outdoor/card1.webp";
import outdoor2 from "../../assets/img/V2/workOutPlace/outdoor/card2.webp";
import outdoor3 from "../../assets/img/V2/workOutPlace/outdoor/card3.webp";
import outdoor4 from "../../assets/img/V2/workOutPlace/outdoor/card4.webp";

interface WorkPlaceOptions {
  title: React.ReactNode;
  subTitle: string;
  images: string[];
}

const workPlaceOptions: Record<WORK_OUT, WorkPlaceOptions> = {
  [WORK_OUT.AT_HOME]: {
    title: (
      <div>
        <div>Home Workouts: Stay</div>
        <div>
          Healthy in <span style={{ color: "#E5FA40" }}>Your Own Comfort</span>
        </div>
      </div>
    ),
    subTitle: "Here are numerous workout options you can do at home, allowing you to exercise at your own pace and in the comfort of your own space.",
    images: [home1, home2, home3, home4],
  },
  [WORK_OUT.AT_THE_GYM]: {
    title: (
      <div>
        <div>
          Exploring <span style={{ color: "#E5FA40" }}>Gym Exercise</span>
        </div>
        <div>Variety</div>
      </div>
    ),
    subTitle:
      "These choices provide guidance on proper execution, covering all machine exercises available at the gym, enabling you to work out at your own pace and comfort in the gym.",
    images: [gym1, gym2, gym3, gym4],
  },
  [WORK_OUT.OUTDOOR]: {
    title: (
      <div>
        <div>Outdoor Workouts for</div>
        <div>
          <span style={{ color: "#E5FA40" }}>Life Boost</span>
        </div>
      </div>
    ),
    subTitle:
      "Outdoor exercise boosts both physical health and mood. Sunlight and fresh air elevate mood by increasing serotonin and endorphin levels, enhancing vitality and resilience against daily stressors.",
    images: [outdoor1, outdoor2, outdoor3, outdoor4],
  },
  [WORK_OUT.ANT_PLACE_IS_OK]: {
    title: (
      <div>
        Exercise <span style={{ color: "#E5FA40" }}>Everywhere</span>
      </div>
    ),
    subTitle: "In a world of endless opportunities, pursue any sport, anywhere. Set your intention, commit, and take the first step towards your envisioned healthy lifestyle.",
    images: [gym1, gym2, outdoor3, home4],
  },
};

export default workPlaceOptions;
