import { Box, Button, styled } from "@mui/material";
import rectangle from "../../../assets/img/V2/landing/rectangle.webp";

export const Main = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  overflow: "hidden",
  touchAction: "none",
  position: "relative",
  WebkitOverflowScrolling: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "Space Grotesk",
  userSelect: "none",
  background: "linear-gradient(116.95deg, #0D1230 -5.62%, #2C165C 62.46%, #881656 106.91%)",
  "@media (max-width: 768px)": {
    height: "100%",
  },
}));

export const PageContent = styled(Box)(({ height }: { height: number }) => ({
  position: "relative",
  width: `${height / 2}px`,
  height: "100%",
  "@media (max-width: 768px)": {
    width: "100%",
    height: "100%",
    margin: "0",
  },
}));

export const Content = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  // marginTop: "1rem",
  position: "relative",
  "@media (max-width: 768px)": {
    marginTop: "0rem",
  },
}));

export const HeaderContent = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  width: "90%",
  padding: "1rem 0.5rem",
  margin: "1rem auto",
  background: "#3c2d67",
  borderRadius: "5rem",
  boxShadow: "-0.36px 0.72px 0px 0px #FFFFFF40 inset, 0px 0px 18.66px 0px #00000040",
  position: "relative",
  height: "5rem",

  "@media (max-width: 768px)": {
    margin: "1rem auto",
  },
}));

export const HeaderTitle = styled(Box)(() => ({
  margin: "0.125rem",
  textAlign: "center",
  fontWeight: "700",
  fontSize: "1.125rem",
  color: "#fff",
  letterSpacing: "0.1em",
}));

export const WreathLeft = styled("img")(() => ({
  position: "absolute",
  left: "1rem",
  width: "3rem",
}));

export const WreathRight = styled("img")(() => ({
  position: "absolute",
  right: "1rem",
  width: "3rem",
}));

export const Stars = styled("img")(() => ({
  position: "relative",
  width: "8rem",
  marginTop: "0.25rem",
}));

export const PartContent = styled(Box)(() => ({
  position: "absolute",
  left: 0,
  right: 0,
  margin: "0 auto",
  width: "16rem",
}));

export const Title = styled(Box)(() => ({
  margin: "0.5rem",
  textAlign: "center",
  fontWeight: "700",
  fontSize: "1.5rem",
  color: "#fff",
  letterSpacing: "0.02em",
}));

export const ColoredTextBox = styled(Box)(() => ({
  color: "#E5FA40",
}));

export const ColoredText = styled("span")(() => ({
  color: "#E5FA40",
}));

export const SubTitle = styled(Box)(() => ({
  textAlign: "center",
  fontSize: "0.875rem",
  color: "#fff",
  marginBottom: "1rem",
  lineHeight: "1.75rem",
  padding: "0 2.5rem",
  "@media (max-width: 768px)": {
    padding: "0 2rem",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },
}));

export const StyledImageSliderContent = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
  gap: "1rem",
  margin: "1.5rem 0",

  "@media (max-width: 768px)": {
    margin: "0.5rem 0",
  },
}));

export const ImageContent = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "transparent",
}));

export const StyledButton = styled(Button)(() => ({
  marginTop: "0 !important",
  minWidth: "1rem",
  padding: "0 1rem",
  margin: "0",
  textTransform: "unset",
  color: "#8E98B4",
  fontSize: "1rem",
  zIndex: 5,
  "&:hover": {
    backgroundColor: "unset !important",
  },
}));

export const TitleContent = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  background: `url(${rectangle})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "50% 1rem",
  backgroundSize: "10rem",
  marginBottom: "1rem",
}));

export const CustomButtonContainer = styled(Box)(() => ({
  position: "fixed",
  bottom: "1rem",
  left: "1rem",
  right: "1rem",
  width: "calc(100% - 2rem)",
  height: "3rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "@media (max-width: 768px)": {
    left: "1rem",
    right: "1rem",
    width: "calc(100% - 2rem)",
  },
}));

export const CustomButton = styled(Button)(() => ({
  width: "25rem",
  // margin: "2rem 0",
  borderRadius: "2rem",
  height: "3.25rem",
  background: "#E5FA40",
  color: "#232D49",
  textTransform: "none",
  fontWeight: 700,
  fontSize: "1rem",
  fontFamily: "Space Grotesk",
  border: "2px solid transparent",
  borderImageSlice: 1,
  borderImageRepeat: "stretch",
  borderImageOutset: 0,
  boxShadow: "0px 6px 14px 0px #E5FA4033, 0px 25px 25px 0px #E5FA402B, 0px 56px 34px 0px #E5FA401A,0px 100px 40px 0px #E5FA4008, 0px 156px 44px 0px #E5FA4000",

  "&:disabled": {
    background: "#899628 !important",
    color: "#232D49 !important",
  },
  "&:hover": {
    background: "#E5FA40 !important",
  },
}));
